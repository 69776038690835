import { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import './Parametre.css';

const Parametre = (req) => {
  const [param, setParam] = useState({
    modifStatus: 0,
    enterprise: {
      name: '',
      siret: '',
      address: '',
      postal: '',
      city: '',
      phone: '',
      email: '',
      logo: ''
    },
    roles: [],
    users: []
  });
  const [logoFile, setLogoFile] = useState('');
  const [newUser, setNewUser] = useState({
    status: 0,
    name: '',
    surname: '',
    mail: '',
    role: ''
  });
  const [subscription, setSubscription] = useState({
    status: false,
    cancelStatus: 0,
    users: 0,
    date: '',
    invoices: [],
    cancelReason: '',
    cancelComment: ''
  });
  const [error, setError] = useState(null);
  const user = req.user;

  const loadEnterprise = async () => {
    if(req && req.tryAuthorization(67)) {
      console.log('LOAD ENTERPRISE : ', user.enterprise.id);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/enterprise`,
          {
            enterprise_id: user.enterprise.id
          }
        );
        if (response.data.success) {
          console.log('ENTREPRISE : ', response.data.enterprise);
          setParam((prevParam) => ({
            ...prevParam,
            enterprise: {
              ...prevParam.enterprise,
              name: response.data.enterprise.name ? response.data.enterprise.name : 'Aucun nom',
              siret: response.data.enterprise.siret ? response.data.enterprise.siret : 'aucun siret',
              address: response.data.enterprise.address ? response.data.enterprise.address : '',
              address_bis: response.data.enterprise.address_bis ? response.data.enterprise.address_bis : '',
              zip: response.data.enterprise.zip ? response.data.enterprise.zip : '',
              city: response.data.enterprise.city ? response.data.enterprise.city : '',
              phone: response.data.enterprise.phone ? response.data.enterprise.phone : 'aucun téléphone',
              email: response.data.enterprise.mail ? response.data.enterprise.mail : 'aucun mail',
              logo: response.data.enterprise.logo ? response.data.enterprise.logo : 'defaut.png',
              status: response.data.enterprise.status ? response.data.enterprise.status : 'aucun status',
              creation_date: response.data.enterprise.creation_date ? response.data.enterprise.creation_date : 'aucune date',
              tva: response.data.enterprise.tva ? response.data.enterprise.tva : 'aucun tva'          
            }
          }));
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError(error.message);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const switchList = (varName) => {
    if (req && req.tryAuthorization(67)) {
      setParam((prevParam) => ({
        ...prevParam,
        modifStatus: 1,
        roles: prevParam.roles.map((role) =>
          role.varName === varName
            ? { ...role, status: role.status === 0 ? 1 : 0 }
            : role
        )
      }));
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const userEdit = (userId) => {
    if(req.tryAuthorization(67)) {
      setParam((prevParam) => ({
        ...prevParam,
        users: prevParam.users.map((user) =>
          user.id === userId && user.role !== 'gerant'
            ? { ...user, status: user.status === 0 ? 1 : 0 }
            : user
        ),
        modifStatus: 1
      }));
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const userCreate = () => {
    if (req.tryAuthorization(67)) {
      setNewUser((prevUser) => ({
        ...prevUser,
        status: prevUser.status === 0 ? 1 : 0
      }));
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const userRoleChange = (e, id) => {
    if (req.tryAuthorization(67)) {
      setParam((prevParam) => ({
        ...prevParam,
        users: prevParam.users.map((user) =>
          user.id === id && user.modifiable === 1
            ? { ...user, status: user.status === 0 ? 1 : 0, role: e.target.value }
            : user
        )
      }));
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const newUserChange = (e, variable) => {
    if (req.tryAuthorization(67)) {
      setNewUser((prevUser) => ({
        ...prevUser,
        [variable]: e.target.value
      }));
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const newUserValid = async () => {
    if (req.tryAuthorization(67)) {
      console.log(newUser);
      if (newUser.name && newUser.surname && newUser.mail && newUser.role) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/newUser`,
            {
              enterprise_id: user.enterprise.id,
              user_name: newUser.name,
              user_surname: newUser.surname,
              user_mail: newUser.mail,
              user_role: newUser.role
            }
          );
          if (response.data) {
              console.log(response.data);
            setParam((prevParam) => ({
              ...prevParam,
              users: [
                ...prevParam.users,
                {
                  id: response.data.user_id,
                  actif: 1,
                  name: newUser.name,
                  surname: newUser.surname,
                  role: newUser.role,
                  modifiable: 0,
                  status: 0,
                  photo: 'jeremy'
                }
              ]
            }));
            setNewUser((prevUser) => ({
              ...prevUser,
              status: 0,
              name: '',
              surname: '',
              mail: '',
              role: ''
            }));
          }
        } catch (error) {
          setError(error.message);
        }
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const usersLoad = async (rolesTable) => {
    if (req.tryAuthorization(67)) {
      console.log(rolesTable);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/users`,
          {
            enterprise_id: user.enterprise.id,
            user_id: user.id
          }
        );
        if (response.data.success) {
          console.log('USERS : ', response.data.rows);
          const usersTable = response.data.rows.map((user) => {
            let userRole = rolesTable[parseInt(user.role)] ? rolesTable[parseInt(user.role)].name : 'utilisateur';


            return {
              id: user.id,
              actif: user.status,
              name: user.nom,
              surname: user.prenom,
              role: userRole,
              modifiable: user.modifiable,
              status: 0,
              photo: user.photo
            };
          });

          console.log('USERS TABLE : ', usersTable);

          setParam((prevParam) => ({
            ...prevParam,
            users: usersTable,
            roles: rolesTable
          }));
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError(error.message);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const loadRoles = async () => {
    if (req.tryAuthorization(67)) {
      console.log('[loadRoles] user : ');
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/roles`,
          {
            enterprise_id: user.enterprise.id
          }
        );
        if (response.data.success) {
          console.log('ROLES : ', JSON.parse(response.data.rows[0].attribut));
          const rolesTable = response.data.rows.map((role) => {
            return {
              id: role.id,
              name: role.name,
              varName: role.name,
              status: 0,
              checkList: JSON.parse(role.attribut)
            };
          });
          console.log('ROLES TABLE : ', rolesTable);
          usersLoad(rolesTable);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError(error.message);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  
  const updateRoles = async () => {
    if (req.tryAuthorization(67)) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/rolesUpdate`,
          {
            enterprise_id: user.enterprise.id,
            roles: param.roles
          }
        );
        if (response.data.success) {
          setParam((prevParam) => ({
            ...prevParam,
            modifStatus: 0
          }));
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError(error.message);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const updateUsers = async () => {
    if (req.tryAuthorization(67)) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/usersUpdate`,
          {
            enterprise_id: user.enterprise.id,
            users: param.users
          }
        );
        if (response.data.success) {
          setParam((prevParam) => ({
            ...prevParam,
            modifStatus: 0
          }));
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError(error.message);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const editRole = async (event, roleVarName, checkboxId) => {
    if (req.tryAuthorization(67)) {
      // Mise à jour locale de l'état
      let lastUpdatedRole = {};
      const updatedRoles = param.roles.map((role) => {
        if (role.varName === roleVarName) {
          lastUpdatedRole = role;
          const updatedChecklist = role.checkList.map((item) => {
            if (item.id === checkboxId) {
              return { ...item, status: event.target.checked ? 1 : 0 };
            }
            return item;
          });
          return { ...role, checkList: updatedChecklist };
        }
        return role;
      });

      // Utilisez la version mise à jour de param ici
      const updatedParam = { ...param, roles: updatedRoles };

      try {
        // Effectuez la requête axios
          lastUpdatedRole.checkList = updatedRoles.find((role) => role.varName === roleVarName).checkList;


        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/roleUpdate`,
          {
              enterprise_id: user.enterprise.id,
              varName: lastUpdatedRole.varName,
              checkList: lastUpdatedRole.checkList
          }
        );

        // Mettez à jour l'état avec la réponse de la requête
        if (response.data) {
          setParam(updatedParam);
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const logoHandleChange = async (event) => {
    if (req.tryAuthorization(67)) {
      if (user && user.enterprise && user.enterprise.id) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          setLogoFile(reader.result);
        };
        reader.readAsDataURL(file);
    
        const formData = new FormData();
        formData.append('logo', file);
        formData.append('enterprise_id', user.enterprise.id);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/logoUpdate`,
            formData
          );
          if (response.data.success) {
            console.log(response.data);
            setLogoFile('');
            setParam({
              ...param,
              enterprise: {
                ...param.enterprise,
                logo: response.data.logo
              }
            })
          } else {
            console.log(response.data.message);
          }
        }
        catch (error) {
          console.log(error);
        }
      } else {
        console.log("user or user.enterprise or user.enterprise.id is undefined");
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const cancelSubsriptionShuffle = () => {
    if (req.tryAuthorization(67)) {
      console.log('cancelSubsriptionShuffle');

      setSubscription({
        ...subscription,
        status: !subscription.status
      })

    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  
  const cancelSubsription = async () => {
    if (req.tryAuthorization(67)) {
      console.log('cancelSubsription');
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/cancelSubscription`,
          {
            enterprise_id: user.enterprise.id
          });

        if (response.data.success) {
          setSubscription({
            ...subscription,
            cancelStatus: 1
          });
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  
  const cancelSubsriptionSend = async () => {
    if (req.tryAuthorization(67)) {
      console.log('cancelSubsriptionSend');
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/cancelSubscriptionAvis`,
          {
            enterprise_id: user.enterprise.id,
            cancelReason: subscription.cancelReason,
            cancelComment: subscription.cancelComment
          });

        if (response.data.success) {
          setSubscription({
            ...subscription,
            cancelStatus: 0
          });
        } else {
          console.log(response.data.message);
        }      
      } catch (error) {
        console.log(error);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };

  
  useEffect(() => {
    console.log('PARAM USE EFFECT : ', user);
    console.log(param)
    if (param.enterprise.name === '' || param.enterprise.name === 'nom') {
      console.log('load entreprise et roles', user);
      loadEnterprise();
      loadRoles();
    }
  }, [user]);

  // Supprimer la fonction editRole car elle n'est pas utilisée
  return (
    <div className='Parametre'>
      <div className='ParametreSection'>
          <div className='ParametreSectionTitle'>Infos entreprise (siège)</div>
          <div className='ParametreSectionContent'>
              <div className='ParametreSectionInputBloc'>
                  <span>Nom de l'entreprise :</span> 
                  <div className='ParametreSectionInput'>{param.enterprise.name}</div>
              </div>
              <div className='ParametreSectionInputBloc'>
                  <span>Status :</span> 
                  <div className='ParametreSectionInput'>{param.enterprise.status}</div>
              </div>
              <div className='ParametreSectionInputBloc'>
                  <span>SIREN :</span> 
                  <div className='ParametreSectionInput'>{param.enterprise.siren}</div>
              </div>
              <div className='ParametreSectionInputBloc'>
                  <span>SIRET :</span> 
                  <div className='ParametreSectionInput'>{param.enterprise.siret}</div>
              </div>
              <div className='ParametreSectionInputBloc'>
                  <span>Numéro TVA :</span> 
                  <div className='ParametreSectionInput'>{param.enterprise.tva}</div>
              </div>
              <div className='ParametreSectionInputBloc'>
                  <span>Date d'inscription :</span> 
                  <div className='ParametreSectionInput'>{new Date(param.enterprise.creation_date).toLocaleDateString('fr-FR', {year: "numeric", month: "long", day: "numeric"})}</div>
              </div>
              <div className='ParametreSectionInputBloc'>
                  <span>Adresse du siège</span> 
                  <div className='ParametreSectionInput'>
                      <div className='ParametreSectionInputAddress'>{param.enterprise.address}</div>
                      <div className='ParametreSectionInputAddress'>{param.enterprise.address_bis}</div>
                      <div className='ParametreSectionInputAddress'>{param.enterprise.zip}</div>
                      <div className='ParametreSectionInputAddress'>{param.enterprise.city}</div>
                  </div>
              </div>
              <div className='ParametreSectionInputBloc'>
                    <div className='ParametreSectionInputLogo'>
                      <input type='file' id='logo' alt='logo' onChange={logoHandleChange}/>
                    </div>
                    <img src={logoFile ? logoFile : 'https://bluestock.fr/uploads/' + param.enterprise.logo} alt='logo entreprise' className='ParametreSectionLogo'/>
              </div>
          </div>
      </div>
      {/* <div className='ParametreSection'>
          <div className='ParametreSectionTitle'>Info entreprise</div>
          <div className='ParametreSectionContent'>
              IDEA : même chose que le siège si il existe d'autre entreprise liées
          </div>
      </div> */}
      <div className='ParametreSection'>
          <div className='ParametreSectionTitle'>Roles et status</div>
          <div className='ParametreSectionContent'>
            {param.roles.length > 0 ? 
            param.roles.map((role, index) => {
              console.log(role);
              const sections = role.checkList.reduce((sections, item, i) => {
              if (!sections[item.section]) {
                  sections[item.section] = [];
              }
              sections[item.section].push(
                <div key={i}>
                  <input
                      type="checkbox"
                      id={`${role.varName}-${item.section}-${i}`}
                      checked={item.status}
                      onChange={(e) => editRole(e, role.varName, item.id)}
                  />
                  <label htmlFor={`${role.varName}-${item.section}-${i}`}>
                      {item.intitule}
                  </label>
                </div>
              );
              return sections;
            }, {});

            return (
              <div className='ParametreSectionContentRoles' key={index}>
                <span>{role.name}</span>
                <span className='ParametreSectionContentRolesAttributeButton' onClick={() => switchList(role.varName)}>
                    {role.status === 0 ? 'Voir les attributions' : 'Réduire'}
                </span>
                <div className={role.status === 0 ? 'ParametreSectionContentRolesChecklistUnactive' : 'ParametreSectionContentRolesChecklist'}>
                  {Object.entries(sections).map(([section, checkboxes]) => (
                    <fieldset key={section}>
                      <legend>{section}</legend>
                      {checkboxes}
                    </fieldset>
                  ))}
                </div>
              </div>
            );
          }) : null}
        </div>
      </div>
      <div className='ParametreSection'>
        <div className='ParametreSectionTitle'>Utilisateurs</div>
          <div className='ParametreSectionContent ParametreSectionUsers'>
            {param.users.map((user) => (
              <div className='userCard' key={user.id}>
                <div className='UserCardPicture'></div>
                <div className='UserCardName'>{user.surname} {user.name}</div>
                <div className='UserCardRole'>
                  {user.status === 0 ? (
                      user.role
                  ) : (
                    <select value={user.role} onChange={(e) => userRoleChange(e, user.id)}>
                    {param.roles.map((role) => (role.varName !== 'gerant' && (
                      <option key={role.varName} value={role.varName}>
                        {role.name}
                      </option>
                    )))}
                    </select>
                  )}
                </div>
                <div className='UserCardStatus'>{user.actif === 1 ? 'Actif' : 'Inactif'}</div>
                  <div className='UserCardModifiy' onClick={() => userEdit(user.id)}>
                    {user.status === 0 ? 'Modifier' : 'Retour'}
                  </div>
                </div>
              ))}
              <div className='userCard'>
                <div className={newUser.status === 0 ? 'UserCardPicture' : 'UserCardPictureUnactive'}>+</div>
                <div className={newUser.status === 0 ? 'UserCardRole' : 'UserCardRoleUnactive'} onClick={userCreate}>Nouvel utilisateur</div>
                <div className={newUser.status === 1 ? 'newUserCard' : 'newUserCardUnactive'}>
                  <input type='text' placeholder='Nom' onChange={(e) => newUserChange(e, 'name')}></input>
                  <input type='text' placeholder='Prénom'  onChange={(e) => newUserChange(e, 'surname')}></input>
                  <input type='mail' placeholder='adresse mail'  onChange={(e) => newUserChange(e, 'mail')}></input>
                  <select value={newUser.role} onChange={(e) => newUserChange(e, 'role')}>
                    {param.roles.map((role) => (role.varName !== 'gerant' && (
                      <option key={role.varName} value={role.varName}>
                          {role.name}
                      </option>
                    )))}
                  </select>
                  <div className='newUserValid' onClick={newUserValid}>Valider</div>
                    <span  onClick={userCreate}>Annuler</span>
                  </div>
                </div>
              </div>
      </div>
      <div className='ParametreSection'>
        <div className='ParametreSectionTitle'>Abonnement et facturation</div>
        <div className='ParametreSectionContent'>
          <div className='ParametreSectionInputBloc'>
            <span>Votre abonnement actuel :</span>
            <div className='ParametreSectionInput'>Abonnement gratuit</div>
            <div className='cancelSubscription' onClick={cancelSubsriptionShuffle}>Annuler mon abonnement</div>
          </div>
          <div className='ParametreSectionInputBloc'>
            <span>Nombre d'utilisateurs :</span>
            <div className='ParametreSectionInput'>{subscription.users}</div>
          </div>
          <div className='ParametreSectionInputBloc'>
            <span>Historique de vos factures :</span>
            <div className='ParametreSectionInput'>Aucune facture</div>
          </div>
        </div>
      </div>

      <div className={subscription.status ? 'cancelSubscriptionMask' : 'cancelSubscriptionMaskUnactived'}>
        <div className='cancelSubscriptionBloc'>
          {
            subscription.cancelStatus === 0 ? (
              <>
                <div className='cancelSubscriptionBlocTitle'>Annuler mon abonnement</div>
                <div className='cancelSubscriptionBlocContent'>
                  <div className='cancelSubscriptionBlocContentText'>
                    Vous êtes sur le point d'annuler votre abonnement. Vous pourrez toujours accéder à votre compte gratuitement, mais vous perdrez l'accès à certaines fonctionnalités.
                    Êtes-vous sûr de vouloir annuler votre abonnement ?
                  </div>
                </div>
                <div className='cancelSubscriptionBlocActionZone'>
                    <div className='cancelSubscriptionBlocCancelButton' onClick={cancelSubsription}>Annuler mon abonnement</div>
                    <div className='cancelSubscriptionBlocBackButton' onClick={cancelSubsriptionShuffle}>Retour</div>
                </div>
              </>
            ) : (
              <>
                <div className='cancelSubscriptionBlocTitle'>Votre abonnement a bien été annulé</div>
                <div className='cancelSubscriptionBlocContent'>
                  <div className='cancelSubscriptionBlocContentText'>
                    Nous sommes triste de vous voir partir. Vous pouvez réactiver votre abonnement à tout moment.<br/>
                  </div>
                  <div className='cancelSubscriptionBlocContentForm'>
                    <p>
                      Nous cherchons toujours à améliorer notre service.
                      Avant de partir, pourriez-vous nous dire pourquoi vous avez décidé d'annuler votre abonnement ?
                    </p>
                    {/* Choix multiple */}
                    <div className='cancelSubscriptionBlocContentFormRadios'>
                      <input type='radio' id='cancelReason1' name='cancelReason' value='cancelReason1' onClick={(e) => setSubscription({...subscription, cancelReason: e.target.value})}/>
                      <label htmlFor='cancelReason1'>Manque de fonctionnalités</label>
                      <input type='radio' id='cancelReason2' name='cancelReason' value='cancelReason2' onClick={(e) => setSubscription({...subscription, cancelReason: e.target.value})}/>
                      <label htmlFor='cancelReason2'>Le prix est trop élevé</label>
                      <input type='radio' id='cancelReason3' name='cancelReason' value='cancelReason4' onClick={(e) => setSubscription({...subscription, cancelReason: e.target.value})}/>
                      <label htmlFor='cancelReason3'>Autre</label>
                    </div>
                    <textarea placeholder='Que pourrions-nous améliorer ?' onChange={(e) => setSubscription({...subscription, cancelComment: e.target.value})}></textarea>
                  </div>
                </div>
                <div className='cancelSubscriptionBlocActionZone'>
                      <div className='cancelSubscriptionBlocContentSendButton' onClick={cancelSubsriptionSend}>Envoyer</div>
                      <div className='cancelSubscriptionBlocBackButton' onClick={cancelSubsriptionShuffle}>Quitter</div>
                </div>
              </>
            )
          }
        </div>
      </div>
    </div>

  );
};

Parametre.propTypes = {
  user: PropTypes.object.isRequired
};

export default Parametre;
