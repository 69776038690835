import React, { useEffect, useState } from 'react';
import './CartSlide.css';
import { ArrowUpCircleFill, Calendar2Day, Cart, Clipboard2Check } from 'react-bootstrap-icons';

// CartSlide est un composant qui permet de gérer les commandes

// Les commandes sont enregistrées dans la base de donnée
// Les commandes sont envoyées par mail

// IDEA : Problème avec commande automatique

const CartSlide = (req) => {
    const { cart, commandes, all_commandes, status, active_commande, cart_alert, cartToggle, lessItem, moreItem, changeItemNumber, selectCommande, changeCommandeDate, active_date = '', deleteCommande } = req;
    const articles_number = cart.reduce((acc, item) => acc + item.exCart, 0);

    const [slide, getSlide] = useState({
        zoneSize : false
    });
    const [articleDrag, setArticleDrag] = useState({
        article: null,
        start_commande: null
    });
    const [commandesPannel, setCommandesPannel] = useState({
        active: false,
        all_commandes: []
    });
    
    const activeCommandes = (e, id) => {
        if(req.tryAuthorization(35)) {
            req.openCommandeToggle(id);
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const invertZoneSizes = () => {
        if (req.tryAuthorization(35)) {
            getSlide({zoneSize: !slide.zoneSize});
        }
    }
    function compare( a, b ) {
        if(req.tryAuthorization(35)) {
            if ( a.valid_date < b.valid_date ){
            return -1;
            }
            if ( a.valid_date > b.valid_date ){
            return 1;
            }
            return 0;
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const handleDragOver = (e) => {
        e.preventDefault();
    }
    const handleDrop = (e, item) => {
        if(!req.tryAuthorization(35)) {
            e.preventDefault();
            const data = e.dataTransfer.getData("text/plain");
            if (data === "article") {
                console.log('drop');
                req.moveArticle(articleDrag.article, articleDrag.start_commande, item);
            }
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }

    }
    const handleDragStart = (e, item, article) => {
        if(req.tryAuthorization(35)) {
            // Bouge un article d'une commande à une autre en cliqué glissé
            e.dataTransfer.setData("text/plain", "article");
            setArticleDrag({article: article, start_commande: item});
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const allCommandes = () => {
        if(req.tryAuthorization(35)) {
            setCommandesPannel({
                ...commandesPannel,
                active: !commandesPannel.active
            });
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }

    useEffect(() => {
        getSlide({zoneSize: true});
    }, [req.cart]);
    useEffect(() => {
        console.log('ALL COMMANDES :: ', all_commandes);
        setCommandesPannel({
            ...commandesPannel,
            active: false,
            all_commandes: all_commandes ? all_commandes : []
        });
    }, [all_commandes]);

    return (
        <>
            <div className={status === 0 ? 'CartIcone CartIcone__inactive' : 'CartIcone'} onClick={cartToggle} title='Commandes en cours'>
                <Clipboard2Check size={24} />
                <span className={cart.length > 0 ? 'CartIcone_length' : 'CartIcone_length CartIcone_length__inactive'}>{ articles_number }</span>
            </div>
            <div className={status === 0 ? 'CartSlide CartSlide__inactive' : 'CartSlide'}>
                <div className='CartSlide__header'>
                    <h2>Commandes en cours</h2>
                </div>
                <div className={slide.zoneSize === true ? 'CartSlide__content' : 'CartSlide__content CartSlide__content_close'}>
                    <div className='CartSlide__content_title'>Commande Active <Cart size={16}/></div>
                    <ul>
                        {req.cart.map((item) => (
                            <li key={item.id}>
                                <img className='CartSlide__image' src={item.image} alt={item.name} />
                                <div className='CartSlide_article_description'>
                                    <h3>{item.name}</h3>
                                    <p>Price: {item.prix_ttc}</p>
                                    <p>Quantity: 
                                        <div className='CartSlide__Quantity'>
                                            <div className='CartSlide__Quantity_less' onClick={() => lessItem(item.id)}>-</div>
                                            <input className='CartSlide__Quantity_number' type='number' value={item.exCart} onChange={(e) => changeItemNumber(e, item.id)} />
                                            <div className='CartSlide__Quantity_more' onClick={() => moreItem(item.id)}>+</div>
                                        </div>
                                    </p>
                                </div>
                            </li>
                        ))}
                        <div className='CartSlide__content_date'>
                            <input type="date" value={active_date.slice(0, 10)} onChange={changeCommandeDate} />
                            {cart_alert.length > 0 ? 
                                <div className='CartSlide__content_alert'>{cart_alert}</div> : 
                                null

                            }
                        </div>
                    </ul>
                    {cart.length > 0 ?
                    <>
                        <div className='CartSlide__content_total'>
                            <h4>Total</h4>
                            <p>{typeof req.total === 'number' ? parseFloat(req.total).toFixed(2) : '0.00'} €</p>
                        </div>
                        <div className='CartSlide__content_validate' onClick={req.validateCommande}>Créer une nouvelle commande</div>
                        <div className='CartSlide__content_delete' onClick={deleteCommande}>Supprimer cette commande</div>
                    </>
                    : null}
                </div>
                <div className='CartSlide__footer'>
                    <ArrowUpCircleFill size={24} className={slide.zoneSize === true ? 'CartSlideArrow' : 'CartSlideArrow CartSlideArrowDown'} onClick={invertZoneSizes} />
                    <div className='CartSlide__content_title'>
                        Mes Commandes <Calendar2Day size={16} />
                    </div>
                    <div className={slide.zoneSize === true ? 'CartSlide__footer_content' : 'CartSlide__footer_content CartSlide__footer_content_open'}>
                        { commandes.length === 0 ?
                        <div className='CartSlide__footer_content_empty'>
                            <p>Vous n'avez pas de commande programmée</p>
                        </div> :
                        <ul className='CartSlide__categories_list'>
                            {commandes.sort(compare).map((item) => (
                                <li key={item.id}>
                                    <div id="CartSlide__categories_title" className={req.active_commande !== item.id ? 'CartSlide__categories_title' : 'CartSlide__categories_title CartSlide__categories_title_active'} onClick={(e) => activeCommandes(e, item.id)}>
                                        {item.valid_date !== '00-00-0000' && (item.name === '' || !item.name) ? new Date(item.valid_date).toLocaleDateString('fr-FR') : item.name ? item.name : 'non programmé'}<span className='CartSlide_category_status' title='modifiable'>{item.status_name}</span>
                                    </div>
                                    <ul className={item.open ? 'CartSlide__categories_articles_list CartSlide__categories_articles_list_active' : 'CartSlide__categories_articles_list'}  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, item.id)}>
                                        {item.articles.map((article) => (
                                            <li key={article.id} draggable={true} onDragStart={(e) => handleDragStart(e, item.id, article.id)}>
                                                <img className='CartSlide__categories_article_image' src={article.image} alt={article.name} />
                                                <div className='CartSlide_categories_article_description'>
                                                    <h3>{article.name}</h3>
                                                    <p>Price: {article.prix_ttc}</p>
                                                    <p>Quantity: {article.exCart}</p>
                                                </div>
                                            </li>
                                        ))}
                                        {
                                            item.status === 0 || item.status === 1 ? 
                                            <div className='CartSlide__command_active' onClick={() => selectCommande(item.id)}>Modifier</div> 
                                            : null
                                        }
                                    </ul>
                                </li>
                            ))
                            }
                            
                        </ul>}
                    </div>
                    <div className='all_commandes_button' onClick={allCommandes}>Toutes les commandes</div>
                </div>
            </div>
            <div className={commandesPannel.active ? 'commandesPannelMask' : 'commandesPannelMaskUnactive'} onClick={allCommandes}>
                <div className='commandesPannel'>
                    <div className='commandesPannel__header'>
                        <h2>Toutes les commandes</h2>
                    </div>
                    <div className='commandesPannel__content'>
                        <ul>
                            {commandesPannel.all_commandes.map((item) => (
                                <li key={item.id}>
                                    <div className='commandesPannel__content_title'>{item.valid_date !== '00-00-0000' ? new Date(item.valid_date).toLocaleDateString('fr-FR') : 'non programmé'}</div>
                                    <ul>
                                        {item.articles.map((article) => (
                                            <li key={article.id}>
                                                <img className='commandesPannel__content_image' src={article.image} alt={article.name} />
                                                <div className='commandesPannel_article_description'>
                                                    <h3>{article.name}</h3>
                                                    <p>Price: {article.prix_ttc}</p>
                                                    <p>Quantity: {article.exCart}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='commandesPannel__footer'>
                        <div className='commandesPannel__footer_close' onClick={allCommandes}>Fermer</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CartSlide;