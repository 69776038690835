import { useEffect, useState } from 'react';
import './Stock.css';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { ArrowDownSquareFill, ArrowUpSquareFill, Grid, ListTask, XSquareFill } from 'react-bootstrap-icons';
import { jwtDecode } from 'jwt-decode';
import { set } from 'react-ga';


function Stock(req){
    const {changeItemNumber} = req;
    const [produit, setProduit] = useState({
        id : 0,
        saved : true,
        categorie : 'brome',
        name : 'Mon article',
        desc : 'Description principal de mon article',
        photo : '',
        prixAchatHT : '10.00',
        prixVCTtc : '30.00',
        prixVenteHt : '30.00',
        prixVenteTtc : '36.00',
        TauxTva : '20',
        exDispo : 5,
        StockAlert : 5,
        technic : {
            desc : "Description technique de mon article",
            data : [
                {
                    name : 'Paramètre 1',
                    content : '0.00'
                },
                {
                    name : 'Paramètre 2',
                    content : '0.00'
                },
                {
                    name : 'Paramètre 3',
                    content : '0.00'
                },
            ],
            section : [
                {
                    type : 0,
                    text : 'Text de ma section',
                    image : 'image.jpg'
                }
            ]
        },
        documents : [
            {
                name : 'garantie',
                content : ''
            },
            {
                name : 'fiche technique',
                content : ''
            },
            {
                name : 'notice',
                content : ''
            }
        ]
    })
    const [search, setSearch] = useState({
        value : '',
        status : 0,
        result : [{
            id : 1,
            name : "Nom de l'article",
            price : '10.00',
            image : ''
        }]
    })
    const [categories, setCategories] = useState([]);
    const [sousCategories, setSousCategories] = useState([]);
    const [articles, setArticles] = useState([]);
    const [inventaire, setInventaire] = useState({
        status : 0,
        affichage : 0,
        offset : 0,
        limit : 20,
        categorie : '',
        sousCategorie : ''
    });
    const [shop, setShop] = useState({
        status : 0,
        affichage : 0,
        cart : [],
        articles : []
    });
    const [categoriesStatus, setCategoriesStatus] = useState({});
    const [shopCategoriesStatus, setShopCategoriesStatus] = useState({});
    const [fournisseurBox, setFournisseurBox] = useState({
        status : false,
        descStatus : false,
        id : 0,
        name : '',
        logo : '',
        desc : '',
        fournisseursList : []
    })

    const searchArticle = async (e) => {
        if (req.tryAuthorization(35)) {
            const searchStatus = e.target.value ? 1 : 0;
            setSearch({
                ...search, 
                value : e.target.value,
                status : searchStatus
            })
            try {
                const search = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/articleSearch",
                {
                    enterprise_id: req.user.enterprise.id,
                    article_search : e.target.value
                }
                );
                if (search.data) {
                console.log(search.data);
                setSearch({
                    ...search,
                    value: e.target.value,
                    status : e.target.value ? 1 : 0,
                    result : search.data.rows
                })
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('Vous n\'avez pas les droits pour rechercher un article');
        }
    }
    const activeArticle = async (e, id) => {
        if (req.tryAuthorization(35)) {
            if(e.target.className.includes('StockListCategoryArticleNumber') || e.target.className.includes('StockListCategoryArticleNumberLess') || e.target.className.includes('StockListCategoryArticleNumberMore')) {
                return;
            } else {
                console.log("activation de l'article : " + id);
                try {
                    const myArticle = articles.find(article => article.id === id);

                    console.log(myArticle);

                    if (myArticle) {
                        console.log(myArticle.data);
                        setProduit({
                            ...produit,
                            id : id,
                            saved : true,
                            categorie : myArticle.categorie,
                            name : myArticle.name,
                            desc : myArticle.description,
                            photo : myArticle.image,
                            prixAchatHT : myArticle.prix_achat_ht,
                            prixVCTtc : myArticle.prix_vente_conseille_ttc,
                            prixVenteTtc : myArticle.prix_vente_ttc,
                            TauxTva : myArticle.taux_tva,
                            exDispo : myArticle.exDispo,
                            StockAlert : myArticle.stock_alert,
                            technic : {
                                desc : '',
                                data : [],
                                section : []
                            },
                            documents : []
                        })
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            console.log('Vous n\'avez pas les droits pour activer un article');
        }
    }
    const createArticle = () => {
        if (req.tryAuthorization(35)) {
            setProduit({
                id : 0,
                saved : true,
                categorie : 'brome',
                name : 'Nouvel article',
                desc : '',
                photo : '',
                prixAchatHT : '0.00',
                prixVCTtc : '0.00',
                prixVenteHt : '0.00',
                prixVenteTtc : '0.00',
                TauxTva : '20',
                exDispo : 1,
                StockAlert : 1,
                technic : {
                    desc : "",
                    data : [],
                    section : []
                },
                documents : [
                    {
                        name : 'garantie',
                        content : ''
                    },
                    {
                        name : 'fiche technique',
                        content : ''
                    },
                    {
                        name : 'notice',
                        content : ''
                    }
                ]
            })
        } else {
            console.log('Vous n\'avez pas les droits pour créer un article');
        }
    }
    const handleChange = (e) => {
        if (req.tryAuthorization(35)) {
            console.log('Changement de valeur : ' + e.target.id + ' : ' + e.target.value);
            if(e.target.id === 'exDispo') {
                setArticles(articles.map(article => article.id === produit.id ? {...article, exDispo : e.target.value} : article));
            }
            setProduit({
                ...produit,
                saved : false,
                [e.target.id] : e.target.value
            });
        } else {
            console.log('Vous n\'avez pas les droits pour modifier les articles');
        }
    }
    const saveArticle = async () => {
        if (req.tryAuthorization(35)) {
            // On compare les valeurs de l'article dans articles avec les valeurs dans produit
            // Si elles sont différentes, on enregistre l'article

            if(produit.saved) {
                console.log('Article déjà enregistré');
                return;
            }
            try {
                console.log("Enregistrement de l'article : " + produit.name);

                const myArticle = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/saveArticle",
                {
                    article: produit,
                    enterprise_id: req.user.enterprise.id
                }
                );
                if (myArticle.data) {
                    console.log(myArticle.data.message);
                    console.log(myArticle.data.article_id);
                    if(produit.id === 0) {
                        setProduit({
                            ...produit,
                            id : myArticle.data.article_id,
                            saved : true
                        });
                    } else {
                        setProduit({
                            ...produit,
                            saved : true
                        });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('Vous n\'avez pas les droits pour enregistrer un article');
        }
    }
    const priceFormat = (e) => {
        if (req.tryAuthorization(35)) {
            setProduit({...produit, [e.target.id] : parseFloat(e.target.value).toFixed(2)});
        } else {
            console.log('Vous n\'avez pas les droits pour modifier les prix');
        }
    }
    const categoriesList = async (e = {}) => {
        if (req.tryAuthorization(35)) {
            console.log("Demande des catégories");
            try {
                let searchText = '';
                
                if(e && e.target && e.target.value !== undefined) {
                    setSearch({
                        ...search,
                        value : e.target.value.toLowerCase()
                    });
                    searchText = e.target.value.toLowerCase();
                } else {
                    searchText = search.value.toLowerCase();
                }

                const myCategories = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/categories",
                    {
                        search : searchText,
                        enterprise_id: req.user.enterprise.id
                    }
                );
                if (myCategories.data) {
                    console.log(myCategories.data);
                    setCategories(myCategories.data.rows);
                    if (inventaire.status === 1) {
                        sousCategoriesList(inventaire.categorie);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('Vous n\'avez pas les droits pour voir les catégories');
        }
    }
    const sousCategoriesList = async (categorie_name) => {
        if (req.tryAuthorization(35)) {
            console.log("Demande des sous-catégories");
            try {
                const myCategories = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/sousCategories",
                    {
                        enterprise_id: req.user.enterprise.id,
                        categorie_name: categorie_name,
                        search : search.value.toLowerCase()
                    }
                );
                if (myCategories.data) {
                    console.log(myCategories.data);
                    setSousCategories(myCategories.data.rows);
                    setInventaire({
                        ...inventaire,
                        status : 1,
                        categorie : categorie_name
                    });
                    if(inventaire.sousCategorie !== '') {
                        articlesListMore(inventaire.sousCategorie);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('Vous n\'avez pas les droits pour voir les sous-catégories');
        }
    }
    const articlesListMore = async (sousCategorie) => {
        if (req.tryAuthorization(35)) {
            try {
                console.log("sousCat : " + sousCategorie);
                console.log("cat : " + inventaire.categorie);
                console.log("offset : " + inventaire.offset);
                console.log("limit : " + inventaire.limit);

                const myArticle = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/articlesMore",
                    {
                        sousCategorie: sousCategorie,
                        categorie: inventaire.categorie,
                        offset: inventaire.sousCategorie === sousCategorie ? inventaire.offset : 0,
                        limit: inventaire.limit,
                        search : search.value.toLowerCase(),
                        enterprise_id: req.user.enterprise.id
                    }
                );
                if (myArticle.data) {
                    console.log(myArticle.data.rows);
                    if(inventaire.offset === 0 || inventaire.sousCategorie !== sousCategorie) {
                        myArticle.data.rows.map((article) => {
                            if(article.exCart === null || article.exCart === undefined)
                                article.exCart = 0;
                            if(article.exDispo === null || article.exDispo === undefined)
                                article.exDispo = 0;
                            return article;
                        })
                        setArticles(myArticle.data.rows);
                    } else {
                        myArticle.data.rows.map((article) => {
                            if(article.exCart === null || article.exCart === undefined)
                                article.exCart = 0;
                            if(article.exDispo === null || article.exDispo === undefined)
                                article.exDispo = 0;
                            return article;
                        })
                        setArticles([...articles, ...myArticle.data.rows]);
                    }
                    

                    setInventaire({
                        ...inventaire,
                        offset : myArticle.data.offset,
                        sousCategorie : sousCategorie
                    });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('Vous n\'avez pas les droits pour voir les articles');
        }
    }
    const categorieBack = () => {
        if (req.tryAuthorization(35)) {
            setInventaire({
                ...inventaire,
                status : 0,
                categorie : '',
                sousCategorie : ''
            });
        } else {
            console.log('Vous n\'avez pas les droits pour revenir en arrière');
        }
    }
    const articleList = async (sousCategorie) => {
        if (req.tryAuthorization(35)) {
            console.log("Demande des articles");

            try {
                const enterprise_id = req.user.enterprise.id;

                if(!enterprise_id || enterprise_id === 0) {
                    return;
                }

                const myArticle = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/articles",
                {
                    enterprise_id: enterprise_id,
                    sousCategorie : sousCategorie
                }
                );
                if (myArticle.data) {
                    // On définie exCart à 0 pour chaque article
                    console.log(myArticle.data.rows);

                    const Articles = myArticle.data.rows.map((article) => {
                        if(article.exCart === null || article.exCart === undefined)
                            article.exCart = 0;
                        if(article.exDispo === null || article.exDispo === undefined)
                            article.exDispo = 0;
                        return article;
                    })

                    setArticles(Articles);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            req.tryAuthorization(35);
        }
    }

    

    const shopArticleList = async () => {
        if (req.tryAuthorization(35)) {
            console.log("Demande des articles du shop");
            try {
                const myArticle = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/shopArticles",
                    {
                        enterprise_id: req.user.enterprise.id
                    }
                );
                if (myArticle.data) {
                    // On définie exCart à 0 pour chaque article
                    const Articles = myArticle.data.rows.map((article) => {
                        if(article.exCart === null || article.exCart === undefined)
                            article.exCart = 0;
                        return article;
                    })

                    setShop({
                        ...shop,
                        articles : Articles
                    });
                    console.log(Articles);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            req.tryAuthorization(35);
        }
    }
    const switchShop = () => {
        if (req.tryAuthorization(35)) {
            setShop({
                ...shop,
                status : shop.status === 0 ? 1 : 0,
            });
            setSearch({
                ...search,
                value: '',
                status : 0,
                result : []
            })
        } else {
            console.log('Vous n\'avez pas les droits pour modifier le shop');
        }
    }
    const switchAffichage = () => {
        if (req.tryAuthorization(35)) {
            setShop({
                ...shop,
                affichage : shop.affichage === 0 ? 1 : 0
            });
        } else {
            console.log('Vous n\'avez pas les droits pour modifier l\'affichage');
        }
    }
    const articleToCartNumber = (e, action, id) => {
        if (req.tryAuthorization(35)) {
            console.log(action + " " + id);
            let cart = shop.cart;
            let articles = shop.articles;
            let article = articles.find(article => article.id === id);
            let index = articles.findIndex(article => article.id === id);
            let indexCart = cart.findIndex(article => article.id === id);
            
            if(action === 'less') {
                if(article.exCart > 1) {
                    console.log('supérieur à 1');
                    article.exCart = article.exCart - 1;
                    articles[index] = article;
                    cart[indexCart] = article;
                } else {
                    console.log('inférieur à 1 :: ' + id);
                    article.exCart = 0;
                    articles[index] = article;
                    cart = cart.filter(obj => obj.id !== id);
                }
            } else if (action === 'more') {
                article.exCart = article.exCart + 1;
                articles[index] = article;
                if(!cart.find(article => article.id === id)){
                    cart.push(article);
                } else {
                    cart[indexCart] = article;
                }
            } else if (action === 'change') {
                let valeur = e.target.value === '' ? 0 : e.target.value;
                if(parseFloat(parseFloat(valeur)) !== article.exCart) {
                    article.exCart = parseFloat(parseFloat(valeur).toFixed(0));
                    articles[index] = article;
                    if(!cart.find(article => article.id === id)){
                        cart.push(article);
                    } else {
                        cart[indexCart] = article;
                    }
                } else {
                    console.log('rien à changer');
                    return;
                }
            }

            setShop({
                ...shop,
                articles : articles
            });
            req.modifyShopCart(cart);
        } else {
            console.log('Vous n\'avez pas les droits pour modifier le panier');
        }
    }
    const articleToStockNumber = async (e, action, id) => {
        if (req.tryAuthorization(35)) {
            console.log(action + " " + id);
            const newArticles = articles.map(article => {
                if (article.article_id === id) {
                    let newExDispo = article.exDispo;
        
                    if (action === 'less') {
                        newExDispo = Math.max(article.exDispo - 1, 0);
                    } else if (action === 'more') {
                        newExDispo = parseInt(article.exDispo) + 1;
                    } else if (action === 'change') {
                        const valeur = e.target.value === '' ? 0 : e.target.value;
                        newExDispo = parseFloat(parseFloat(valeur).toFixed(0));
                    }
                    return { ...article, exDispo: newExDispo };
                }
                return article;
            });

            try {
                const article = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/articleStock",
                    {
                        article_id: id,
                        exDispo: newArticles.find(article => article.article_id === id).exDispo,
                        enterprise_id: req.user.enterprise.id
                    }
                );
                if (article.data.success) {
                    console.log(article.data.message);
                    setArticles(newArticles);
                    // On met à jour l'article actif

                    setProduit({
                        ...produit,
                        exDispo : newArticles.find(article => article.article_id === id).exDispo
                    })
                    if(produit.article_id === id){
                        setProduit({
                            ...produit,
                            exDispo : newArticles.find(article => article.id === id).exDispo
                        })
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('Vous n\'avez pas les droits pour modifier le stock');
        }
    }
    const articleToMonthCommande = () => {
        if (req.tryAuthorization(35)) {
            console.log('Article to month commande');
            console.log(articles);

            for(let i = 0; i < articles.length; i++) {
                console.log('Article : ' + articles[i].name);
                console.log('Article : ' + articles[i].quantity);
                console.log('Article : ', articles[i]);
                if(articles[i].quantity <= articles[i].stock_alert) {
                    console.log('Article en alerte : ' + articles[i].name);
                    req.autoCommandeBuilder(articles[i]);
                }
            }
        } else {
            console.log('Vous n\'avez pas les droits pour commander');
        }
    }
    const fournisseurShuffle = async () => {
        if (req.tryAuthorization(35)) {
            console.log('Fournisseur shuffle');
            if (fournisseurBox.status) {
                setFournisseurBox({
                    ...fournisseurBox,
                    status : false,
                    descStatus : false,
                    fournisseursList : [],
                    id : 0,
                    name : '',
                    logo : '',
                    desc : ''

                })
            } else {
                try {
                    const fournisseur = await axios.post(
                        process.env.REACT_APP_SERVER_URL + "/api/fournisseur",
                        {
                            enterprise_id: req.user.enterprise.id
                        }
                    );
                    if (fournisseur.data) {
                        console.log(fournisseur.data);
                        setFournisseurBox({
                            ...fournisseurBox,
                            status : !fournisseurBox.status,
                            descStatus : false,
                            fournisseursList : fournisseur.data.fournisseurs,
                            name : '',
                            logo : '',
                            desc : ''
                        })
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            console.log('Vous n\'avez pas les droits pour voir les fournisseurs');
        }
    } 
    const fournisseurShow = (id=0) => {
        if (req.tryAuthorization(35)) {
            console.log('Fournisseur show : ' + id);
            if(!isNaN(id) && id !== 0) {
                const fournisseur = fournisseurBox.fournisseursList.find(fournisseur => fournisseur.id === id);
                setFournisseurBox({
                    ...fournisseurBox,
                    id : fournisseur.id,
                    name : fournisseur.nom,
                    logo : fournisseur.logo,
                    desc : fournisseur.description,
                    descStatus : true
                });
            } else {
                setFournisseurBox({
                    ...fournisseurBox,
                    descStatus : false,
                    name : '',
                    logo : '',
                    desc : ''
                });
            }
        } else {
            console.log('Vous n\'avez pas les droits pour voir un fournisseur');
        }
    }
    const fournisseurActive = async (id) => {
        if (req.tryAuthorization(35)) {
            console.log('Fournisseur active : ' + id);
            try {
                const fournisseur = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/fournisseurActive",
                    {
                        fournisseur_id: id,
                        enterprise_id: req.user.enterprise.id
                    }
                );
                if (fournisseur.data) {
                    setArticles(fournisseur.data.articles);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('Vous n\'avez pas les droits pour activer un fournisseur');
        }
    }
    // Organisation des articles par catégorie

    // IDEA : Moteur de recherche à améliorer
    
    const shopArticlesByCategory = shop.articles.reduce((acc, article) => {
        const category = article.categorie; // Assurez-vous que la propriété de catégorie est correcte
        if(search.status === 1) {
            if(article.name.toLowerCase().includes(search.value.toLowerCase()) || article.categorie.toLowerCase().includes(search.value.toLowerCase())) {
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push(article);
            }
        } else {
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(article);
        }
        return acc;
    }, {});
    useEffect(() => {
        console.log("Stock : ", req);
        categoriesList();
        
        //shopArticleList();
        //articleToMonthCommande();
        //IDEA : Ajouter le système de commande automatique
    }, [req.user.enterprise.id])
    useEffect(() => {
        let newArticles = shop.articles;
        newArticles.map((article) => {
            let cartArticle = req.cart.find(cartArticle => cartArticle.id === article.id);
            if(cartArticle) {
                article.exCart = cartArticle.exCart;
            } else {
                article.exCart = 0;
            }
        })
        setShop({
            ...shop,
            articles : newArticles,
            cart : req.cart
        })
    }, [req.cart])
    useEffect(() => {
        saveArticle();
    }, [produit.exDispo])
    useEffect(() => {
        console.log('Articles : ', articles);
    }, [articles])
    // Fonction pour basculer l'état déplié d'une catégorie
    const toggleCategory = (category) => {
        if(req.tryAuthorization(35)) {
            setCategoriesStatus((prevStatus) => ({
            ...prevStatus,
            [category]: !prevStatus[category],
            }));
        } else {
            console.log('Vous n\'avez pas les droits pour modifier les catégories');
        }
    };
    const toggleShopCategory = (shopCategory) => {
        if(req.tryAuthorization(35)) {
            setShopCategoriesStatus((prevStatus) => ({
            ...prevStatus,
            [shopCategory]: !prevStatus[shopCategory],
            }));
        }
    };

    const shopCategoriesJSX = Object.entries(shopArticlesByCategory).map(([shopCategory, articles]) => (
        <div className='StockListCategory' key={shopCategory}>
          {/* En-tête de catégorie dépliable */}
          <div className={!shop.affichage ? 'StockListCategoryTitle' : 'StockListCategoryTitleGridStyle'} onClick={() => toggleShopCategory(shopCategory)}>
            {shopCategory} <span>{shopCategoriesStatus[shopCategory] ? <ArrowUpSquareFill size={16} /> : <ArrowDownSquareFill size={16}/>}</span>
          </div>
          {/* Articles sous la catégorie si dépliée */}
          {shopCategoriesStatus[shopCategory] && (
            <ul className={!shop.affichage ? 'StockListCategoryArticleList' : 'StockListCategoryArticleListGridStyle'}>
              {articles.map((article) => (
                <li className={article.inStock ? (!shop.affichage ? 'StockListCategoryArticleActive' : 'StockListCategoryArticleActiveGridStyle') : (!shop.affichage ? 'StockListCategoryArticle' : 'StockListCategoryArticleGridStyle')} onClick={(e) => activeArticle(e, article.id)} key={article.id}>
                    {
                        !shop.affichage ? 
                        <>
                        {article.name} - Quantité : {article.exDispo} Ex.
                            <div className='StockListCategoryArticleNumber'>
                                <div className='StockListCategoryArticleNumberLess' onClick={(e) => articleToCartNumber(e, 'less', article.id)}>-</div>
                                <input type='number' value={article.exCart} onChange={(e) => articleToCartNumber(e, 'change', article.id)}></input>
                                <div className='StockListCategoryArticleNumberMore' onClick={(e) => articleToCartNumber(e, 'more', article.id)}>+</div>
                            </div>
                        </> : 
                        <>
                            <div className='StockListCategoryArticleImage'></div>
                            <span className='StockListCategoryArticleName'>{article.name}</span>
                            <div className='StockListCategoryArticleNumber'>
                                <div className='StockListCategoryArticleNumberLess' onClick={(e) => articleToCartNumber(e, 'less', article.id)}>-</div>
                                <input type='number' value={article.exCart} onChange={(e) => articleToCartNumber(e, 'change', article.id)}></input>
                                <div className='StockListCategoryArticleNumberMore' onClick={(e) => articleToCartNumber(e, 'more', article.id)}>+</div>
                            </div>
                        </>
                    }
                    
                    {/* IDEA : Ajoutez d'autres informations d'article au besoin */}
                </li>
              ))}
            </ul>
          )}
        </div>
    ));

    return(
        <div className='Stock'>
            <div className='StockBloc'>
                <div className='StockDesc'>
                    {fournisseurBox.status ?
                    <>
                        <div className='StockDescTypeTongue'>Fournisseurs</div>
                        <div className='StockFournisseurBox'>
                            {
                                fournisseurBox.fournisseursList.map((fournisseur) => (
                                    <div className='Fournisseur' key={fournisseur.id} onClick={() => fournisseurShow(fournisseur.id)}>
                                        <div className='FournisseurLogo'></div>
                                        <div className='FournisseurName'>{fournisseur.nom}</div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={fournisseurBox.descStatus ? 'StockFournisseurDesc' : 'StockFournisseurDescInactive'}>
                            <div className='FournisseurDescTitle'>{fournisseurBox.name}</div>
                            <div className='FournisseurActiveButton' onClick={() => fournisseurActive(fournisseurBox.id)}>Activer</div>
                            <dic className='FournisseurCloseButton' onClick={fournisseurShow}><XSquareFill size={18}/></dic>
                            <div className='FournisseurDescText'>{fournisseurBox.desc}</div>
                        </div>
                    </> :
                    <>
                        <div className='StockDescTypeTongue'>{produit.id === 0 ? 'Nouvel article' : 'Article'}</div>
                        <div className='StockMainDescBox'>
                            <div className='StockMainDesc'>
                                <div className='StockMainDescName'><input type='text' placeholder='Nom du produit' id='name' value={produit.name} onChange={handleChange}/></div>
                                Description : <br/>
                                <textarea className='StockMainDescText'  id='desc' value={produit.desc} onChange={handleChange}>
                                    {produit.desc}
                                </textarea>
                                <div className='StockMainDescName'>
                                    Catégorie : 
                                    <select id='categorie' className='StockMainDescCat' value={produit.categorie} onChange={handleChange}>
                                        <optgroup label="Chimie">
                                            <option value='brome'>Brome</option>
                                            <option value='chlore'>Chlore</option>
                                            <option value='hypochloryte'>Hypochloryte</option>
                                            <option value='oxygene actif'>Oxygène actif</option>
                                        </optgroup>
                                        <optgroup label="Hammam">
                                            <option value='mesure'>Sur mesure</option>
                                            <option value='portatif'>Portatif</option>
                                        </optgroup>
                                        <optgroup label="Entretien">
                                            <option value='balais et brosse'>Balais et brosse</option>
                                            <option value='tuyaux aspirateur'>Tuyaux aspirateur</option>
                                        </optgroup>
                                        <optgroup label="Loisir">
                                            <option value='jeux piscine'>Jeux de piscine</option>
                                            <option value='flotteurs'>Flotteurs</option>
                                            <option value='éclairage'>Éclairage pour piscine</option>
                                        </optgroup>
                                        <optgroup label="P.M.R.">
                                            <option value='accessoires adaptés'>Accessoires adaptés</option>
                                            <option value='rampe et accès'>Rampes et accès facilité</option>
                                            <option value='sièges et supports'>Sièges et supports spécifiques</option>
                                            <option value='équipement de sécurité'>Équipement de sécurité adapté</option>
                                        </optgroup>
                                        <optgroup label="Piscine">
                                            <option value='piscine bois'>Bois</option>
                                            <option value='coque'>Coque</option>
                                            <option value='piscine tubulaire'>Tubulaire</option>
                                        </optgroup>
                                        <optgroup label="Robot">
                                            <option value='nettoyeur automatique'>Nettoyeurs automatiques</option>
                                            <option value='robot électrique'>Robots électriques</option>
                                            <option value='accessoires robot'>Accessoires pour robots</option>
                                        </optgroup>
                                        <optgroup label="Sauna">
                                            <option value='poêle sauna'>Poêles pour sauna</option>
                                            <option value='accessoires sauna'>Accessoires pour sauna</option>
                                            <option value='huiles essentielles'>Huiles essentielles pour sauna</option>
                                        </optgroup>
                                        <optgroup label="Sécurité">
                                            <option value='alarme piscine'>Alarmes pour piscine</option>
                                            <option value='barrière sécurité'>Barrières de sécurité</option>
                                            <option value='couverture piscine'>Couvertures de sécurité</option>
                                        </optgroup>
                                        <optgroup label="Spa">
                                            <option value='jacuzzi'>Jacuzzis</option>
                                            <option value='produits spa'>Produits d'entretien pour spa</option>
                                            <option value='accessoires spa'>Accessoires pour spa</option>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                            <div className='StockMainDescImage' style={{
                                backgroundImage : `url("${produit.photo}")`
                            }}>
                            </div>
                        </div>
                        <div className='StockPrices'>
                            <div className='StockPriceCase'>
                                <div className='StockPriceName'>PRIX ACHAT HT</div>
                                <div className='StockPrice'>
                                    <input type='text' placeholder='00.00' id='prixAchatHT' onBlur={priceFormat} onChange={handleChange} value={produit.prixAchatHT}></input><span>€</span>
                                </div>
                            </div>
                            <div className='StockPriceCase'>
                                <div className='StockPriceName'>Prix vente conseillé ttc</div>
                                <div className='StockPrice'>
                                    <input type='text' placeholder='00.00' id='prixVCTtc' onBlur={priceFormat} onChange={handleChange} value={produit.prixVCTtc}></input><span>€</span>
                                </div>
                            </div>
                            <div className='StockPriceCase'>
                                <div className='StockPriceName'>PRIX vente ttc</div>
                                <div className='StockPrice'>
                                    <input type='text' placeholder='00.00' id='prixVenteTtc' onBlur={priceFormat} onChange={handleChange} value={produit.prixVenteTtc}></input><span>€</span>
                                </div>
                            </div>
                            <div className='StockPriceCase'>
                                <div className='StockPriceName'>Taux TVA</div>
                                <div className='StockPrice'>
                                    <select type='text' id='TauxTva' className='StockTvaSelect' onChange={handleChange} value={produit.TauxTva}>
                                        <option value='0'>0%</option>
                                        <option value='5.5'>5.5%</option>
                                        <option value='10'>10%</option>
                                        <option value='12.5'>12.5%</option>
                                        <option value='20'>20%</option>
                                    </select>
                                </div>
                            </div>
                            <div className='StockPriceCase'>
                                <div className='StockPriceName'>Nombre d'exemplaire disponible</div>
                                <div className='StockPrice'>
                                    <input type='number' placeholder='00' id='exDispo' onChange={handleChange} value={produit.exDispo}></input><span>Ex.</span>
                                </div>
                            </div>
                            <div className='StockPriceCase'>
                                <div className='StockPriceName'>Stock Alert</div>
                                <div className='StockPrice'>
                                    <input type='number' id='StockAlert' onChange={handleChange} value={produit.StockAlert} placeholder='00'></input><span>Ex.</span>
                                </div>
                            </div>
                        </div>
                        <div className='StockTechnic'>
                            <div className='StockTecnicDescBox'>
                                {/* Non éditable par les pisciniste, éditable par le fournisseur */}
                                <span className='StockTecnicDescBoxTitle'>Description technique</span>
                                <br/>
                                {produit.technic.desc}
                                <div className='StockTechnicParameters'>
                                    {produit.technic.data.map((line, index) => (
                                        <div key={index} className='StockTechnicParameter'>
                                            <div className='StockTechnicParameterLeft'>{line.name}</div>
                                            <div className='StockTechnicParameterRight'>{line.content}</div>
                                        </div>
                                    ))}
                                </div>
                                {produit.technic.section.map((section) => (
                                    <div className={section.type === 1 ? 'StockTechnicImageTextBloc' : 'StockTechnicImageTextBlocInverted'}>
                                        <div className='StockTechnicBlocImage'></div>
                                        <div className='StockTechnicBlocText'>
                                            {section.text}
                                        </div>
                                    </div>
                                ))}
                                {/* <div className='StockTechnicLargeImage'></div> */}
                            </div>
                            <div className='StockTecnicDocs'>
                                DOCUMENTATIONS <br/>
                                {/* NOTE : Les boutons documents ne sont que des liens vers les document */}
                                {produit.documents.map((line) => (
                                    <div className='StockTechnicDoc'>
                                        <div className='StockTechnicDocName'>{line.name}</div>
                                        <div className='StockTechnicDocView'></div>
                                        <div className='StockTechnicDocSend'></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='StockSaveBar'>
                            <div className={produit.saved ? 'StockSaveButtonUnactive' : 'StockSaveButton'} onClick={saveArticle}>Enregistrer</div>
                        </div>
                    </>}
                </div>
                <div className='StockList'>
                    <div className='StockListActionBar'>
                        <div className='StockListCreateButton' onClick={createArticle}>Créer un article</div>
                    </div>
                    <div className='StockSearch'><input type='text' value={search.value} onChange={categoriesList} placeholder='Rechercher un article'/><span className='SearchIcon'></span></div>
                    <div className='StockListCategories'>
                        {!shop.status ? (
                            inventaire.status === 0 ? (
                                categories.map((category) => (
                                    <div className='StockListCategory' key={category.categorie}>
                                        <div className='StockListCategoryTitle' onClick={() => sousCategoriesList(category.categorie)}>{category.categorie}</div>
                                    </div>
                                ))
                            ) : (
                                <>
                                    <div className='StockListCategoryBack' onClick={categorieBack}>Retour</div>
                                    {sousCategories.map((sousCategorie) => (
                                        <div className='StockListCategory' key={sousCategorie.sous_categorie}>
                                            <div className='StockListCategoryTitle' onClick={inventaire.sousCategorie === sousCategorie.sous_categorie ? () => setInventaire({...inventaire, sousCategorie : ''})  : () => articlesListMore(sousCategorie.sous_categorie)}>{sousCategorie.sous_categorie}</div>
                                            {
                                                inventaire.sousCategorie === sousCategorie.sous_categorie && (
                                                    <div className='StockListCategoryArticleList'> 
                                                        {articles.map((article) => (
                                                            !shop.affichage ? (
                                                                <div className='StockListCategoryArticle' key={article.id} onClick={(e) => activeArticle(e, article.id)}>
                                                                    {article.name}
                                                                    <div className='StockListCategoryArticleNumber'>
                                                                        <div className='StockListCategoryArticleNumberLess' onClick={(e) => articleToStockNumber(e, 'less', article.article_id)}>-</div>
                                                                        <input type='number' value={article.exDispo} onChange={(e) => articleToStockNumber(e, 'change', article.article_id)}></input>
                                                                        <div className='StockListCategoryArticleNumberMore' onClick={(e) => articleToStockNumber(e, 'more', article.article_id)}>+</div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='StockListCategoryArticleGridStyle' key={article.id} onClick={(e) => activeArticle(e, article.id)}>
                                                                    <div className='StockListCategoryArticleImage' style={{
                                                                        backgroundImage : `url("${article.image}")`
                                                                    }}></div>
                                                                    <div className='StockListCategoryArticleName'>{article.name}</div>
                                                                    <div className='StockListCategoryArticleQte'>
                                                                        <div className='StockListCategoryArticleNumber'>
                                                                            <div className='StockListCategoryArticleNumberLess' onClick={(e) => articleToStockNumber(e, 'less', article.article_id)}>-</div>
                                                                            <input type='number' value={article.exDispo} onChange={(e) => articleToStockNumber(e, 'change', article.article_id)}></input>
                                                                            <div className='StockListCategoryArticleNumberMore' onClick={(e) => articleToStockNumber(e, 'more', article.article_id)}>+</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ))}
                                                        <div className='StockListArticlesMore' onClick={() => articlesListMore(sousCategorie.sous_categorie)}>Voir plus</div>
                                                    </div>
                                                )      
                                            }
                                        </div>
                                    ))}
                                </>
                            )
                        ) : (
                            shopCategoriesJSX.length > 0 ? shopCategoriesJSX : <div className='StockListCategory'>Aucun article trouvé</div>
                        )}
                    </div>

                    <div className='StockListSwitchBand'>
                        <div className={shop.status ? 'StockListSwitchButtonStock' : 'StockListSwitchButtonStock StockListSwitchButtonStockActive'} onClick={switchShop}>Inventaire</div>
                        {/* <div className={!shop.status ? 'StockListSwitchButtonShop' : 'StockListSwitchButtonShop StockListSwitchButtonShopActive'}onClick={switchShop}>Magasin</div> */}
                        <div className='AffichageSwitch' onClick={switchAffichage}>
                            <div className={shop.affichage ? 'AffichageSwitchButtonLeft' : 'AffichageSwitchButtonLeft AffichageSwitchButtonLeftActive'}><ListTask size={16}/></div>
                            <div className={!shop.affichage ? 'AffichageSwitchButtonRight' : 'AffichageSwitchButtonRight AffichageSwitchButtonRightActive'}><Grid size={16}/></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='FournisseurSwitchBar'>
                <div className={fournisseurBox.status ? 'FournisseurSwitchButtonRed' : 'FournisseurSwitchButton'} onClick={fournisseurShuffle}>{fournisseurBox.status ? 'Fermer' : 'Voir les fournisseurs'}</div>
            </div>
        </div>
    )
}

export default Stock;