import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './NotificationPanel.css';

function NotificationPanel (props) {
    const [notif, setNotif] = useState({
        status : 0,
        notif_nbr : 0,
        notifs : [
            {
                type : 0,
                name : 'Ma notification',
                content : 'contenue de ma super notification',
                action : ''
            },
            {
                type : 1,
                name : 'Ma notification',
                content : 'contenue de ma super notification',
                action : ''
            }
        ]
    })
    const {notifToggle} = props;
    const closeNotificationPanel = () => {    
        console.log(props);
        notifToggle();
    }

    useEffect(() => {
        if (props.notif.status !== notif.status) {
            setNotif(prevNotif => ({
                ...prevNotif,
                status: prevNotif.status === 0 ? 1 : 0
            }));
        }
    }, [props.notif.status, notif.status]);
    useEffect(() => {
        props.notif.notifs.forEach(notif => {
            setNotif(prevNotif => ({
                ...prevNotif,
                notifs: [
                    ...prevNotif.notifs,
                    {
                        type: notif.type,
                        name: notif.name,
                        content: notif.content,
                        action: notif.action
                    }
                ]
            }));
        })
    }, [props.notif.notifs]);   

    return(
        <div className={notif.status === 1 ? 'NotificationPanel' : 'NotificationPanelInactive'}>
            <div className='NotifPanelName'>NOTIFICATIONS <span className='NotificationCloser' onClick={closeNotificationPanel}>&#60;</span></div>
            <div className='NotificationZone'>
                {notif.notifs.map((notif, index) => (
                    <div className='Notification' key={index}>
                        <div className='NotificationContent'>
                            {notif.name} : <span className='NotificationText'> {notif.content}</span>
                        </div>
                        <div className='NotifActionPart'>
                            {notif.type === 0 ?
                            (<div className='NotifImage'></div>) :
                            (<div className='NotifActionButton' href={notif.action}>Voir</div>)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default NotificationPanel;