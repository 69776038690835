import React, { useEffect } from 'react';
import './Chat.css';
import axios from 'axios';

function Chat() {

    const [discussion, setDiscussion] = React.useState([]);
    const [activeDiscussion, setActiveDiscussion] = React.useState({
        id: -1,
        messages: []
    });
    const [chat, setChat] = React.useState({
        message: ''
    });
    const  loadChat = async () => {
        const response = await axios.post('http://localhost:3003/api/adminChat', {
            token: localStorage.getItem('sessionToken')
        });
        console.log(response);

        if (response.data.error) {
            console.error(response.data.error);
            return;
        } else if (response.data.discussions) {
            console.log(JSON.parse(response.data.discussions[0].messages));

            setDiscussion(response.data.discussions);
            setActiveDiscussion({
                ...activeDiscussion,
                id: response.data.discussions[0].id,
                messages: JSON.parse(response.data.discussions[0].messages)
            });
        }
    }
    const conversationSwitch = (discussion, index) => {
        console.log(discussion);
        setActiveDiscussion({
            ...activeDiscussion,
            id: discussion.id,
            messages: JSON.parse(discussion.messages)
        });
    }

    const handleChatMessage = (e) => {
        setChat({
            ...chat,
            message: e.target.value
        });
    }
    const sendMessagePress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    }
    const sendMessage = async () => {
        const response = await axios.post('http://localhost:3003/api/sendAdminMessage', {
            token: localStorage.getItem('sessionToken'),
            message: chat.message,
            discussionId: activeDiscussion.id
        });
        console.log(response);

        if (response.data.error) {
            console.error(response.data.error);
            return;
        } else if (response.data.messages) {
            setActiveDiscussion({
                ...activeDiscussion,
                messages: response.data.messages
            });
            setChat({
                ...chat,
                message: ''
            });
            updateDiscution();
        }
    }
    const updateDiscution = async () => {
        const response = await axios.post('http://localhost:3003/api/adminChat', {
            token: localStorage.getItem('sessionToken')
        });

        if (response.data.error) {
            console.error(response.data.error);
            return;
        } else if (response.data.discussions) {
            setDiscussion(response.data.discussions);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            updateDiscution();
        }, 20000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        loadChat();
    }, []);

    return (
        // Cette Version ne sera que sur téléphone
        <div className="Chat">
            <h1>Chat</h1>
            <div className='discussionZone'>
                <div className='messages'>
                    {
                        activeDiscussion.messages.map((message, index) => {
                            console.log(message);
                            return (
                                <div key={index} className={'message message_' + message.type}>
                                    <p>{message.text}</p>
                                </div>
                            );
                        })
                    }
                </div>
                <div className='inputZone'>
                    <input type='text' placeholder='Message' value={chat.message} onChange={handleChatMessage} onKeyDown={sendMessagePress} />
                    <button onClick={sendMessage}>Envoyer</button>
                </div>   
            </div>
            <div className='ContactsZone'>
                {
                    discussion.map((discussion, index) => {
                        return (
                            <div key={index} className='contact' onClick={() => conversationSwitch(discussion, index)}>
                                <p className='LastMessage'>
                                        {JSON.parse(discussion.messages)[JSON.parse(discussion.messages).length - 1].text ? JSON.parse(discussion.messages)[JSON.parse(discussion.messages).length - 1].text.substring(0, 20) + '...' : ''}
                                </p>
                                <div className={JSON.parse(discussion.messages)[JSON.parse(discussion.messages).length - 1].type && JSON.parse(discussion.messages)[JSON.parse(discussion.messages).length - 1].type === 'user' ? 'statusLightRed' : 'statusLight'}></div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default Chat;