import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';

export const AuthContext = createContext({ currentUser: null, setCurrentUser: null, isAuthenticated: false, setIsAuthenticated: null });

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleToken = (token) => {
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 > Date.now()) {
        console.log('Token not expired');
        localStorage.setItem('session_token', token);
        setCurrentUser(decoded);
        setIsAuthenticated(true);
      } else {
        console.log('Token expired');
        localStorage.removeItem('session_token');
        document.cookie = 'session_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/app;';
        navigate('/', { replace: true });
      }
    } catch (error) {
      console.error("Erreur lors du décodage du token : ", error);
      localStorage.removeItem('session_token');
      document.cookie = 'session_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/app;';
      navigate('/', { replace: true });
    }
  }

  useEffect(() => {
    console.log('%cAuthProvider useEffect', 'background-color: white; color: red; padding: 5px; border-radius: 3px;');
    const token = localStorage.getItem('session_token');
    const cookie = document.cookie.split('; ').find(row => row.startsWith('session_token='))?.split('=')[1];

    if (token) {
      console.log('Token found in localStorage');
      handleToken(token);
    } else if (cookie) {
      console.log('Cookie found');
      handleToken(cookie);
    }
    setIsLoading(false);
  }, []);

  function logout() {
    console.log('LOGOUT');
    setIsLoading(true);
    localStorage.removeItem('session_token');
    document.cookie = 'session_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/app;';
    setCurrentUser(null);
    setIsAuthenticated(false);
    setIsLoading(false);
    navigate('/', { replace: true });
  }

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, isLoading, logout, isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
