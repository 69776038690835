import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Facturation.css";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Bank, Cash, CreditCard, EnvelopeAt, FileEarmarkPlus, Pen, Printer, XSquareFill } from "react-bootstrap-icons";

import parse from 'html-react-parser';

function Facturation(req) {
  const [formStatus, setFormStatus] = useState(0);
  // Status (mode liste visualisation / Création modification)
  const [DF, setDF] = useState(0);
  // Switch entre facture et devis pour la recherche
  const [entryDate, setEntryDate] = useState();
  // Date à partir de laquelle les factures ont été créées
  const [endDate, setEndDate] = useState();
  // Date jusqu'à laquelle les factures ont été créées
  const [search, setSearch] = useState('');
  // Contenue de l'input de recherche
  const [activeFacture, setActiveFacture] = useState(0);
  // Id de la facture affichée (ne correspond pas au numéro de facture)
  const [facture, setFacture] = useState({
    id : null,
    enterprise_id : 0,
    num : 0,
    type : 0,
    name : '',
    clientSearch : '',
    clientSearchTable : [],
    editable : 1,
    registred : 1,
    creaDate : new Date(),
    articles : [],
    client : {
      id : 0,
      name : '',
      surname : '',
      adresse : '',
      adresseBis : '',
      zip : '',
      city : '',
      tel : '',
      mail : ''
    },
    total_ht : 0,
    total_ttc : 0,
    total_taxe : 0,
    rDue : 0,
    reglement : []
  });
  /* 
    Objet facture contenant toutes les données de la facture active
    - id
    - num (ex : F000001)
    - type (0 / 1 | défini si la pièce est un devis ou une facture)
    - editable (défini si la pièce est éditable)
    - creaDate (date de création)
    - articles [
      - article {
        -id (de l'article en bdd)
        - nom
        - prixHt
        - qantite
        - taxe
        - description
        - option (0 / 1 | permet de mettre un article en option)
      }
    ]
    client {
      - id (du client en bdd)
      - nom
      - prenom
      - adresse
      - adresseBis
      - zip
      - city
      - tel
      - mail
    } 
  */
  const [factureList, setFactureList] = useState([]);
  const [pdf, setPdf] = useState({
    url : '',
    status : 0
  });
  const [mailComplete, setMailComplete] = useState({
    status : 0,
    mail : '',
    searchResult : {}
  })
  /* 
    Contient la liste des factures selon champs de recherche
    - Facture {
      - id (de la facture en bdd)
      - num (ex : F000001)
      - nom (on peut nommé les facture / ca n'apparait pas sur l'impression finale)
      - totalTtc
      - client (Nom + prénom du client)
      - creaDate (date de création)
    }
  */
  const updating = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [parameter, setParameter] = useState({
    status : 0,
    saved : true,
    conditionReglement : "30% à la commande,\n 70% à la livraison.",
    conditionVente : "Paiement à réception de facture.",
    modele : 0,
    baseColor : '#ffffff',
    secondaryColor : '#ffffff',
    fontColor : '#000000',
    font : 'Arial',
    fontSize : 12,
    iban : '',
    bic : ''
  })
  const [avoir, setAvoir] = useState({
    status : 0,
    name : 'Avoir sur facture n°' + facture.num,
    articles : [],
    total_ttc : 0,
    total_ht : 0,
    total_taxe : 0,
    newArticle : {},
    toggleAvoirArticleCreate : 0
  })
  const [reglement, setReglement] = useState({
    status : false,
    id: 0,
    reglements: [],
    newReglements : [],
    amount : 0,
    rDue : 0
  })
  const [bassin, setBassin] = useState({
    id : 0,
    article_id : 0,
    status: false,
    name: '',
    largeur: 0,
    longueur: 0,
    profondeur: 0,
    volume: 0,
    comment: '',
    type: '',
    structure: '',
    revetement: '',
    date: new Date()
  })

  const fdSwitch = () => {
    let switchChoice = DF === 0 ? 1 : 0; 
    setDF(switchChoice);
  };
  const factureListing = async () => {
    console.log('factureListing entreprise id : ', req.user.enterprise.id);
    try {
      const liste = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/factureList",
          {
              enterprise_id: req.user.enterprise.id,
              df_choice : DF
          }
      );
      console.log('df_choice : ', DF);
      if (liste.data) {
          var newListe = [];
          console.log('Piece :: ', liste.data.rows)
          liste.data.rows.map((piece) => {

            let articlesList = piece.content === undefined ? [] : (typeof piece.content === "string" ? JSON.parse(piece.content) : piece.content);
            articlesList = typeof articlesList === "string" ? JSON.parse(articlesList) : articlesList;
            articlesList = typeof articlesList === "string" ? JSON.parse(articlesList) : articlesList;

            let reglementList = piece.reglement === undefined ? [] : (typeof piece.reglement === "string" ? JSON.parse(piece.reglement) : piece.reglement);

            console.log('articlesList :: ', articlesList);

            newListe.push({
              id : piece.id,
              bassin_id : piece.bassin_id === undefined ? 0 : piece.bassin_id,
              enterprise_id : piece.enterprise_id === undefined ? 0 : piece.enterprise_id,
              num : piece.piece_nbr === undefined ? 0 : piece.piece_nbr,
              type : piece.type === undefined ? 0 : piece.type,
              name : piece.name === undefined ? '' : piece.name,
              editable : piece.editable === undefined ? 1 : piece.editable,
              registred : 1,
              refere : piece.refere === undefined ? 0 : piece.refere,
              creaDate : piece.creation_date === undefined ? new Date() : piece.creation_date,
              comment : piece.comment === undefined ? '' : piece.comment,
              articles : articlesList,
              client : {
                id : piece.client_id === undefined ? 0 : piece.client_id,
                name : piece.client_nom === undefined ? '' : piece.client_nom,
                surname : piece.client_prenom === undefined ? '' : piece.client_prenom,
                adresse : piece.client_adresse === undefined ? '' : piece.client_adresse,
                adresseBis : piece.client_adresse_bis === undefined ? '' : piece.client_adresse_bis,
                zip : piece.client_zip === undefined ? '' : piece.client_zip,
                city : piece.client_city === undefined ? '' : piece.client_city,
                tel : piece.client_tel === undefined ? '' : piece.client_tel,
                mail : piece.client_mail === undefined ? '' : piece.client_mail
              },
              total_ht : piece.total_ht === undefined ? 0 : piece.total_ht,
              total_ttc : piece.total_ttc === undefined ? 0 : piece.total_ttc,
              total_taxe : piece.total_taxe === undefined ? 0 : piece.total_taxe,
              rDue : piece.rDue === undefined ? 0 : piece.rDue,
              reglement : reglementList
            })
          })
          
          setFactureList(newListe);

      }
    } catch (error) {
        console.log(error);
    }
  };
  const fdActiveSwitch = () => {
    if(facture.num === 0){
      const newFacture = { ...facture, type: facture.type === 0 ? 1 : 0 };
      setFacture(newFacture);
    }
  };
  const handleChange = (e) => {
    // Encaisse les changements des filtres pour factures et déclenche le tri de celles-ci
    const valeur = e.target.value;
    const id = e.target.id;
    
    if (id === 'entry'){
      setEntryDate(valeur);
    } else if (id === 'end'){
      setEndDate(valeur);
    } else if (id === 'search'){
      setSearch(valeur);
    }
  };
  const factureActive = (id) => {
    console.log('factureActive');
    setActiveFacture(id);
    const piece = factureList.find(o => o.id === id);
  
    if(piece === undefined){
      factureListing();
      return;
    }

    console.log("PIECE :: ", piece);
    setFacture({
      ...facture,
      id : piece.id === undefined ? 0 : piece.id,
      bassin_id : piece.bassin_id === undefined ? 0 : piece.bassin_id,
      enterprise_id : piece.enterprise_id,
      num : piece.num === undefined ? 0 : piece.num,
      type : piece.type === undefined ? 0 : piece.type,
      name : piece.name === undefined ? '' : piece.name,
      refere : piece.refere === undefined ? 0 : piece.refere,
      comment : piece.comment === undefined ? '' : piece.comment,
      clientSearch : '',
      clientSearchTable : [],
      editable : piece.editable === undefined ? 1 : piece.editable,
      registred : 1,
      creaDate : piece.creation_date === undefined ? new Date() : piece.creation_date,
      articles : piece.articles !== null ? (typeof piece.articles === "string" ? JSON.parse(piece.articles) : piece.articles) : [],
      client : {
        ...facture.client,
        id : piece.client.id,
        name : piece.client.name,
        surname : piece.client.surname,
        adresse : piece.client.adresse,
        adresseBis : piece.client.adresseBis,
        zip : piece.client.zip,
        city : piece.client.city,
        tel : piece.client.tel,
        mail : piece.client.mail
      },
      total_ht : piece.total_ht === undefined ? 0 : piece.total_ht,
      total_taxe : piece.total_taxe === undefined ? 0 : piece.total_taxe,
      rDue : piece.rDue,
      reglement : piece.reglement !== null ? (typeof piece.reglement === "string" ? JSON.parse(piece.reglement) : piece.reglement) : []
    })
    navigate('/app/facture?id=' + id);

    if(piece.bassin_id !== undefined && piece.bassin_id !== 0) {
      loadBassin(piece.bassin_id);
    }
  };
  const loadBassin = async (id) => {
    try {
      const bassin = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/bassinLoad",
        {
            bassin_id: id
        }
      );
      if (bassin.data) {
        const technic = JSON.parse(bassin.data.technic);
        setBassin({
          ...bassin,
          status : true,
          id : bassin.data.id,
          article_id : bassin.data.article_id,
          address : bassin.data.address,
          address_bis : bassin.data.address_bis,
          zip : bassin.data.zip,
          city : bassin.data.city,

          name : technic.name,
          largeur : technic.largeur,
          longueur : technic.longueur,
          profondeur : technic.profondeur,
          volume : technic.mcube,
          type : technic.type,
          structure : technic.structure,
          revetement : technic.revetement,
          date : bassin.data.crea_date,
          observation : technic.comment,
        })
      }
    } catch (error) {
      console.log(error);
    }
  };
  const createFacture = () => {
    if(req.tryAuthorization(1)){

      setActiveFacture(0);
      setFormStatus(1);
      setFacture({
        ...facture,
        id : 0,
        date : new Date(),
        enterprise_id : req.user.enterprise.id,
        num : 0,
        type : DF,
        name : '',
        clientSearch : '',
        clientSearchTable : [],
        editable : 1,
        registred : 0,
        creaDate : new Date(),
        articles : [],
        client : {
          id : 0,
          name : '',
          surname : '',
          adresse : '',
          adresseBis : '',
          zip : '',
          city : '',
          tel : '',
          mail : ''
        },
        total_ht : 0,
        total_ttc : 0,
        total_taxe : 0,
        rDue : 0,
        reglement : []
      })
      navigate('/app/facture?id=0');
    }
  };
  const listBack = () => {
    if(facture.registred === 0 && window.confirm("êtes vous sur de vouloir quitter sans sauvegarder ?")) {
      setFormStatus(0);
      setActiveFacture(0);
      setFacture({
        ...facture,
        id : null,
        num : 0,
        type : 0,
        name : '',
        clientSearch : '',
        clientSearchTable : [],
        editable : 1,
        registred : 1,
        creaDate : new Date(),
        articles : [],
        client : {
          id : 0,
          name : '',
          surname : '',
          adresse : '',
          adresseBis : '',
          zip : '',
          city : '',
          tel : '',
          mail : ''
        },
        total_ht : 0,
        total_ttc : 0,
        total_taxe : 0,
        rDue : 0,
        reglement : []
      })
    } else if (facture.registred === 1) {
      setFormStatus(0);
      setActiveFacture(0);
      setFacture({
        ...facture,
        id : null,
        num : 0,
        type : 0,
        name : '',
        clientSearch : '',
        clientSearchTable : [],
        editable : 1,
        registred : 1,
        creaDate : new Date(),
        articles : [],
        client : {
          id : 0,
          name : '',
          surname : '',
          adresse : '',
          adresseBis : '',
          zip : '',
          city : '',
          tel : '',
          mail : ''
        },
        total_ht : 0,
        total_ttc : 0,
        total_taxe : 0,
        rDue : 0,
        reglement : []
      })
    }
  };
  const ClientChange = (e, variable) => {
    const changeCustommer = { ...facture, registred : 0, client: {
      ...facture.client,
      [variable] : e.target.value
    }};
    setFacture(changeCustommer);
  };
  const ClientSearch = async (e) => {
    const newFacture = { ...facture, clientSearch: e.target.value};
    setFacture(newFacture);
    try {
      const search = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/clientSearch",
        {
            enterprise_id: req.user.enterprise.id,
            client_search : e.target.value
        }
      );
      if (search.data) {
        setFacture({
          ...facture,
          clientSearchTable : search.data.rows,
          clientSearch: e.target.value
        })
      }
    } catch (error) {
      console.log(error);
    }
  };
  const factureChange = (e) => {
    const newFacture = { ...facture, registred : 0, name: e.target.value};
    setFacture(newFacture);
  };
  const articleCreate = () => {
    const newArticle = {
      ...facture,
      registred : 0,
      articles: [
        ...facture.articles,
        {
          id: uuidv4(), // Utiliser uuid pour générer un identifiant unique
          name: '',
          ex: 1,
          prixHt: 0,
          prixTtc: 0,
          tva : '20',
          option: 0,
          searchResult : [],
          isSearchActive : true
        },
      ],
    };
    setFacture(newArticle);
    totalActualise();
  };
  const priceFormat = (e, variable, id) => {
    var prix = e.target.value === '' ? '0' : e.target.value;
    prix = parseFloat(e.target.value.replace(/[,]/g, '.').replace(/[^\d.]/g, ''));

    if (variable === 'prixHt') {
      setFacture((ancienne_facture) => ({
        ...ancienne_facture,
        registred: 0,
        articles: ancienne_facture.articles.map((article) =>
          article.id === id
            ? {
                ...article,
                prixHt: prix.toFixed(2),
                prixTtc: (parseFloat(prix) + (prix / 100 * parseFloat(article.tva))).toFixed(2),
              }
            : article
        ),
      }));
    } else if (variable === 'prixTtc') {
      setFacture((ancienne_facture) => ({
        ...ancienne_facture,
        registred: 0,
        articles: ancienne_facture.articles.map((article) =>
          article.id === id
            ? {
                ...article,
                prixHt: (parseFloat(prix) / (1 + parseFloat(article.tva) / 100)).toFixed(2),
                prixTtc: prix.toFixed(2),
              }
            : article
        ),
      }));
    }
  };
  const articleChange = async (e, variable, id) => {
    if(variable === 'name'){
      try {
        const valeur = e.target.value;
        const search = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/articleSearch",
          {
              enterprise_id: req.user.enterprise.id,
              article_search : e.target.value
          }
        );
        if (search.data) {
          setFacture((ancienne_facture) => ({
            ...ancienne_facture,
            registred: 0,
            articles: ancienne_facture.articles.map((article) =>
              article.id === id ? { 
                ...article, 
                isSearchActive : valeur !== '' ? 1 : 0,
                searchResult : search.data.rows,
                [variable]: valeur === '' ? '' : valeur
              } : article
            )
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setFacture((ancienne_facture) => ({
        ...ancienne_facture,
        registred: 0,
        articles: ancienne_facture.articles.map((article) =>
          article.id === id ? { 
            ...article, 
            [variable]: e.target.value
          } : article
        ),
      }));
    }
  }
  const unactiveNameSearch = (e, id) => {
    console.log('Unactive Name Search');
    setFacture((ancienne_facture) => ({
      ...ancienne_facture,
      registred: 0,
      articles: ancienne_facture.articles.map((article) =>
        article.id === id ? {
          ...article,
          isSearchActive : 0
        } : article
      )
    }));
  }
  const chooseArticleName = (index) => {
    const myArticle = facture.articles.find(article => article.isSearchActive === 1);

    console.log('myArticle :: ', myArticle.searchResult[index]);

    if(myArticle.searchResult[index] && !bassin.status && myArticle.searchResult[index].categorie === 'Bassin') {
      let description_technique = JSON.parse(myArticle.searchResult[index].description_technique);
      console.log('description_technique :: ', description_technique);
      setBassin({
        id: 0,
        article_id: myArticle.searchResult[index].id,
        status: true,
        name: myArticle.searchResult[index].name,
        largeur: description_technique.largeur,
        longueur: description_technique.longueur,
        profondeur: description_technique.profondeur,
        volume: description_technique.volume,
        type: description_technique.type,
        structure: description_technique.structure,
        revetement: description_technique.revetement,
        date: new Date(),
        comment: '',
        filtration : '',
        traitement : '',
        chauffage : '',
        couverture : '',
        equipement : '',
        local : '',
        accessoire : ''
      });
    }

    setFacture((ancienne_facture) => ({
      ...ancienne_facture,
      registred: 0,
      articles: ancienne_facture.articles.map((article) =>
        article.isSearchActive === 1 ? { 
          ...article, 
          id: article.searchResult[index].id,
          name: article.searchResult[index].name,
          prixTtc: article.searchResult[index].prix_vente_ttc,
          prixHt: article.searchResult[index].prix_vente_ttc / (1 + article.searchResult[index].taux_tva / 100),
          tva: article.searchResult[index].taux_tva,
          categorie: article.searchResult[index].categorie,
          isSearchActive : 0
        } : article
      ),
    }));
  }
  const articleOption = (id) => {
    setFacture((ancienne_facture) => ({
      ...ancienne_facture,
      registred : 0,
      articles: ancienne_facture.articles.map((article) =>
        article.id === id ? { ...article, option: article.option === 0 ? 1 : 0} : article
      ),
    }));
  }
  const articleDelete = (id) => {
    /* Si l'article est de catégorie bassin, on le supprime du bassin */
    let deletedArticle = facture.articles.find(article => article.id === id);

    console.log(deletedArticle);

    if(deletedArticle && deletedArticle.categorie === 'Bassin') {
      setBassin({
        id: 0,
        article_id: 0,
        status: false,
        name: '',
        largeur: 0,
        longueur: 0,
        profondeur: 0,
        volume: 0,
        type: '',
        structure: '',
        revetement: ''
      });
    }

    setFacture((ancienne_facture) => ({
      ...ancienne_facture,
      registred: 0,
      articles: ancienne_facture.articles.filter((article) => article.id !== id),
    }));
  };
  const saveFacture = async () => {
    setFacture((ancienne_facture) => ({
      ...ancienne_facture,
      registred: 1,
    }));
    
    try {
      console.log('facture :: ', facture);
      console.log('bassin :: ', bassin);
      const liste = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/factureSave",
          {
              facture: facture,
              bassin: bassin
          }
      );
      if (liste.data) {
        console.log(liste.data.message);
        setFacture({
          ...facture,
          id : liste.data.facture_id,
          num : liste.data.num,
          client : {
            ...facture.client,
            id : liste.data.client_id
          }
        })
        if(bassin.status) {
          setBassin({
            ...bassin,
            id : liste.data.bassin_id
          })
        }
      }
    } catch (error) {
        console.log(error);
    }
    // La réponse doit être l'id de la facture ou du devis
  } ;
  const tvaChange = (e, id) => {
    const tva = parseFloat(e.target.value);
  
    setFacture((ancienne_facture) => ({
      ...ancienne_facture,
      registred: 0,
      articles: ancienne_facture.articles.map((article) =>
        article.id === id
          ? {
              ...article,
              prixTtc: (article.prixHt * (1 + tva / 100)).toFixed(2),
              tva: e.target.value,
            }
          : article
      ),
    }));
    totalActualise();
  };
  const clientChoose = (id) => {
    const choosenOne = facture.clientSearchTable.find(objet => objet.id === id);

    setFacture({
      ...facture,
      clientSearch : '',
      clientSearchTable : [],
      client : {
        id : id,
        name : choosenOne.name === undefined ? '' : choosenOne.name,
        surname : choosenOne.surname === undefined ? '' : choosenOne.surname,
        adresse : choosenOne.address === undefined ? '' : choosenOne.address,
        adresseBis : choosenOne.addressBis === undefined ? '' : choosenOne.addressBis,
        zip : choosenOne.zip === undefined ? '' : choosenOne.zip,
        city : choosenOne.city === undefined ? '' : choosenOne.city,
        tel : choosenOne.phone === undefined ? '' : choosenOne.phone,
        mail : choosenOne.mail === undefined ? '' : choosenOne.mail
      },
    })
  }
  const createPayment = () => {
    if (req.tryAuthorization(68)) {
      const newArticle = {
        ...facture,
        registred : 0,
        reglement: [
          ...facture.reglement,
          {
            id: uuidv4(), // Utiliser uuid pour générer un identifiant unique
            amount : 0,
            comment : '',
            type : 0
          }
        ],
      };
      setFacture(newArticle);
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const changePayment = (e, variable, id) => {
    if (req.tryAuthorization(70)) {
      console.log('changePayment');
      setFacture((ancienne_facture) => ({
        ...ancienne_facture,
        registred: 0,
        reglement: ancienne_facture.reglement.map((article) =>
          article.id === id ? { ...article, [variable]: e.target.value } : article
        ),
      }));
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const addPayment = () => {
    if (req.tryAuthorization(68)){
      const totalPaye = facture.reglement.reduce((acc, reglement) => acc + parseFloat(reglement.amount), 0);
      const rDue = facture.total_ht + facture.total_taxe - totalPaye;

      setFacture({
        ...facture,
        rDue : rDue
      })
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const deletePayment = (id) => {
    if (req.tryAuthorization(69)){
      setFacture((ancienne_facture) => ({
        ...ancienne_facture,
        registred: 0,
        reglement: ancienne_facture.reglement.filter((article) => article.id !== id),
      }));
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  };
  const openParameter = () => {
    if(req.tryAuthorization(5)) {
      setParameter({
        ...parameter,
        status : parameter.status === 0 ? 1 : 0
      })
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const loadParameter = async () => {
    console.log('loadParameter');
    const conditionReglementDefaut = "30% à la commande, 70% à la livraison."
    const conditionVenteDefaut = "Paiement à réception de facture."
    try {
      const liste = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/factureParameter",
          {
              enterprise_id: req.user.enterprise.id
          }
      );
      if (liste.data) {
          console.log(liste.data);
          setParameter({
            ...parameter,
            conditionReglement : liste.data.conditionReglement ? liste.data.conditionReglement : conditionReglementDefaut,
            conditionVente : liste.data.conditionVente ? liste.data.conditionVente : conditionVenteDefaut,
            modele : liste.data.modele ? liste.data.modele : 0,
            baseColor : liste.data.baseColor ? liste.data.baseColor : '#ffffff',
            secondaryColor : liste.data.secondaryColor ? liste.data.secondaryColor : '#ffffff',
            fontColor : liste.data.fontColor ? liste.data.fontColor : '#000000',
            font : liste.data.font ? liste.data.font : 'Arial',
            fontSize : liste.data.fontSize ? liste.data.fontSize : 12,
            status : 0,
            saved : true,
            iban : liste.data.iban ? liste.data.iban : '',
            bic : liste.data.bic ? liste.data.bic : '',
            logo : liste.data.logo ? liste.data.logo : ''
          })
      }
    } catch (error) {
        console.log(error);
    }
  }
  const handleParameterChange = async (e) => {
    if(req.tryAuthorization(5)) {
      setParameter({
        ...parameter,
        [e.target.id] : e.target.value,
        saved : false
      })
    } else {
      req.popToast("Vous n'avez pas l'autorisation d'executer cette action", 3)
    }
  }
  const saveParameter = async () => {
    if (req.tryAuthorization(5)) {
      try {
        const liste = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/factureParameterSave",
            {
              enterprise_id: req.user.enterprise.id,
              conditionReglement : parameter.conditionReglement,
              conditionVente : parameter.conditionVente,
              modele : parameter.modele,
              baseColor : parameter.baseColor,
              secondaryColor : parameter.secondaryColor,
              fontColor : parameter.fontColor,
              font : parameter.font,
              fontSize : parameter.fontSize,
              iban : parameter.iban,
              bic : parameter.bic
            }
        );
        if (liste.data) {
          console.log(liste.data.message)
          setParameter({
            ...parameter,
            status : 0,
            saved : true
          })
        }
      } catch (error) {
          console.log(error);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation d'executer cette action", 3)
    }
  };
  const DevisModifaction = (id) => {
    if (req.tryAuthorization(3)) {
      console.log('DevisModifaction');
      const devis = factureList.find(o => o.id === id);
      setActiveFacture(0);
      setFormStatus(1);
      setFacture({
        ...facture,
        id : devis.id,
        date : devis.date,
        bassin_id : devis.bassin_id,
        enterprise_id : devis.enterprise_id,
        num : devis.num,
        type : devis.type,
        name : devis.name,
        clientSearch : '',
        clientSearchTable : [],
        editable : devis.editable,
        registred : 1,
        creaDate : new Date(),
        articles : devis.articles !== null ? (typeof devis.articles === "string" ? JSON.parse(devis.articles) : devis.articles) : [],
        client : {
          ...facture.client,
          id : devis.client.id,
          name : devis.client.name,
          surname : devis.client.surname,
          adresse : devis.client.adresse,
          adresseBis : devis.client.adresseBis,
          zip : devis.client.zip,
          city : devis.client.city,
          tel : devis.client.tel,
          mail : devis.client.mail
        },
        total_ht : devis.total_ht,
        total_ttc : devis.total_ttc,
        total_taxe : devis.total_taxe,
        rDue : devis.rDue === null ? 0 : devis.rDue,
        reglement : devis.reglement !== null ? (typeof devis.reglement === "string" ? JSON.parse(devis.reglement) : devis.reglement) : []

        
      })
      console.log(devis);
      if(devis.bassin_id !== undefined && devis.bassin_id !== 0) {
        console.log('Bassin trouvé');
        loadBassin(devis.bassin_id);
      } else {
        console.log('Pas de bassin');
        setBassin({
          ...bassin,
          status : false,
          id : 0,
          article_id : 0,
          name : '',
          largeur : 0,
          longueur : 0,
          profondeur : 0,
          volume : 0,
          type : '',
          structure : '',
          revetement : '',
          date : new Date(),
          comment : ''
        })
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  // Fonction pour calculer la somme des prix HT des articles
  const calculerTotalPrixHT = () => {
    const totalPrixHT = facture.articles.reduce((acc, article) => parseFloat(acc) + (parseFloat(article.prixHt) * parseFloat(article.ex)), 0);
    return totalPrixHT;
  };
  // Fonction pour calculer le total des taxes (différence entre prix TTC et prix HT)
  const calculerTotalTaxes = () => {
    const totalTaxes = facture.articles.reduce((acc, article) => acc + ((parseFloat(article.prixTtc) - parseFloat(article.prixHt)) * parseFloat(article.ex)), 0);
    return totalTaxes;
  };
  const avoirToggle = () => {
    if (req.tryAuthorization(74)) {
      // On ouvre la fenetre pour avoir
      setAvoir({
        ...avoir,
        status : avoir.status === 0 ? 1 : 0,
        articles : avoir.status === 0 ? facture.articles : [],
        allChecked : 0,
        total_ht : 0,
        total_taxe : 0,
        total_ttc : 0,
        name : '',
        comment : '',
        newArticle : {
          name : '',
          ex : 1,
          prixHt : 0,
          prixTtc : 0,
          tva : '20',
          comment : ''
        }
      })
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const avoirCheckArticle = (e) => {
    if(req.tryAuthorization(74)) {
      console.log('avoirCheckArticle');
      // On ajoute ou retire un article à l'avoir
      const id = e.target.id;
      const checked = e.target.checked;
      const articlesList = avoir.articles.map((article) => article.id === id ? { ...article, checked : checked } : article);
      const allChecked = articlesList.reduce((acc, article) => acc + (article.checked ? 1 : 0), 0) === articlesList.length ? 1 : 0;
      setAvoir({
        ...avoir,
        articles : articlesList,
        total_ht : articlesList.reduce((acc, article) => acc + (article.checked ? parseFloat(article.prixHt) * parseFloat(article.ex) : 0), 0),
        total_taxe : articlesList.reduce((acc, article) => acc + (article.checked ? (parseFloat(article.prixTtc) - parseFloat(article.prixHt)) * parseFloat(article.ex) : 0), 0),
        total_ttc : articlesList.reduce((acc, article) => acc + (article.checked ? parseFloat(article.prixTtc) * parseFloat(article.ex) : 0), 0),
        allChecked : allChecked 
      })
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const articlesCheckAll = (e) => {
    if(req.tryAuthorization(74)) {
      if(e.target.checked){
        setAvoir({
          ...avoir,
          articles : avoir.articles.map((article) => ({ ...article, checked : true })),
          total_ht : avoir.articles.reduce((acc, article) => acc + parseFloat(article.prixHt) * parseFloat(article.ex), 0),
          total_taxe : avoir.articles.reduce((acc, article) => acc + (parseFloat(article.prixTtc) - parseFloat(article.prixHt)) * parseFloat(article.ex), 0),
          total_ttc : avoir.articles.reduce((acc, article) => acc + parseFloat(article.prixTtc) * parseFloat(article.ex), 0),
          allChecked : 1
        })
      } else {
        setAvoir({
          ...avoir,
          articles : avoir.articles.map((article) => ({ ...article, checked : false })),
          total_ht : 0,
          total_taxe : 0,
          total_ttc : 0,
          allChecked : 0
        })
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const avoirCreate = async () => {
    if(req.tryAuthorization(74)) {
      console.log('avoirCreate');
      try {
        const liste = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/avoirSave",
            {
                refere : facture.id,
                user_id : req.user.id,
                name : 'Avoir sur facture n°' + facture.num,
                articles : avoir.articles.filter(article => article.checked),
                total_ht : avoir.total_ht,
                total_taxe : avoir.total_taxe,
                total_ttc : avoir.total_ttc,
                rDue : 0,
                editable : 1,
                reglements : [],
                enterprise_id : req.user.enterprise.id,
                client_id : facture.client.id,
            }
        );
        if (liste.data) {
          console.log(liste.data);
          setAvoir({
            ...avoir,
            status : 0,
            newArticle: {},
            toggleAvoirArticleCreate : 0,
            comment : '',
            articles : [],
            total_ht : 0,
            total_taxe : 0,
            total_ttc : 0,
            allChecked : 0
          })
          factureActive(liste.data.id);
          factureListing();
        }
      } catch (error) {
          console.log(error);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const toggleAvoirArticleCreate = () => {
    if(req.tryAuthorization(74)) {
      setAvoir({
        ...avoir,
        toggleAvoirArticleCreate : avoir.toggleAvoirArticleCreate === 0 ? 1 : 0
      })
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const avoirNewArticleChange = (e) => {
    if(req.tryAuthorization(74)) {
      if(e.target.id === 'prixHt'){
        setAvoir({
          ...avoir,
          newArticle : {
            ...avoir.newArticle,
            [e.target.id] : e.target.value,
            prixTtc : (parseFloat(e.target.value) * (1 + parseFloat(avoir.newArticle.tva) / 100))
          }
        })
      } else if(e.target.id === 'tva'){
        setAvoir({
          ...avoir,
          newArticle : {
            ...avoir.newArticle,
            [e.target.id] : e.target.value,
            prixTtc : (parseFloat(avoir.newArticle.prixHt) * (1 + parseFloat(e.target.value) / 100))
          }
        })
      } else if (e.target.id === 'prixTtc'){
        setAvoir({
          ...avoir,
          newArticle : {
            ...avoir.newArticle,
            [e.target.id] : e.target.value,
            prixHt : (parseFloat(e.target.value) / (1 + parseFloat(avoir.newArticle.tva) / 100))
          }
        })
      } else {
        setAvoir({
          ...avoir,
          newArticle : {
            ...avoir.newArticle,
            [e.target.id] : e.target.value
          }
        })
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const avoirArticleCreate = () => {
    if(req.tryAuthorization(74)) {
      const newArticles = [...avoir.articles, {
        id: uuidv4(), // Utiliser uuid pour générer un identifiant unique
        name: avoir.newArticle.name,
        ex: avoir.newArticle.ex,
        prixHt: avoir.newArticle.prixHt,
        prixTtc: avoir.newArticle.prixTtc,
        tva : avoir.newArticle.tva,
        comment : avoir.newArticle.comment,
        checked : true
      }]
      const totalHt = newArticles.reduce((acc, article) => acc + (article.checked ? parseFloat(article.prixHt) * parseFloat(article.ex) : 0), 0);
      const totalTtc = newArticles.reduce((acc, article) => acc + (article.checked ? parseFloat(article.prixTtc) * parseFloat(article.ex) : 0), 0);
      const totalTaxe = totalTtc - totalHt;
      const allChecked = newArticles.reduce((acc, article) => acc + (article.checked ? 1 : 0), 0) === newArticles.length ? 1 : 0;
      setAvoir({
        ...avoir,
        articles : [
          ...avoir.articles,
          {
            id: uuidv4(), // Utiliser uuid pour générer un identifiant unique
            name: avoir.newArticle.name,
            ex: avoir.newArticle.ex,
            prixHt: avoir.newArticle.prixHt,
            prixTtc: avoir.newArticle.prixTtc,
            tva : avoir.newArticle.tva,
            comment : avoir.newArticle.comment,
            checked : true
          }
        ],
        toggleAvoirArticleCreate : 0,
        total_ht : totalHt,
        total_ttc : totalTtc,
        total_taxe : totalTaxe,
        newArticle : {
          name : '',
          ex : 1,
          prixHt : 0,
          prixTtc : 0,
          tva : '20',
          comment : ''
        },
        allChecked : allChecked
      })
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const avoirArticleDelete = (id) => {
    console.log('avoirArticleDelete');

    if(req.tryAuthorization(74)) {
      const newArticles = avoir.articles.filter((article) => article.id !== id);
      const totalHt = newArticles.reduce((acc, article) => acc + (article.checked ? parseFloat(article.prixHt) * parseFloat(article.ex) : 0), 0);
      const totalTtc = newArticles.reduce((acc, article) => acc + (article.checked ? parseFloat(article.prixTtc) * parseFloat(article.ex) : 0), 0);
      const totalTaxe = totalTtc - totalHt;
      const allChecked = newArticles.reduce((acc, article) => acc + (article.checked ? 1 : 0), 0) === newArticles.length ? 1 : 0;
      
      console.log('newArticles :: ', newArticles);
      console.log('totalHt :: ', totalHt); 
      console.log('totalTtc :: ', totalTtc);
      console.log('totalTaxe :: ', totalTaxe);
      console.log('allChecked :: ', allChecked);

      setAvoir({
        ...avoir,
        articles : newArticles,
        total_ht : totalHt,
        total_ttc : totalTtc,
        total_taxe : totalTaxe,
        allChecked : allChecked
      })
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const reglementToggle = (id) => {
    if(req.tryAuthorization(73)) {
      if(!reglement.status){
        console.log(facture);

        setReglement({
          ...reglement,
          status : !reglement.status,
          id : id,
          reglements : facture.reglement,
          newReglements : [],
          amount : facture.total_ttc,
          rDue : facture.rDue,
        })
      } else {
        setReglement({
          ...reglement,
          status : !reglement.status,
          id : 0,
          reglements : [],
          newReglements : [],
          amount : 0,
          rDue : 0
        })
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const addReglement = () => {
    if(req.tryAuthorization(73)) {
      console.log('addReglement');
      setReglement({
        ...reglement, 
        newReglements : [
          ...reglement.newReglements,
          {
            id: uuidv4(), // Utiliser uuid pour générer un identifiant unique
            amount : 0,
            comment : '',
            date : new Date().toISOString().split('T')[0],
            type : 0
          }
        ]
      })
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const reglementChange = (e, variable, id) => {
    console.log('reglementChange');

    if(req.tryAuthorization(73)) {
      const newReglements = reglement.newReglements.map((article) =>
        article.id === id ? { ...article, [variable]: e.target.value } : article
      )
      const totalPaye = newReglements.reduce((acc, reglement) => acc + parseFloat(reglement.amount), 0) + reglement.reglements.reduce((acc, reglement) => acc + parseFloat(reglement.amount), 0)
      const rDue = facture.total_ht + facture.total_taxe - totalPaye;
      
      setReglement({
        ...reglement,
        newReglements : newReglements,
        rDue : rDue
      })
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const reglementDelete = (id) => {
    console.log('reglementDelete');

    if(req.tryAuthorization(73)) {
      const myReglement = reglement.newReglements.filter((article) => article.id === id);
      const newRDue = reglement.rDue - myReglement[0].amount;

      setReglement({
        ...reglement,
        newReglements : reglement.newReglements.filter((article) => article.id !== id),
        rDue: newRDue
      })
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const reglementSave = async () => {
    console.log('reglementSave');

    if(req.tryAuthorization(73)) {

      try {

        // On génère un seul tableau avec les anciens et les nouveaux reglements
        const reglementsArray = [...reglement.reglements, ...reglement.newReglements];

        const liste = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/factureReglementSave",
            {
                facture_id : facture.id,
                reglements : reglementsArray,
                rDue : reglement.rDue,
                total_ttc : facture.total_ttc,
                total_ht : facture.total_ht,
                total_taxe : facture.total_taxe,
                enterprise_id : req.user.enterprise.id
            }
        );
        if (liste.data) {
          console.log(liste.data.message);
          setFacture({
            ...facture,
            reglement : reglementsArray,
            rDue : reglement.rDue,
            registred : 1
          })
          setReglement({
            ...reglement,
            status : 0,
            newReglements : [],
            amount : 0,
            rDue : 0
          })
          factureListing();
        }
      } catch (error) {
          console.log(error);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const sendMail = async() => {
    //TODO : Ajouter aux paramètre - Configuration mail client (envois depuis le mail du client)
    
    if(req.tryAuthorization(72)) {
      console.log('SEND MAIL');
      const facturationVisionZone = document.getElementById('facturationVisionZone');
      // Obtention du code HTML complet
      const fullHtmlCode = facturationVisionZone.outerHTML;
      try {
        // On vérifie que le mail du client existe
        if(facture.client.mail === '' && mailComplete.status === 0){
          // On fait apparaitre une fenetre pour remplir un mail
          console.log('MAIL SHUFFLE ACTIVE');
          mailCompleteShuffle()
        } else if (mailComplete.mail.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/)){
          // On envoie le mail
          const mail = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/factureMail",
            {
                facture: facture,
                htmlCode : fullHtmlCode,
                mail : mailComplete.mail,
                userSearch : mailComplete.searchResult ? mailComplete.searchResult.id : 0
            }
          );

          if (mail.data) {
            console.log(mail.data.message);
            req.popToast('Mail envoyé avec succès');
            setMailComplete({
              ...mailComplete,
              status : 0,
              mail : '',
              searchResult : []
            })
          }
        } else if (mailComplete.status === 1 && !mailComplete.mail.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/)){
          req.popToast('Veuillez remplir un mail valide', 1);
        } else {
          req.popToast('Veuillez remplir un mail', 1);
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const mailCompleteShuffle = () => {
    if(req.tryAuthorization(72)) {
      setMailComplete({
        ...mailComplete,
        status : mailComplete.status === 0 ? 1 : 0,
        mail : '',
        searchResult : []
      })
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const mailCompleteHandleChange = async (e) => {
    if(req.tryAuthorization(72)) {
      setMailComplete({
        ...mailComplete,
        mail : e.target.value,
        searchResult : null
      })
      // Si target.value correspond bien à un mail
      if(e.target.value.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/)){
        // On vérifie si un utilisateur n'a pas déjà le mail recherché
        try {
          const search = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/userMailSearch",
            {
                mail : e.target.value
            }
          );

          if (search.data.success) {
            console.log(search.data.message);
            console.log(search.data.user);
            setMailComplete({
              ...mailComplete,
              mail: e.target.value,
              searchResult : search.data.user
            })
          } else {
            console.log(search.data.message);
          }
        } catch (error) {
          console.log(error)
        }
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const facturePrint = async () => {
    //IDEA : Personnalisation de la facture (CSS, HTML, PDF, etc.)
  
    if(req.tryAuthorization(71)) {
      try {
        const facturationVisionZone = document.getElementById('facturationVisionZone');
      
        // Obtenez le code HTML complet (y compris l'élément racine) avec outerHTML
        const fullHtmlCode = facturationVisionZone.outerHTML;

        // Obtenez le code CSS interne (si applicable)
        const styleElement = document.querySelector('.facturationVisionZone style');
        const cssCode = styleElement ? styleElement.innerHTML : '';

        console.log("CSS : ", cssCode);

        const print = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/facturePrint",
          {
            facture: facture,
            htmlCode : fullHtmlCode,
            cssCode : cssCode
          }
        );
        if (print.data) {
          console.log("URL :: " + print.data.url);
          window.open(print.data.url, '_blank');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const devisToFacture = async () => {
    if(req.tryAuthorization(4)){
      try {
        const devis = factureList.find(o => o.id === facture.id);
        const liste = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/devisToFacture",
            {
                devis: devis,
                user_id : req.user.id
            }
        );
        if (liste.data) {
          console.log(liste.data.message);
          factureListing();
          req.popToast('Devis transformé en facture', 0);
        }
      } catch (error) {
          console.log(error);
      }
    } else {
      req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    }
  }
  const bassinChange = (e, variable) => {
    setBassin({
      ...bassin,
      [variable] : e.target.value
    })
    if(variable === 'name') {
      setFacture({
        ...facture,
        articles : facture.articles.map((article) =>
          article.id === bassin.id ? { ...article, name : e.target.value } : article
        )
      })
    }
  }
  
  const totalActualise = useCallback(() => {
    console.log('totalActualise');

    var objDiv = document.getElementById("FactureModifArticlesList");
    if(objDiv !== null)
      objDiv.scrollTop = objDiv.scrollHeight;

    if (!updating.current) {
      const totalReglement = facture.reglement.reduce((acc, reglement) => acc + parseFloat(reglement.amount), 0);
      const totalPrixHT = calculerTotalPrixHT();
      const totalTaxes = calculerTotalTaxes();
      const totalTtc = totalPrixHT + totalTaxes;
  
      updating.current = true;
  
      facture.articles.forEach((article) => {
        if (article.prixHt === '') {
          article.prixHt = 0;
        }
        if (article.prixTtc === '') {
          article.prixTtc = 0;
        }
      });

      setFacture((prevFacture) => {
        const newRDue = totalPrixHT + totalTaxes - totalReglement;
        if (
          prevFacture.total_ht !== totalPrixHT ||
          prevFacture.total_taxe !== totalTaxes ||
          prevFacture.rDue !== newRDue
        ) {
          console.log(totalPrixHT, totalTaxes, newRDue);
          return { ...prevFacture, 
            total_ht: totalPrixHT, 
            total_taxe: totalTaxes, 
            total_ttc : totalTtc, 
            rDue: newRDue
          };
        } else {
          return prevFacture; // Pas de mise à jour si les totaux n'ont pas changé
        }
      });
      
  
      updating.current = false;
    }
  }, [facture, calculerTotalPrixHT, calculerTotalTaxes]);
  useEffect(() => {
    console.log('useEffect totalActualise');
    totalActualise();
  }, [facture.articles]);  // Assurez-vous de mettre toutes les dépendances nécessaires
  useEffect(() => {
    console.log('useEffect factureList');
    factureListing();
    loadParameter();

  }, [req.user.enterprise.id, DF, facture.registred]);
  useEffect(() => {
    console.log('useEffect facture.reglement');
    addPayment();
  }, [facture.reglement]);
  useEffect(() => {
    console.log('useEffect location.search');
    const params = new URLSearchParams(location.search);
    const id = parseFloat(params.get('id'));
    const categorie = params.get('categorie');

    // Si categorie est égale à devis on switch pour devis
    
    const matchId = factureList.find(o => o.id === id);

    if(id && matchId && facture.id === null) {
      console.log('activation facture lien');
      factureActive(id);
    }
  }, [factureList]);

  useEffect(() => {
    var objDiv = document.getElementById("AvoirFormArticles");
    if(objDiv !== null)
      objDiv.scrollTop = objDiv.scrollHeight;
  }, [avoir.articles]);

  //IDEA : Facturation Prestataire vers Revendeur facilité
  //IDEA : Afficher les alertes clients après recherche du client
  //IDEA : Envois des documents par mail avec la facture

  return (
    <div className="Facturation">
      <div className="FacturationActionZone">
        <div className={formStatus === 1 ? "FacturationActionBloc" : "UnactiveBloc"}>
          <div className='FacturationActionBar'>
            <div className="FactureModifBackButton" onClick={listBack}>Retour</div>
            <div className={facture.num === 1 ? "FactureDevisChoice" :  "FactureDevisChoice FactureDevisChoiceBlured"} onClick={fdActiveSwitch}>
              <div className={facture.type === 1 ? "FactureChoice" : "FactureChoice FdActiveChoice"}>FACTURE</div>
              <div className={facture.type === 0 ? "DevisChoice" :  "DevisChoice FdActiveChoice"}>DEVIS</div>
            </div>
            <div className="FactureNum">{facture.num === 0 ? "Brouillon" : ((facture.type === 0 ? 'F' : 'D') + ('' + facture.num).padStart(6, '0'))}</div>
          </div>
          <div className="FactureModifForm">
            {/* Nom de la facture / champs de recherche permettant de dupliquer une pièce */}
            <input type='text' className='FactureModifName' placeholder="Nom de la facture" value={facture.name} onChange={factureChange}/>
            {/* Recherche du client */}
            <input type='text'className='FactureModifClientSearch' placeholder="Rechercher un client" value={facture.clientSearch} onChange={ClientSearch}/>
            {/* Infos clients / Précomplétée après une recherche */}
            {facture.clientSearch !== '' && 
              <div className='FactureClientSearchZone'>
                {facture.clientSearchTable.map((client) => (
                  <div className="clientSearchLine" onClick={() => clientChoose(client.id)}>
                    {client.name} {client.surname}
                  </div>
                ))}
              </div>
            }
            <div className='FactureModifClientInfos'>
              <input type='text' className='FactureModifClientName' placeholder="Nom du client" value={facture.client.name} onChange={(e) => ClientChange(e, 'name')}/>
              <input type='text' className='FactureModifClientSurname' placeholder='Prénom du client' value={facture.client.surname} onChange={(e) => ClientChange(e, 'surname')}/>
              <input type='text' className='FactureModifClientAdresse' placeholder="Adresse du client" value={facture.client.adresse} onChange={(e) => ClientChange(e, 'adresse')}/>
              <input type='text' className='FactureModifClientAdresseBis' placeholder="Adresse du client(suite)" value={facture.client.adresseBis} onChange={(e) => ClientChange(e, 'adresseBis')}/>
              <input type='text' className='FactureModifClientZip' placeholder="code postal" value={facture.client.zip} onChange={(e) => ClientChange(e, 'zip')}/>
              <input type='text' className='FactureModifClientCity' placeholder="Ville" value={facture.client.city} onChange={(e) => ClientChange(e, 'city')}/>
              <input type='text' className='FactureModifClientPhone' placeholder='Téléphone' value={facture.client.tel} onChange={(e) => ClientChange(e, 'tel')}/>
              <input type='text' className='FactureModifClientMail' placeholder='Adresse mail' value={facture.client.mail} onChange={(e) => ClientChange(e,'mail')}/>
            </div>

            {
              bassin.status && (
                <div className="FactureModifBassinZone">
                  <div className="FactureModifBassinTitle">Bassin</div>
                  <div className="FactureModifBassinName">Nom</div>
                  <input type='text' className='FactureModifBassinNameInput' placeholder="Nom du bassin" value={bassin.name} onChange={(e) => bassinChange(e, 'name')}/>
                  <div className="FactureModifBassinLongueur">Longueur</div>
                  <input type='number' className='FactureModifBassinLongueurInput' placeholder="Longueur du bassin" value={bassin.longueur} onChange={(e) => bassinChange(e, 'longueur')}/>
                  <div className="FactureModifBassinLargeur">Largeur</div>
                  <input type='number' className='FactureModifBassinLargeurInput' placeholder="Largeur du bassin" value={bassin.largeur} onChange={(e) => bassinChange(e, 'largeur')}/>
                  <div className="FactureModifBassinProfondeur">Profondeur</div>
                  <input type='number' className='FactureModifBassinProfondeurInput' placeholder="Profondeur du bassin" value={bassin.profondeur} onChange={(e) => bassinChange(e, 'profondeur')}/>
                  <div className="FactureModifBassinVolume">Volume</div>
                  <input type='number' className='FactureModifBassinVolumeInput' placeholder="Volume du bassin" value={bassin.volume} onChange={(e) => bassinChange(e, 'volume')}/>
                  <div className="FactureModifBassinType">Type</div>
                  <input type='text' className='FactureModifBassinTypeInput' placeholder="Type de bassin" value={bassin.type} onChange={(e) => bassinChange(e, 'type')}/>
                  <div className="FactureModifBassinStructure"> Structure</div>
                  <input type='text' className='FactureModifBassinStructureInput' placeholder="Structure du bassin" value={bassin.structure} onChange={(e) => bassinChange(e, 'structure')}/>
                  <div className="FactureModifBassinRevetement"> Revêtement</div>
                  <input type='text' className='FactureModifBassinRevetementInput' placeholder="Revêtement du bassin" value={bassin.revetement} onChange={(e) => bassinChange(e, 'revetement')}/>
                  <div className="FactureModifBassinDate">Date de pose</div>
                  <input type='date' className='FactureModifBassinDateInput' placeholder="Date de pose" value={bassin.date} onChange={(e) => bassinChange(e, 'date')}/>
                  <div className="FactureModifBassinComment">Commentaire</div>
                  <input type='text' className='FactureModifBassinCommentInput' placeholder="Commentaire" value={bassin.comment} onChange={(e) => bassinChange(e, 'comment')}/>
                  {/* TODO : Ajouter filtration, pompes, traitement, chauffage, couverture, equipement, local, accessoire */}
                </div>
              )
            }
            
            <div className='FactureModifArticlesList' id='FactureModifArticlesList'>
              <div className='FactureModifArticlesListLegend'>
                <div className="FactureModifArticleNameLegend">Nom article</div>
                <div className="FactureModifArticleExemplaireLegend">Ex.</div>
                <div className="FactureModifArticlePriceHtLegend">Prix HT</div>
                <div className="FactureModifArticlePriceTtcLegend">Prix TTC</div>
                <div className="FactureModifArticlePriceTtcLegend">TVA</div>
                <div className="FactureModifArticleOptionLegend">Option</div>
                <div className="FactureModifArticleDeleteLegend">X</div>
                
              </div>
              {facture.articles.map((article) => (
                <div className='FactureModifArticleLine' key={article.id}>
                  <div className='FactureModifArticleNameZone'>
                    <input 
                      type='text' 
                      className="FactureModifArticleName" 
                      placeholder="Nom de l'article" 
                      value={article.name ? article.name : ''} 
                      onChange={(e) => articleChange(e, 'name', article.id)} 
                      onActive={(e) => articleChange(e, 'name', article.id)} 
                      onKeyDown={(e) => (e.keyCode === 13 || e.keyCode === 9) && unactiveNameSearch(e, article.id)} defaultValue="" />
                    {article.isSearchActive && article.searchResult.length > 0 && (
                        <ul className="FactureModifArticleNameList">
                          {article.searchResult.map((result, index) => (
                            <li key={index} onClick={() => chooseArticleName(index)}>{result.name}</li>
                          ))}
                        </ul>
                      )}
                  </div>
                  {/* Fait office de champs de recherche pour article */}
                  <input type='number' className="FactureModifArticleExemplaire" placeholder='Nbr ex.' value={article.ex} onChange={(e) => articleChange(e, 'ex', article.id)}/>
                  <input type='number' className="FactureModifArticlePriceHt" placeholder='Prix HT' value={parseFloat(article.prixHt)}  onBlur={(e) => priceFormat(e, 'prixHt', article.id)} onChange={(e) => articleChange(e, 'prixHt', article.id)}/>
                  <input type='number' className="FactureModifArticlePriceTtc" placeholder='Prix TTC'  value={parseFloat(article.prixTtc)} onBlur={(e) => priceFormat(e, 'prixTtc', article.id)} onChange={(e) => articleChange(e, 'prixTtc', article.id)}/>

                  <select className="FactureModifArticlePriceTva" placeholder='TVA'  value={article.tva} onBlur={(e) => tvaChange(e, article.id)} onChange={(e) => articleChange(e, 'tva', article.id)}>
                    <option value='0'>0%</option>
                    <option value='5.5'>5,5%</option>
                    <option value='10'>10%</option>
                    <option value='12.5'>12,5%</option>
                    <option value='20' selected>20%</option>
                  </select>
                  
                  <div className={article.option === 0 ? 'FactureModifArticleOption' : 'FactureModifArticleOptionActive'} onClick={() => articleOption(article.id)}>option</div>
                  <div className='FactureModifArticleDelete' onClick={() => articleDelete(article.id)}>X</div>
                </div>
              )) }
              
              <div className='FactureModifArticleMore' onClick={articleCreate}>Nouvel article</div>
            </div>
            <div className='FactureReglementZone'>
                <div className="newReglementButton" onClick={createPayment}>Ajouter un réglement</div>
                {
                  facture.reglement.map((reglement) => (
                    <div className="factureReglementLine">
                      <select type='text' className='factureReglementLineType' placeholder='montant' value={reglement.type} onChange={(e) => changePayment(e, 'type', reglement.id)}>
                        <option value={0}>Carte Bleue</option>
                        <option value={1}>Chèque</option>
                        <option value={2}>Espèce</option>
                        <option value={3}>Virement bancaire</option>
                      </select>
                      <input type='number' className='factureReglementLineAmount' placeholder='montant' value={reglement.amount} onChange={(e) => changePayment(e, 'amount', reglement.id)}></input>
                      <input type='text' className='factureReglementLineComment' placeholder='commentaire' value={reglement.comment} onChange={(e) => changePayment(e, 'comment', reglement.id)}></input>
                      <div className='FactureModifArticleDelete' onClick={() => deletePayment(reglement.id)}></div>
                    </div>
                ))
                } 
            </div>
            <div className='FactureRestantDue'>
              {
                facture.rDue <= 0 ? (
                  <div className="FactureRegle">Réglée !</div>
                ) : (
                  "Restant due : " + facture.rDue
                )
              }
            </div>
            
            <div className='FactureSave' onClick={saveFacture}>Enregistrer</div>
            <div className="FactureSaveStatus">{facture.registred === 0 ? "Pas enregistré" : "Enregistré"}</div>
          </div>
        </div>
        <div className={formStatus === 0 ? "FacturationActionBloc" : "UnactiveBloc"}>
          <div className="FacturationActionBar">
            <div className="FactureDevisChoice" id="FactureDevisChoice" onClick={fdSwitch}>
              <div className={DF === 1 ? "FactureChoice" : "FactureChoice FdActiveChoice"}>FACTURE</div>
              <div className={DF === 0 ? "DevisChoice" :  "DevisChoice FdActiveChoice"}>DEVIS</div>
            </div>
            <div className="FactureDateChoice">
              Du <input type='date' className='FactureDateInput' id='entry' onChange={handleChange} value={entryDate}></input> au <input type='date' className='FactureDateInput' id='end' onChange={handleChange} value={endDate}></input>
            </div>
            <input
              type="text"
              className="FactureSearch"
              placeholder=" Rechercher une facture..."
              id='search'
              onChange={handleChange}
              value={search}
            />
          </div>
        </div>
        <ul className={formStatus === 0 ? "FacturationList" : "UnactiveBloc"}>
        {factureList.length > 0 ? (
          <li className="FacturationListLegend">
            <div className="FacturationListLegendNum">Numéro</div>
            <div className="FacturationListLegendClient">Client</div>
            <div className="FacturationListLegendDate">Date</div>
          </li>
        ) : (
          <div className="FacturationListEmpty">Aucune pièce trouvée</div>
        )}
        {factureList.map((facture) => {
          const entryDateDate = new Date(entryDate);
        
          // End date doit être égale au jour à 23h59
          const endDateDate = new Date(endDate).setHours(23, 59, 59, 999);
          
          if (
            (facture.name.toLowerCase().includes(search.toLowerCase()) || facture.client.name.toLowerCase().includes(search.toLowerCase()) || facture.client.surname.toLowerCase().includes(search.toLowerCase()) || search === "" ||!search) &&
            (entryDateDate.toString() === "Invalid Date" || !entryDateDate || new Date(facture.creaDate) >= entryDateDate) &&
            (endDateDate.toString() === "Invalid Date" || !endDateDate || new Date(facture.creaDate) <= endDateDate)
          ) {
            return <li className="FacturationListItem" onClick={() => factureActive(facture.id)}>
              <div className="FacturationListNum">{facture.type === 0 ? 'F' : 'D'} {('' + facture.num).padStart(6, '0')}</div>
            
              <div className="FacturationListClient">{facture.name} ({facture.client.name} {facture.client.surname})</div>
              <div className="FacturationListDate">{new Date(facture.creaDate).toLocaleDateString('fr-FR')}</div>
              {facture.type !== 0 || (facture.type === 0 && (!facture.reglement || facture.reglement.length === 0)) && (
                <div className='DevisModifButton' onClick={() => DevisModifaction(facture.id)}>Modifier</div>
              )}
              {facture.reglement && facture.rDue > 0 && (
                <div className='DevisModifButton' onClick={() => reglementToggle(facture.id)}>Reglement</div>
              )}
              {facture.type === 0 && facture.reglement && facture.rDue <= 0 && (
                <div className='DevisModifEmptySpace'></div>
              )}
              {facture.type === 1 && (
                <div className='FactureModifButton' onClick={() => devisToFacture(facture.id)}>Facturer</div>
              )}
            </li>
          }
        })}  
        </ul>
        <div className="FacturationParamBand">
          <div className={formStatus === 0 ? "NewFactureButton" : "UnactiveBloc"} onClick={createFacture}>Nouvelle pièce</div>
          {req.tryAuthorization(5) && <div className="ParameterButton" onClick={openParameter}>Paramètres</div>}
        </div>
      </div>
      <div id='facturationVisionZone' className="FacturationVisionZone" 
        style={{color : `${parameter.fontColor}`, 
        backgroundColor : `${parameter.baseColor}`
        }}>
        {facture.id !== null ? (
          <>
            <div className="FactureVisionHeader">
              <div className="FactureVisionLogoZone">
                <img src={parameter.logo ? 'https://bluestock.fr/uploads/' + parameter.logo : '#'} alt='logo' className="FactureVisionLogo"/>
                <div className="FactureVisionEnterpriseInfo"
                  style={{color: `${parameter.fontColor}`}}
                >
                  {req.user.enterprise.name}
                  <br />
                  <br />
                  {req.user.enterprise.address}
                  <br />
                  {req.user.enterprise.address_bis}
                  <br />
                  {req.user.enterprise.zip} {req.user.enterprise.city}
                  <br />
                  {req.user.enterprise.phone}
                </div>
              </div>
              <div className="FactureVisionNumberZone">
                {facture.num === 0 ? "Brouillon" : ((facture.type === 0 ? 'F' : 'D') + ('' + facture.num).padStart(6, '0'))}
                <br />
                {new Date(facture.creaDate).toLocaleDateString('fr-FR')}
              </div>
            </div>
            <div className="FactureVisionClientInfoZone">
              <div className="FactureVisionComment">
                {
                  facture.refere && facture.refere !== 0 ?
                  "Facture d'avoir sur la facture n°" + facture.refere :
                  facture.rDue > 0 && facture.rDue < facture.total_ht + facture.total_taxe ? "Facture d'accompte"
                  : facture.comment
                }
              </div>
              <div className="FactureVisionClientInfo"
                style={{
                  color: `${parameter.fontColor}`,
                  border: `1px solid ${parameter.fontColor}`
                }}
              >
                {facture.client.name} {facture.client.surname}
                <br />
                <br />
                {facture.client.adresse}
                <br />
                {facture.client.adresseBis}
                <br />
                {facture.client.zip} {facture.client.city}
                <br />
                <br />
                {facture.client.tel}<br/>
                {facture.client.mail}

              </div>
            </div>
            <div className="FacturationVisionArticleListZone"
              style={{
                color: `${parameter.fontColor}`,
                backgroundColor: `${parameter.baseColor}`,
                border: `1px solid ${parameter.fontColor}`
              }}
            >
              <ul className="FacturationVisionArticleList">
                <li className="FacturationVisionArticleListLegend"
                  style={{
                    color: `${parameter.baseColor}`,
                    backgroundColor: `${parameter.fontColor}`,
                    border: `1px solid ${parameter.fontColor}`
                  }}
                >
                  <div className="FacturationVisionArticleListLegendDesignation">
                    Désignation
                  </div>
                  <div className="FacturationVisionArticleListLegendQte">Qté</div>
                  <div className="FacturationVisionArticleListLegendHt">
                    Prix HT
                  </div>
                  <div className="FacturationVisionArticleListLegendTtc">
                    Prix TTC
                  </div>
                </li>
                {facture.articles.map((article) => (
                  <li className="FacturationVisionArticleListItem"
                    style={{
                      color: `${parameter.fontColor}`,
                      backgroundColor: `${parameter.baseColor}`,
                      borderBottom: `1px solid ${parameter.fontColor}`
                    }}
                  >
                    <div className="FacturationVisionArticleListItemDesignation"
                      style={{
                        borderRight: `1px solid ${parameter.fontColor}`
                      }}
                    >
                      {article.name}
                    </div>
                    <div className="FacturationVisionArticleListItemQte"
                      style={{
                        borderRight: `1px solid ${parameter.fontColor}`
                      }}
                    >{article.ex}</div>
                    <div className="FacturationVisionArticleListItemHt"
                      style={{
                        borderRight: `1px solid ${parameter.fontColor}`
                      }}
                    >
                      {parseFloat(article.prixHt).toFixed(2)} €
                    </div>
                    <div className="FacturationVisionArticleListItemTtc">
                      {parseFloat(article.prixTtc).toFixed(2)} €
                    </div>
                  </li>
                )) }
                
              </ul>
            </div>
            <div className={facture.refere && facture.refere !== 0 ? "FacturationVisionReglementListInactive" : "FacturationVisionReglementList"} 
              style={{
                color: `${parameter.fontColor}`, 
                backgroundColor: `${parameter.secondaryColor}`,
                border: `1px solid ${parameter.fontColor}`
              }}
            >
              {
                facture.reglement.map((reglement) => (
                  <div className="FacturationVisionReglementLine">
                    <div className="FacturationVisionReglementType">{
                      parseFloat(reglement.type) === 0 ? <><CreditCard size={16} /> Carte Bleue</> : 
                      parseFloat(reglement.type) === 1 ? <><Pen size={16} /> Chèque</> : 
                      parseFloat(reglement.type) === 2 ? <><Cash size={16} /> Espèces</>: 
                      parseFloat(reglement.type) === 3 ? <><Bank size={16} /> Virement bancaire</>: ''
                    }</div>
                    <div className="FacturationVisionReglementAmount">Réglement de {parseFloat(reglement.amount).toFixed(2)} €</div>
                    <div className="FacturationVisionReglementComment">{reglement.comment}</div>
                  </div>
                ))
              }
            </div>
            <div className="FacturationVisionCheckingZone"
              style={{
                color: `${parameter.fontColor}`
              }}
            >
              <div className="FacturationVisionReglementZone">
                Conditions de réglement :<br />
                {parameter.conditionReglement ? parameter.conditionReglement : '30% à la commande, le solde à la livraison'}
              </div>
              <div className="FacturationVisionPriceZone">
                <div className="FacturationVisionPriceLegend">
                  Total HT :<br />
                  Total TTC :<br />
                  Dont TVA :<br />
                  {facture.type === 0 && (!facture.refere || facture.refere === 0) ? 'Restant dûe :' : ''}
                </div>
                <div className="FacturationVisionPrice">
                  {facture.refere && facture.refere !== 0 ? (facture.total_ht * -1).toFixed(2) : facture.total_ht.toFixed(2)} €<br />
                  {facture.refere && facture.refere !== 0 ? ((facture.total_ht + facture.total_taxe) * -1).toFixed(2) : (facture.total_ht + facture.total_taxe).toFixed(2)} €<br />
                  {facture.refere && facture.refere !== 0 ? (facture.total_taxe * -1).toFixed(2) : facture.total_taxe.toFixed(2)} €<br />
                  {facture.type === 0 && (!facture.refere || facture.refere === 0) ? (facture.rDue).toFixed(2) + ' €' : ''}
                </div>
              </div>
            </div>
            <div className="FactureVisionPaymentMethod"
              style={{
                color : `${parameter.fontColor}`
              }}
            >
              {parameter.iban ? 'Information bancaire' : ''}<br />
              {parameter.iban ? 'IBAN : ' + parameter.iban : ''}<br />
            </div>
            <div className="FactureVisionEnterpriseDetails"
              style={{
                color : `${parameter.fontColor}`
              }}
            >
              {/* Texte supplémentaire */}

              {req.user.enterprise.name + ' ' + req.user.enterprise.address + ' ' + req.user.enterprise.address_bis + ' ' + req.user.enterprise.zip + ' ' + req.user.enterprise.city} <br/>
              {'SIREN : ' + req.user.enterprise.siren}

            </div>
            <div className='FactureVisionFloatBar'>
              {facture.type === 0 && !facture.refere ? 
                <div className="avoirButton" onClick={avoirToggle}>Créer un avoir <FileEarmarkPlus/></div>
                : null  
              }
              <div className="sendByMailButton" onClick={sendMail}>Envoyer par mail <EnvelopeAt/></div>
              <div className="printButton" onClick={facturePrint}>Imprimer <Printer/></div>
            </div>
          </>) 
          : (
            <div className="FacturationVisionEmpty">
              Sélectionnez/Créez une pièce
              <div className="FacturationVisionEmptyButton" onClick={createFacture}> Nouvelle pièce <FileEarmarkPlus /></div>
            </div>
          ) 
        }
      </div>
      {req.tryAuthorization(5) && <div className={parameter.status === 0 ? "ParameterMask" : "ParameterMask ParameterMaskActive"}>
        {/* Finir les changements de paramètre pour  */}
        <div className="ParameterBloc">
          <div className="ParameterTitleZone">
            <div className="ParameterTitle">Paramètre
              <div className="ParameterClose" onClick={openParameter}><XSquareFill size={18}/></div>
            </div>

            <div className="ParameterVente">
              <label htmlFor="ParameterVente">Conditions de vente (se trouveront au dos de vos factures / devis)</label>
                <textarea className="ParameterVenteTextZone" id='conditionVente' value={parameter.conditionVente} onChange={handleParameterChange} placeholder="Conditions de vente">
                </textarea>
                
                <label htmlFor="ParameterVente">Conditions de Réglement</label>
                <textarea className="Paramètre condition de réglement" id='conditionReglement' value={parameter.conditionReglement} onChange={handleParameterChange} placeholder="conditions de réglement">
                </textarea>
              
            </div>
            <div className="ParameterPersonalisation">

              <div className="ParameterPersonalisationTitle">Information de paiement</div>
              <label htmlFor="iban">IBAN</label>
              <input type="text" id='iban' value={parameter.iban} onChange={handleParameterChange}/>

              <label htmlFor="bic">BIC</label>
              <input type="text" id='bic' value={parameter.bic} onChange={handleParameterChange}/>
            </div>
            <div className="ParameterPersonalisation">
              <div className="ParameterPersonalisationTitle">Personnalisation</div>
              <select className='ParamaterInvoiceStyle' id='modele' value={parameter.modele} onChange={handleParameterChange}>
                <option value="0">Modèle 1</option>
                <option value="1">Modèle 2</option>
                <option value="2">Modèle 3</option>
                <option value="3">Modèle 4</option>
              </select>
              
              <label htmlFor="ParameterDominantColor">Couleur dominante</label>
              <input type='color' className='ParameterDominantColor' id='baseColor' value={parameter.baseColor} onChange={handleParameterChange}></input>
              <div className='colorShow' style={{backgroundColor: parameter.baseColor}}></div>

              <label htmlFor="ParameterSecondaryColor">Couleur secondaire</label> 
              <input type='color' className='ParameterSecondaryColor' id='secondaryColor' value={parameter.secondaryColor} onChange={handleParameterChange}></input>
              <div className='colorShow' style={{backgroundColor: parameter.secondaryColor}}></div>

              <label htmlFor="ParameterTextColor">Couleur du texte</label>
              <input type='color' className='ParameterTextColor' id='fontColor' value={parameter.fontColor} onChange={handleParameterChange}></input>
              <div className='colorShow' style={{backgroundColor: parameter.fontColor}}></div>

              <label htmlFor="ParameterFontSize">Taille de police</label>
              <select className='ParameterFontSize' id='fontSize' value={parameter.fontSize} onChange={handleParameterChange}>
                <option value="8">8 pts</option>
                <option value="10">10 pts</option>
                <option value="12">12 pts</option>
                <option value="14">14 pts</option>
                <option value="16">16 pts</option>
                <option value="18">18 pts</option>
              </select>

              <label htmlFor="ParameterFont">Police d'écriture</label>
              <select className='ParameterFont' id='font' value={parameter.font} onChange={handleParameterChange}>
                <option value="Poppins">Poppins</option>
                <option value="Arial">Arial</option>
                <option value="Bodoni">Bodoni</option>
              </select>
              {/* 
                Personalisation :
                - logo
                - signature
                - entête
                - pied de page
              */}
              
              <div className="ParameterSave" onClick={saveParameter}>Enregistrer</div>
            </div>
          </div>
        </div>
      </div>}
      <div className={avoir.status === 0 ? "AvoirMask" : "AvoirMask AvoirMaskActive"}>
        <div className="AvoirBloc">
          <div className="AvoirTitleZone">
            <div className="AvoirTitle">Création d'avoir</div>
            <div className="AvoirClose" onClick={avoirToggle}>X</div>
          </div>
          <div className="AvoirForm">
            <div className="AvoirFormClient">
              <div className="AvoirFormClientTitle">Client</div>
              <div className='AvoirFormClientName'>{facture.client.name} {facture.client.surname}</div>
              <div className='AvoirFormClientAdresse'>{facture.client.adresse} {facture.client.adresseBis}</div>
              <div className='AvoirFormClientZip'>{facture.client.zip} {facture.client.city}</div>
              <div className='AvoirFormClientTel'>{facture.client.tel}</div>
              <div className='AvoirFormClientMail'>{facture.client.mail}</div>
            </div>
            <div className="AvoirFormArticles" id='AvoirFormArticles'>
              <div className="AvoirFormArticleLegend">
                <input type="checkbox" className="AvoirFormArticleCheckbox" id="checkAll" checked={avoir.allChecked} onClick={articlesCheckAll}></input>
                <div className="AvoirFormArticleName">Nom</div>
                <div className="AvoirFormArticleExemplaire">Ex.</div>
                <div className="AvoirFormArticlePriceHt">Prix HT</div>
                <div className="AvoirFormArticlePriceTtc">Prix TTC</div>
                <div className="AvoirFormArticlePriceTva">TVA</div>
                <div className="AvoirFormArticleDelete">X</div>
              </div>
              {avoir.articles.map((article) => (
                <div className={article.checked ? "AvoirFormArticle AvoirFormArticleActive" : "AvoirFormArticle"} key={article.id}>
                  <input type='checkbox' className="AvoirFormArticleCheckbox" id={article.id} value={article.checked} checked={article.checked} onChange={avoirCheckArticle}></input>
                  <div className="AvoirFormArticleName">{article.name}</div>
                  <div className="AvoirFormArticleExemplaire">{article.ex}</div>
                  <div className="AvoirFormArticlePriceHt">{parseFloat(article.prixHt).toFixed(2)}</div>
                  <div className="AvoirFormArticlePriceTtc">{parseFloat(article.prixTtc).toFixed(2)}</div>
                  <div className="AvoirFormArticlePriceTva">{parseFloat(article.tva).toFixed(2)}</div>
                  <div className="AvoirFormArticleDelete" onClick={() => avoirArticleDelete(article.id)}>X</div>
                          
                  {article.comment && article.comment.length > 0 ? <div className="AvoirFormArticleComment">{article.comment}</div>
                  : null}
                </div>
              )) }
            </div>
            <div className={avoir.toggleAvoirArticleCreate ? "FactureModifArticleLineCreator" : "FactureModifArticleLineCreator FactureModifArticleLineCreatorActive"}>
              <input type='text' className="AvoirFormArticleName" id='name' placeholder="Nom de l'article" value={avoir.newArticle.name} onChange={avoirNewArticleChange}/>
              <input type='number' className="AvoirFormArticleExemplaire" id='ex' placeholder='ex.' value={avoir.newArticle.ex} onChange={avoirNewArticleChange}/>
              <input type='text' className="AvoirFormArticlePriceHt" id='prixHt' placeholder='Prix HT' value={avoir.newArticle.prixHt} onChange={avoirNewArticleChange}/>
              <input type='text' className="AvoirFormArticlePriceTtc" id='prixTtc' placeholder='Prix TTC' value={avoir.newArticle.prixTtc} onChange={avoirNewArticleChange}/>
              <label htmlFor="tva">TVA % :</label>
              <select className="AvoirFormArticlePriceTva" placeholder='TVA' id='tva' value={avoir.newArticle.tva} onChange={avoirNewArticleChange}>
                <option value='0'>0%</option>
                <option value='5.5'>5,5%</option>
                <option value='10'>10%</option>
                <option value='12.5'>12,5%</option>
                <option value='20' selected>20%</option>
              </select>
              <input type='text' className="AvoirModifArticleComent" id='comment' placeholder='Commentaire' value={avoir.newArticle.comment} onChange={avoirNewArticleChange}/>
              <div className="FactureAvoirModifArticleAdd" onClick={avoirArticleCreate}>Ajouter</div>
            </div>
            <div className="FactureModifArticleAddLine" onClick={toggleAvoirArticleCreate}>{avoir.toggleAvoirArticleCreate ? 'Ajouter un remboursement' : 'Annuler'}</div>
          </div>
          <div className='FactureModifTotalAvoir'>Total avoir : {(avoir.total_ttc * -1).toFixed(2)} €</div>
          <div className='FactureModifValidationZone'>
            <div className='FactureModifValidation' onClick={avoirCreate}>Créer l'avoir</div>
            <div className="FactureModifAnnulation" onClick={avoirToggle}>Annuler</div>
          </div>
        </div>
      </div>
      <div className={mailComplete.status === 0 ? "mailSendMaskUnactive" : "mailSendMask"}>
        <div className='mailSendBox'>
          <div className='mailSendBoxTitle'>Envois par mail</div>
          <div className='mailSendBoxText'>
              Un email est nécessaires si vous souhaitez envoyer la facture par mail.
          </div>
          <input type='text' value={mailComplete.mail} placeholder='mail du client' onChange={mailCompleteHandleChange}/>

          {mailComplete.searchResult && (
            <div className="mailSendBoxUserMatch">
              <div className='mailSendBoxUserMatchTitle'>
                Correspondance trouvée :
              </div>
              <div className='mailSendBoxUserName'>
                {mailComplete.searchResult.nom} {mailComplete.searchResult.prenom} 
              </div>
              <div className='mailSendBoxUserMail'>
                {mailComplete.searchResult.mail}
              </div>
            </div>
          )}

          <div className='mailSendBoxButton' onClick={sendMail}>
            Envoyer
          </div>
        </div>
      </div>
      <div className={reglement.status ? "reglementMask" : "reglementMaskUnactive"}>
        <div className='reglementBloc'>
          <div className='reglementTitle'>Réglements</div>
          <div className='reglementLine'>
            <div className='reglementType'>Type</div>
            <div className='reglementAmount'>Montant</div>
            <div className='reglementDate'>Date</div>
          </div>
          {
            reglement.reglements.map((reglement) => (
              <div className='reglementLine'>
                <div className='reglementType'>{reglement.type}</div>
                <div className='reglementAmount'>{reglement.amount}</div>
                <div className='reglementDate'>{reglement.date}</div>
              </div>
            ))
          }
          {
            reglement.newReglements.map((reglement) => {
              return (
                <div className='reglementLine'>
                  <select className='reglementType' value={reglement.type} onChange={(e) => reglementChange(e, 'type', reglement.id)}>
                    <option value={0}>Carte Bleue</option>
                    <option value={1}>Chèque</option>
                    <option value={2}>Espèce</option>
                    <option value={3}>Virement bancaire</option>
                  </select>
                  <input type='number' className='reglementAmount' value={reglement.amount} onChange={(e) => reglementChange(e, 'amount', reglement.id)}></input>
                  <input type='date' className='reglementDate' value={reglement.date} onChange={(e) => reglementChange(e, 'date', reglement.id)}></input>
                  <div className='reglementDelete' onClick={() => reglementDelete(reglement.id)}>X</div>
                </div>
              )
            })
          }
          <div className="reglementTotalZone">
            <div className="reglementTotal">Total : {reglement.amount}</div>
            <div className="reglementRDue">Restant Due : {reglement.rDue}</div>
          </div>
          <div className="reglementMoreButton" onClick={addReglement}>Ajouter un réglement</div>
          <div className='reglementCloseButton' onClick={() => reglementToggle(reglement.id)}>Fermer</div>
          <div className="reglementSaveButton" onClick={reglementSave}>Enregistrer</div>
        </div>
      </div>
    </div>
  );
}

export default Facturation;