import {useEffect, useState } from 'react';
import './OnePage.css';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { ArrowRightCircle, Cake, Cake2, Check, JournalBookmarkFill, PersonArmsUp, TelephoneFill, X } from 'react-bootstrap-icons';
import { useAuth } from '../AuthProvider/AuthProvider';

import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './SubscriptionForm';
import { Elements } from '@stripe/react-stripe-js';
import Stripe from 'stripe';

function OnePage () {
    const company_name = 'Blue Stock';
    const slogan = <>Le seul <span className='bold_text'>CRM</span> dédié aux <span className='bold_text'>revendeurs</span> de piscines et de spas</>;
    const slogan_bis = 'Gérez vos stocks avec fluidité, plongez dans la simplicité';
    const navigate = useNavigate();
    
    const { currentUser } = useAuth();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    const NAME_REGEX = /^[a-zA-ZÀ-ÿ\s-]+$/;
    const BIRTH_REGEX = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
    const SIRET_REGEX = /^[0-9]{14}$/;
    const ADDRESS_REGEX = /^[0-9a-zA-ZÀ-ÿ\s,-]+$/;
    const ZIP_REGEX = /^[0-9]{5}$/;
    const CITY_REGEX = /^[a-zA-ZÀ-ÿ\s-]+$/;
    const PHONE_REGEX = /^\+?[0-9\s()-]*$/;
    const PASS_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[_]).{8,}$/;
    const MAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const validateName = (name) => NAME_REGEX.test(name);
    const validateBirth = (birth) => BIRTH_REGEX.test(birth);
    const validateSiret = (siret) => SIRET_REGEX.test(siret);
    const validateAddress = (address) => ADDRESS_REGEX.test(address);
    const validateZip = (zip) => ZIP_REGEX.test(zip);
    const validateCity = (city) => CITY_REGEX.test(city);
    const validatePhone = (phone) => PHONE_REGEX.test(phone);
    const validateMail = (mail) => MAIL_REGEX.test(mail);
    const validatePass = (pass) => PASS_REGEX.test(pass);

    const monthly_price = 82;
    const yearly_price = 735;
    const users_price = 15;
    
    const [suscribe, setSuscribe] = useState({
        status : 0,
        shuffle : 0,
        alert : '',
        alert_user_zone : '',
        alert_enterprise_zone : '',
        alert_other_enterprise_zone : '',
        step : 0,
        user : {
            code : '',
            account_type : 'revendeur',
            name : '',
            surname : '',
            birth : '',
            role : '',
            mail : '',
            phone : '',
            pass : '',
            pass_bis : '',
            sexe : ''
        },
        enterprise : {
            name : '', // Nom de l'entreprise
            siret : '', //14 caractères
            status : '', //SARL, SAS, EURL...
            adresse : '',
            adresse_suite : '',
            zip : '',
            city : '',
            phone : '',
            mail: ''
        },
        more_enterprise : [],
        payment : {
          customerId : '',
          suscribtionId : '',
          users : 0,
          stripeOptions : {
            clientSecret: '',
            customer: '' 
          },
          stripe_appearance: {},
        }
    })
    const [connec, setConnec] = useState({
        shuffle : 0,
        status : 0,
        alert : '',
        mail : '',
        pass : '',
        remember : false
    })
    const [light_menu, setLightMenu] = useState(false);
    const [forgotPassSwitch, setForgotPassSwitch] = useState(false);
    const [forgetPass, setForgetPass] = useState({
        shuffle : 0,
        status : 0,
        alert : '',
        mail : '',
        pass : '',
        pass_bis : ''
    })
    const [instantContact, setInstantContact] = useState({
        status : false,
        tel : '',
        name : '',
        surname : '',
        alert : '',
        chrono : 0,
        chrono_status : 0,
        chrono_alert : ''
    });
    const [chat, setChat] = useState({
        status : false,
        id : 0,
        talk : '',
        answers : [
            'Je voudrais des informations sur vos tarifs',
            'Je voudrais des informations sur votre logiciel',
            'Je voudrais des informations sur votre entreprise',
            'Je voudrais parler à un commercial'
        ],
        messages : [
            {
                type : 'bot',
                text : 'Bonjour, comment puis-je vous aider ?'
            }
        ],
        chatType : 'bot',
        chatIterance : 0
    })
    const [paymentStatus, setPaymentStatus] = useState(false);
    const scrollSmoothTo = (id) => {
      const element = document.getElementById(id);
      const rect = element.getBoundingClientRect();
      const offset = rect.top - 150;
    
      console.log('Scrolling to : ', offset);
    
      element.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });
    };
    const handleUserChange = async (e) => {
        setSuscribe({
            ...suscribe,
            user : {
                ...suscribe.user,
                [e.target.id] : e.target.value
            }
        })
    }
    const formuleUsersNumber = (e) => {
      setSuscribe({
        ...suscribe,
        payment : {
          ...suscribe.payment,
          users : e.target.value
        }
      })
    }
    const handleEnterpriseChange = (e) => {
        setSuscribe({
            ...suscribe,
            enterprise : {
                ...suscribe.enterprise,
                [e.target.id] : e.target.value
            }
        })

        // Vérification du SIRET
        // const siret_regex = /^[0-9]{3} ?[0-9]{3} ?[0-9]{3} ?[0-9]{5}$/;
        // const siren_regex = /^[0-9]{3} ?[0-9]{3} ?[0-9]{3}$/;

        // if((e.target.id === 'siret' && e.target.value.match(siret_regex)) || (e.target.id === 'siren' && e.target.value.match(siren_regex))) {
        //   // On lance une requete axios vers société.com pour récupérer les informations de l'entreprise

        //   const enterpriseData = axios.get('https://api.societe.com/api/v1/entreprise/' + e.target.value + '/infoslegales',
        //   {
        //     headers : {
        //       'X-Authorization': 'socapi ' + process.env.REACT_APP_SOCIETE_API_KEY
        //     }
        //   })

        //   console.log(enterpriseData);
        
        // }
          
    }
    const handleOtherEnterpriseChange = (e, index) => {
        let more_enterprise = suscribe.more_enterprise;
        more_enterprise[index][e.target.id] = e.target.value;
        setSuscribe({
            ...suscribe,
            more_enterprise : more_enterprise
        }, () => suscribe_verif())
    }
    const addEnterprise = () => {
        setSuscribe({
            ...suscribe,
            more_enterprise : [...suscribe.more_enterprise,
            {
                shuffle : 0,
                link : '',
                name : '', // Nom de l'entreprise
                siret : '', //14 caractères
                status : '', //SARL, SAS, EURL...
                adresse : '',
                adresse_suite : '',
                zip : '',
                city : '',
                phone : '',
                mail: ''
            }]
        }, () => suscribe_verif())
    }
    const validateUser = (user) => {
        if (!validateName(user.name)) {
          throw new Error('Nom invalide');
        }
        else if (!validateName(user.surname)) {
          throw new Error('Prénom invalide');
        }
        else if (!validateBirth(user.birth)) {
          throw new Error('Date de naissance invalide');
        }
        else if (!validatePass(user.pass)) {
          throw new Error('Mot de passe invalide');
        }
        else if (user.pass_bis !== user.pass) {
          throw new Error("Les mots de passe ne correspondent pas");
        } else {
            if (user.account_type === 'revendeur' || user.account_type === 'prestataire') {
                if (user.sexe === '') {
                    throw new Error('Sexe invalide');
                } else {
                    setSuscribe({
                        ...suscribe,
                        alert_user_zone : '',
                        alert : ''
                    })
                    // Validation d'un patron ou prestataire
                    // Le patron doit avoir un code entreprise
                    // Le prestataire doit avoir un code entreprise
                    // Le sexe doit être renseigné
                }
            } else if (user.account_type === 'employe' || user.account_type === 'associe') {
                console.log('Employé ou associé')
                if (user.role === '') {
                    console.log('Role invalide');
                    throw new Error('Role invalide');
                } else if (user.code === '') {
                    console.log('Code invalide');
                    throw new Error('Code entreprise invalide');
                } else {
                    console.log('Employé ou associé valide');
                    setSuscribe({
                        ...suscribe,
                        alert_user_zone : '',
                        alert : ''
                    })
                    //throw new Error('');

                    // Validation d'un employé
                    // L'employé doit avoir un code entreprise
                }
            }
        }
    }; 
    const validateEnterprise = (enterprise) => {
        if (!validateSiret(enterprise.siret)) {
          throw new Error("SIRET invalide");
        }
        if (!validateAddress(enterprise.adresse)) {
          throw new Error("Adresse invalide");
        }
        if (!validateZip(enterprise.zip)) {
          throw new Error("Code postal invalide");
        }
        if (!validateCity(enterprise.city)) {
          throw new Error("Ville invalide");
        }
        if (!validatePhone(enterprise.phone)) {
          throw new Error("Numéro de téléphone invalide");
        }
        if (enterprise.mail !== '' && !validateMail(enterprise.mail)) {
          throw new Error("Adresse mail invalide");
        }
        if(suscribe.payment.priceId === '') {
          throw new Error('Veuillez choisir une formule');
        }
    };
    const validateMoreEnterprises = (more_enterprises) => {
        more_enterprises.forEach((enterprise, index) => {
          if (!validateName(enterprise.name)) {
            throw new Error(`Nom d'entreprise ${index + 1} invalide`);
          }
          if (!validateSiret(enterprise.siret)) {
            throw new Error(`SIRET de l'entreprise ${index + 1} invalide`);
          }
          if (!validateAddress(enterprise.adresse)) {
            throw new Error(`Adresse de l'entreprise ${index + 1} invalide`);
          }
          if (enterprise.adresse_suite !== '' && !validateAddress(enterprise.adresse_suite)) {
            throw new Error(`Suite d'adresse de l'entreprise ${index + 1} invalide`);
          }
          if (!validateZip(enterprise.zip)) {
            throw new Error(`Code postal de l'entreprise ${index + 1} invalide`);
          }
          if (!validateCity(enterprise.city)) {
            throw new Error(`Ville de l'entreprise ${index + 1} invalide`);
          }
          if (!validatePhone(enterprise.phone)) {
            throw new Error(`Numéro de téléphone de l'entreprise ${index + 1} invalide`);
          }
          if (enterprise.mail !== '' && !validateMail(enterprise.mail)) {
            throw new Error(`Adresse mail de l'entreprise ${index + 1} invalide`);
          }
        });
    };    
    const suscribe_verif = async () => {
        try {
            if (suscribe.user.account_type === '' || suscribe.user.account_type === 'revendeur' || suscribe.user.account_type === 'prestataire') {
              console.log('Patron ou prestataire');
              validateUser(suscribe.user);
              validateEnterprise(suscribe.enterprise);
              if (suscribe.more_enterprise.length > 0) {
                validateMoreEnterprises(suscribe.more_enterprise);
              }
            } else {
              console.log('Employé');
              // Validation d'un employé
              // L'employé doit avoir un code entreprise

              validateUser(suscribe.user);
            }
            setSuscribe({ ...suscribe, status: 1 });
            return 1;
        } catch (error) {
            console.log(error);
            setSuscribe({ ...suscribe, status: 0, alert: error.message });
            return 0;
        }
    };
    const suscribeBlocShuffle = () => {
        setSuscribe({
            ...suscribe,
            shuffle : suscribe.shuffle === 0 ? 1 : 0
        })
    }
    const connectBlocShuffle = () => {
        if(!forgotPassSwitch) {
            console.log('connect');
            
            setConnec({
                ...connec,
                shuffle : connec.shuffle === 0 ? 1 : 0
            })
        } else {
            setForgotPassSwitch(!forgotPassSwitch);
        }
    }
    const reduceEnterpriseCase = (index) => {
        console.log('reduce');
        // Passe le shuffle de l'entreprise de 0 à 1 et inversement
        let more_enterprise = suscribe.more_enterprise;
        more_enterprise[index].shuffle = more_enterprise[index].shuffle === 0 ? 1 : 0;
        setSuscribe({
            ...suscribe,
            more_enterprise : more_enterprise
        }, () => suscribe_verif())
    }
    const deleteEnterpriseCase = (index) => { 
        let more_enterprise = suscribe.more_enterprise;
        more_enterprise.splice(index, 1);
        setSuscribe({
            ...suscribe,
            more_enterprise : more_enterprise
        }, () => suscribe_verif())
    }
    const connecHandleChange = (e) => {
        if(e.target.id === 'remember') {
            setConnec({
                ...connec,
                remember : e.target.checked
            })
        } else {
            setConnec({
                ...connec,
                [e.target.id] : e.target.value
            })
        }
    }
    const validConnec = async () => {
      if (!forgotPassSwitch) {
        let alert = '';
        let status = 0;
        const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[_]).{8,}$/;
    
        if (connec.pass === '') {
          console.log('Veuillez rentrer un mot de passe');
          alert = "Veuillez rentrer un mot de passe";
        } else if (!connec.pass.match(passRegex)) {
          console.log('Mot de passe incorrect');
          alert = "Mot de passe incorrect";
        } else if (connec.mail === '') {
          console.log('Veuillez rentrer une adresse e-mail');
          alert = "Veuillez rentrer une adresse e-mail";
        } else if (!connec.mail.match(mailRegex)) {
          console.log('Adresse e-mail invalide');
          alert = "Adresse e-mail invalide";
        } else {
          console.log('Connexion');
          alert = 'connexion en cours...';
          status = 1;
          console.log('Connexion en cours : ' + process.env.REACT_APP_SERVER_URL + "/api/connect")
          try {
            const connecData = await axios.post(
              process.env.REACT_APP_SERVER_URL + "/api/connect",
              {
                mail: connec.mail,
                pass: connec.pass,
                remember: connec.remember
              }
            );
        
            if (connecData.data) {
              if (connecData.data.success) {
                console.log(connecData.data.message);
                // Stocke le token dans le stockage local ou dans un cookie en fonction de la valeur de connec.remember
                if (!connec.remember) {
                  localStorage.setItem("session_token", connecData.data.token);
                } else {
                  document.cookie = "session_token=" + connecData.data.token + "; max-age=31536000";
                  console.log("Cookie enregistré : " + document.cookie);
                }
                console.log("Token enregistré : " + connecData.data.token);
                // Rediriger l'utilisateur vers la page /app/facture après une connexion réussie
                navigate("/app/facture", { replace: false });
              } else {
                setConnec({
                  ...connec,
                  alert: "Identifiants et/ou mot de passe incorrect",
                  status: 0
                });
              }
            } else {
              setConnec({
                ...connec,
                alert: "erreur lors de la connexion, veuillez réessayer",
                status: 0
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
        setConnec({
          ...connec,
          alert : alert,
          status : status
        });
      } else {
        console.log('Mot de passe oublié');
        try {
          const forgotPassData = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/forgotPass",
            {
                mail : connec.mail
            }
          )
          console.log(forgotPassData);
          if(forgotPassData.data) {
            if(forgotPassData.data.success) {
              console.log(forgotPassData.data.message);
              setConnec({
                  ...connec,
                  alert : 'Un mail vous a été envoyé pour réinitialiser votre mot de passe',
                  status : 1
              })
            } else {
              setConnec({
                  ...connec,
                  alert : 'Adresse mail inconnue',
                  status : 0
              })
            }
          } else {
            setConnec({
                ...connec,
                alert : 'erreur lors de la demande de mot de passe oublié, veuillez réessayer',
                status : 0
            })
          }
        } catch (error) {
          console.log(error);
          setConnec({
              ...connec,
              alert : 'erreur lors de la demande de mot de passe oublié, veuillez réessayer',
              status : 0
          })
        }
      }
    }  
    const cookieConnec = async (token) => {
        console.log('Connexion via cookie');
        try {
            const connecData = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/connectCookie",
                {
                    token : token
                }
            )
            console.log(connecData);
            if(connecData.data) {
                if(connecData.data.success) {
                    console.log(connecData.data.message);
                    localStorage.setItem('session_token', connecData.data.token);
                    console.log('Token enregistré : ' + connecData.data.token);
                    navigate('/app/facture', { replace: true });
                } else {
                    console.log(connecData.data.message);
                }
            } else {
                console.log('Erreur lors de la connexion');
            }
        } catch (error) {
            console.log(error);
        }
    }
    const forgotPass = () => {
        setForgotPassSwitch(!forgotPassSwitch);
    }
    const tokenVerify = async (token) => {
        if(forgetPass.token !== token){
            console.log('Vérification du token');
            try {
                const tokenData = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/forgotPassTokenVerify",
                    {
                        token : token
                    }
                )
                console.log(tokenData);
                if(tokenData.data) {
                    if(tokenData.data.success) {
                        console.log(tokenData.data.message);
                        // On active la fenêtre de changement de mot de passe
                        setForgetPass({
                            ...forgetPass,
                            shuffle : 1,
                            token : token
                        })
                    } else {
                        console.log(tokenData.data.message);
                        console.log('Erreur lors de la vérification du token');
                        // On redirige vers la page d'accueil
                        navigate('/', { replace: false });
                        
                    }
                } else {
                    console.log('Erreur lors de la vérification du token 2');
                    // On redirige vers l'accueil
                    navigate('/', { replace: false });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log(forgetPass.token);
            console.log('Token déjà vérifié');
        }
    }
    const fogetPassHandleChange = (e) => {
        setForgetPass({
            ...forgetPass,
            [e.target.id] : e.target.value
        })
    }
    const forgotPassShuffle = (e) => {
        setForgetPass({
            ...forgetPass,
            shuffle : forgetPass.shuffle === 0 ? 1 : 0
        })
    }
    const forgotPassChange = async (e) => {
        let alert = '';
        let status = 0;
        const passRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[_]).{8,}$/;
        if (forgetPass.pass === '') {
            console.log('Veuillez rentrer un mot de passe');
            alert = "Veuillez rentrer un mot de passe";
        } else if (!forgetPass.pass.match(passRegex)) {
            console.log('Mot de passe incorrect');
            alert = "Mot de passe incorrect";
        } else if (forgetPass.pass_bis === '') {
            console.log('Veuillez rentrer une seconde fois votre mot de passe');
            alert = "Veuillez rentrer une seconde fois votre mot de passe";
        } else if (forgetPass.pass_bis !== forgetPass.pass) {
            console.log('Les mots de passe ne correspondent pas');
            alert = "Les mots de passe ne correspondent pas";
        } else {
            console.log('Changement de mot de passe');
            alert = 'Changement de mot de passe en cours...';
            status = 1;
            try {
                const changePassData = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/forgotPassChange",
                    {
                        token : forgetPass.token,
                        pass : forgetPass.pass
                    }
                )
                console.log(changePassData);
                if(changePassData.data) {
                    if(changePassData.data.success) {
                        console.log(changePassData.data.message);
                        setForgetPass({
                            ...forgetPass,
                            alert : 'Mot de passe changé avec succès',
                            shuffle : 0,
                            token : '',
                            status : 1
                        })
                    } else {
                        setForgetPass({
                            ...forgetPass,
                            alert : 'Erreur lors du changement de mot de passe',
                            status : 0
                        })
                    }
                } else {
                    setForgetPass({
                        ...forgetPass,
                        alert : 'Erreur lors du changement de mot de passe',
                        status : 0
                    })
                }
            } catch (error) {
                console.log(error);
                setForgetPass({
                    ...forgetPass,
                    alert : 'Erreur lors du changement de mot de passe',
                    status : 0
                })
            }
        }
        setForgetPass({
            ...forgetPass,
            alert : alert,
            status : status
        });
    }
    const callBackActive = async() => {
        // IDEA : Bloquer le système de rappel hors des heures de travail
        //Demande de rappel envoyé à la base de donnée et déclenchement du chrono
        
        try {
            const callBackData = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/callBack",
                {
                    tel : instantContact.tel,
                    name : instantContact.name,
                    surname : instantContact.surname
                }
            )
            console.log(callBackData);
            if(callBackData.data) {
                if(callBackData.data.success) {
                    console.log(callBackData.data.message);

                    let chrono_time = 1800;
                    if(new Date().getHours() < 8 || new Date().getHours() > 18|| new Date().getDay() === 0 || new Date().getDay() === 6) {
                        setInstantContact({
                            ...instantContact,
                            alert : 'Le service de rappel n\'est pas disponible en dehors des heures de travail (du lundi au samedi de 8h à 18h), vous serez rappelé dès l\'ouverture du service',
                        })
                        return;
                    } else {
                      setInstantContact({
                          ...instantContact,
                          chrono : chrono_time,
                          chrono_status : 1,
                          chrono_alert : 'Vous serez rappelé avant ' + (chrono_time / 60).toFixed(0) + 'minutes et ' + (chrono_time % 60) + 'secondes'
                      })
                      const chrono = setInterval(() => {
                          chrono_time = parseFloat(chrono_time) - 1;
                          setInstantContact({
                              ...instantContact,
                              chrono : chrono_time,
                              chrono_status : 1,
                              chrono_alert : `Vous serez rappelé avant ${Math.floor(chrono_time / 60)} minutes et ` + (chrono_time % 60) + ' secondes'
                          })
                      }, 1000);
                      setTimeout(() => {
                          clearInterval(chrono);
                          setInstantContact({
                              ...instantContact,
                              chrono_status : 0,
                              chrono_alert : 'Vous avez été rappelé',
                              status : false,
                              tel : '',
                              name : '',
                              surname : ''
                          })
                      }, 60000);
                    }
                } else {
                    console.log(callBackData.data.message);
                    setInstantContact({
                        ...instantContact,
                        alert : callBackData.data.message
                    })
                }
            } else {
                console.log('Erreur lors de la demande de rappel');
                setInstantContact({
                    ...instantContact,
                    alert : 'Erreur lors de la demande de rappel'
                })
            }
        } catch (error) {
            console.log(error);
            setInstantContact({
                ...instantContact,
                alert : 'Erreur lors de la demande de rappel'
            })
        }
    }
    const chatActive = () => {
        console.log('Chat instantané');

        // Ouverture de la fenêtre de chat

        setChat({
            ...chat,
            status : true
        });
        
    }
    const chatAnswer = async(answer) => {
        // Envoie de la réponse à la base de donnée

        console.log('Réponse : ' + answer);
        try {
            let messages = chat.messages;

            messages.push({
                type : 'user',
                text : answer
            });
            console.log('Messages : ', messages);

            const chatData = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/chat",
                {
                    answer : answer,
                    messages : messages,
                    id : chat.id
                }
            )
            console.log(chatData);
            if(chatData.data) {
                if(chatData.data.success) {
                    
                    if(chatData.data.responseText !== ''){
                        messages.push({
                            type : 'bot',
                            text : chatData.data.responseText
                        });
                    }

                    console.log('chatData.data.answers : ', chatData.data.answers);

                    setChat({
                        ...chat,
                        type : chatData.data.chatType,
                        messages : messages,
                        answers : chatData.data.answers,
                        id : chatData.data.id,
                        talk : '',
                        chatType : chatData.data.chatType
                    })
                } else {
                    console.log(chatData.data.message);
                }
            } else {
                console.log('Erreur lors de la demande de chat');
            }
        } catch (error) {
            console.log(error);
        }
    }
    const chatClose = () => {
        setChat({
            ...chat,
            status : false
        })
    }
    const handleChatTalk = (e) => {
        setChat({
            ...chat,
            talk : e.target.value
        })
    }

    // IDEA : Mettre en place le chat instantané avec un WebSocket
    const chatEnter = (e) => {
        if(e.key === 'Enter') {
            chatAnswer(chat.talk);
        }
    } 
    const lightMenuActive = () => {
        setLightMenu(!light_menu);
    }
    const chatActualise = async() => {
        console.log('Actualisation du chat');

        const chatIterance = chat.chatIterance + 1;
        setChat({
            ...chat,
            chatIterance : chatIterance
        })

        // Actualisation du chat
        try {
            const chatData = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/chat",
                {
                    id : chat.id
                }
            )
            console.log(chatData);
            if(chatData.data) {
                if(chatData.data.success) {
                    console.log(chatData.lastMessage);
                    if(chatData.data.messages[chatData.data.messages.length - 1].text !== chat.messages[chat.messages.length - 1].text){
                        // Actuvalisation des messages
                        console.log('Messages : ', chatData.data.messages);
                        setChat({
                            ...chat,
                            messages : chatData.data.messages,
                            answers : chatData.data.answers,
                            chatType : chatData.data.chatType,
                            chatIterance : 0
                        });
                    } else {
                        // Pas de nouveau message
                        console.log('Pas de nouveaux messages');
                        // On attend 2500ms et on renvoie chatActualise

                        if(chatIterance < 10) {
                            setTimeout(() => {
                                chatActualise();
                            }, 2500);
                        } else {
                            console.log('Arrêt de l\'actualisation');
                        }
                    }
                } else {
                    console.log(chatData.data.message);
                }
            } else {
                console.log('Erreur lors de la demande de chat');
            }
        } catch (error) {
            console.log(error);
        }
    }
    const formuleChange = (id) => {
      setSuscribe({
        ...suscribe,
        payment : {
          ...suscribe.payment,
          stripeOptions: {
            ...suscribe.payment.stripeOptions,
            priceId: id
          }
        }
      });
    }
    const suscribeValid = async () => {
      // Inscrit les données clients dans la base de donnée sans paiement

      if(suscribe.step === 0) {
        const validation = await suscribe_verif();
        if (validation === 1) {
          console.log('Envoie des données');
          try {
            const suscribeData = await axios.post(
              process.env.REACT_APP_SERVER_URL + "/api/suscribe",
              {
                user : suscribe.user,
                enterprise : suscribe.enterprise,
                more_enterprise : suscribe.more_enterprise,
              }
            );

            if(suscribeData.data.success) {
              if (suscribe.enterprise.siret){
                // On vient de créer une entreprise
                // On demande un paiement
                console.log('Demande de paiement');

                clientSecretAsk(suscribeData.data.enterprise_id).then((obj) => {
                  console.log('Client secret : ', obj.clientSecret);
                  if (obj.clientSecret) {
                    setSuscribe({
                      ...suscribe,
                      step: 1,
                      payment: {
                        ...suscribe.payment,
                        stripeOptions: {
                          ...suscribe.payment.stripeOptions,
                          clientSecret: obj.clientSecret,
                          customer: obj.customerId
                        }
                      }
                    });
                  } else {
                    setSuscribe({
                      ...suscribe,
                      alert: 'Erreur lors de la demande de paiement'
                    });
                  }
                });
              } else {
                // On a créé un utilisateur
                // On redirige vers la page de connexion
                
                try {
                  const connecData = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/connect",
                    {
                      mail: suscribe.user.mail,
                      pass: suscribe.user.pass,
                      remember: 1
                    }
                  );
              
                  if (connecData.data) {
                    if (connecData.data.success) {
                      console.log(connecData.data.message);
                      // Stocke le token dans le stockage local ou dans un cookie en fonction de la valeur de connec.remember
                      if (!connec.remember) {
                        localStorage.setItem("session_token", connecData.data.token);
                      } else {
                        document.cookie = "session_token=" + connecData.data.token + "; max-age=31536000";
                        console.log("Cookie enregistré : " + document.cookie);
                      }
                      console.log("Token enregistré : " + connecData.data.token);
                      // Rediriger l'utilisateur vers la page /app/facture après une connexion réussie
                      navigate("/app/facture", { replace: false });
                    } else {
                      setConnec({
                        ...connec,
                        alert: "Identifiants et/ou mot de passe incorrect",
                        status: 0
                      });
                    }
                  } else {
                    setConnec({
                      ...connec,
                      alert: "erreur lors de la connexion, veuillez réessayer",
                      status: 0
                    });
                  }
                } catch (error) {
                  console.log(error);
                }


              }
            } else {
              console.log(suscribeData.data.message);
            }


          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("SUSCRIBE :: ", suscribe);
          if (suscribe.alert === '') {
            setSuscribe({
              ...suscribe,
              alert : 'Veuillez rentrer des données valide pour valider votre inscription'
            });
          }
        }
      }
    };
    const clientSecretAsk = async (enterprise_id) => {
      const response = await axios.post(process.env.REACT_APP_SERVER_URL + '/api/stripe-client-secret', {
        priceId : suscribe.payment.stripeOptions.priceId,
        users : suscribe.payment.users,
        user_mail : suscribe.user.mail,
        enterprise : {
          id : enterprise_id,
          mail : suscribe.enterprise.mail,
          phone : suscribe.enterprise.phone,
          name : suscribe.enterprise.name,
          address : {
            line1 : suscribe.enterprise.adresse,
            line2 : suscribe.enterprise.adresse_suite,
            country : 'FR',
            city : suscribe.enterprise.city,
            state : '',
            postal_code : suscribe.enterprise.zip
          }
        }
      });
      
      if (response.data.success) {
        console.log('Client secret reçu');
        return {
          clientSecret : response.data.clientSecret, 
          customerId : response.data.customerId
        };
      } else {
        console.log('Erreur lors de la demande de client secret');
        return null;
      }
    };
    const refund = async (payment_intent, clientSecret) => {
      console.log('Remboursement');
      console.log('Payment intent : ' + payment_intent);
      try {
        const refundData = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/stripePaymentRefund",
          {
            paymentIntentId : payment_intent,
            clientSecret : clientSecret
          }
        )
        
        if(refundData.data) {
          if(refundData.data.success) {
            console.log(refundData.data.message);
          } else {
            console.log(refundData.data.message);
          }
        } else {
          console.log('Erreur lors du remboursement');
        }
      } catch (error) {
        console.log(error);
      }
    };


    // TODO : Finir retrievePayment
    const retrievePayment = async () => {
      // On récupère le client secret
      const stripe = await Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
      );
      console.log('Client secret : ' + clientSecret);
      console.log('Stripe : ', stripe);
      //On attend que stripePromise state soit fulfilled
        // Retrieve the PaymentIntent
        const paymentIntent = await stripe.retrievePaymentIntent(clientSecret);
        console.log('PaymentIntent : ', paymentIntent);
    }
    const lookProspect = async(pid) => {
      // On récolte un max d'info sur l'utilisateur
      const prospect_data = {
        navigator : navigator.userAgent,
        screen : window.screen.width + 'x' + window.screen.height,
        language : navigator.language,
        latitude : navigator.geolocation ? navigator.geolocation.latitude : '',
        longitude : navigator.geolocation ? navigator.geolocation.longitude : '',
        time_zone : new Date().getTimezoneOffset()
      }

      try {
        const prospectData = await axios.post(
          process.env.REACT_APP_SERVER_URL + '/api/prospect_follow',
          {
            pid : pid,
            prospect_data : prospect_data
          }
        )
        console.log(prospectData);

        if(prospectData.data) {
          if(prospectData.data.success) {
            console.log(prospectData.data.message);
          } else {
            console.log(prospectData.data.message);
          }
        } else {
          console.log('Erreur lors de la récupération des données prospect');
        }
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(() => {
      // On vérifie que l'url contienne /success
      if (window.location.href.includes('/success') && !paymentStatus) {
        console.log('Success');
        setPaymentStatus(true);
        // On récupère l'ID de paiement à partir de la réponse du serveur
        const payment_intent = new URLSearchParams(window.location.search).get('payment_intent');
        const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
        const status = new URLSearchParams(window.location.search).get('redirect_status');
    
        if (status === 'succeeded' && payment_intent) {
          // On envoie l'ID de paiement au serveur pour le remboursement
          refund(payment_intent, clientSecret);
        }
      }
    }, [stripePromise, paymentStatus]);
    useEffect(() => {
        if(suscribe.status === 1) {
            console.log('USE EFEECT SUSCRIBE VERIF');
            suscribe_verif();
        }
    }, [suscribe.user, suscribe.enterprise])
    useEffect(() => {
      // Vérifie si le token est valide
      const token = localStorage.getItem('session_token') || document.cookie.split('; ').find(row => row.startsWith('session_token='))?.split('=')[1];
      if (token) {
        const decoded = jwtDecode(token);
        console.log('Token valide trouvé :', decoded);
        try {
          // Décode le token et vérifie s'il est valide
          if (decoded.exp * 1000 > Date.now()) {
            // Redirige vers /app/facture
            navigate('/app/facture', { replace: true });
          } else {
            console.log('Token expiré');
            // Supprime le token du local storage et du cookie
            localStorage.removeItem('session_token');
            document.cookie = 'session_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          }
        } catch (error) {
          console.error("Erreur lors du décodage du token : ", error);
          // Supprime le token du local storage et du cookie
          localStorage.removeItem('session_token');
          document.cookie = 'session_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        }
      } else {
        console.log('Aucun token trouvé');
      }
    }, [navigate]);
    
    useEffect(() => {
        if(chat.chatType === 'commercial') {
            console.log('USE EFFECT CHAT');
            chatActualise();
        }
    }, [chat.chatType])
    useEffect(() => {
        const chat_text_zone = document.querySelector('.chat_text_zone');
        if(chat_text_zone) {
            chat_text_zone.scrollTop = chat_text_zone.scrollHeight;
        }
    }, [chat]);
    useEffect(() => {
      console.log("connexion shuffle " + connec.shuffle);
      if (connec.status === 1) {
        console.log("USE EFFECT CONNEC");
        navigate("/app/facture", { replace: true });
      } else if (connec.shuffle === 0 && connec.status === 0) {
        console.log("USE EFFECT CONNEC 2");
        const token = localStorage.getItem("session_token");
        const cookie = document.cookie.split("; ").find((row) => row.startsWith("session_token="))?.split("=")[1];
        if (token) {
          navigate("/app/facture", { replace: true });
        } else if (cookie) {
          cookieConnec(cookie);
        }
      }
    }, [connec.shuffle, connec.status]);
    

    useEffect(() => {

      // Quand un prospect arrive sur le site il y a un pid en get
      const pid = new URLSearchParams(window.location.search).get('pid');
      if(pid) {
        console.log('Prospect ID : ' + pid);
        lookProspect(pid);
      }
    }, []);



    return (
      <div className='OnePage'>
        <div className='OnePage_menu'>
          <div className='logo_bloc'>
            <div className='logo'></div>
            <div className='company_name'>{company_name}</div>
          </div>
          <div className='menu_line'>
            <span onClick={() => scrollSmoothTo('presentation')} className='menu_line_button'>Présentation</span>
            <span onClick={() => scrollSmoothTo('tarifs')} className='menu_line_button'>Tarifs</span>
            <span onClick={() => scrollSmoothTo('fonctionnalite')} className='menu_line_button'>Fonctionnalités</span>
            <span onClick={() => scrollSmoothTo('contact')} className='menu_line_button'>Contact</span>
            <div className='connect' onClick={connectBlocShuffle}>connexion</div>
          </div>
          <div className={light_menu ? 'menu_burger menu_burger_active' : 'menu_burger'} onClick={lightMenuActive}>
            <div className='burger_line'></div>
            <div className='burger_line'></div>
            <div className='burger_line'></div>
          </div>
        </div>
        <div className={light_menu ? 'menu_light menu_light_active' : 'menu_light'}>
          <div className='menu_light_line'> 
            <span className='menu_light_button'>Présentation</span>
            <span className='menu_light_button'>Nos fonctionnalités</span>
            <span className='menu_light_button'>Nos tarifs</span>
            <div className='connect' onClick={connectBlocShuffle}>connexion</div>
          </div>
        </div>
        <div className='slogan_zone'>
          <div className='slogan'>
            {slogan}
            <div className='suscribe' onClick={suscribeBlocShuffle}>Je m'inscris</div>
          </div>
          <div className='slogan_img'></div>
        </div>
        <div className='whatisit_long_text' id='presentation'>
          Le milieu de la piscine compte de nombreux pôles. Entre la partie commerciale, service après-vente, la construction, les entretiens et autres prestations il est très compliqué de garder une bonne communication pour un travail efficace.<br/>
          C'est face à ce constat que chez {company_name} nous avons décidé de créer un CRM dédié aux revendeurs de piscines.<br/><br/>
          Ici vous retrouverez l'ensemble des outils nécessaires à une bonne gestion de votre entreprise sans perte de temps.
        </div>
        <div className='pole_explication'>
          <div className='pole_case'>
            <div className='pole_nbr'>
              01
            </div>
            <div className='pole_text'>
              CRM avant tout
              <p>
                Centralisation des fiches clients avec spécifications techniques pour une gestion simplifiée.
              </p>
              <div className='pole_more'>En savoir plus</div>
            </div>
          </div>
          <div className='pole_case'>
            <div className='pole_nbr'>
              02
            </div>
            <div className='pole_text'>
              Stock simplifié
              <p>
                Notre logiciel spécialisé simplifie la gestion de stock avec des articles et fournisseurs pré-enregistrés.
              </p>
              <div className='pole_more'>En savoir plus</div>
            </div>
          </div>
          <div className='pole_case'>
            <div className='pole_nbr'>
              03
            </div>
            <div className='pole_text'>
              Le hub de la piscine
              <p>
                Ventes, magasin, plannings des techniciens, prestataires, et gestion de stock, centralisés et simplifiés.
              </p>
              <div className='pole_more'>En savoir plus</div>
            </div>
          </div>
        </div>
        <div className='whatisit'>
          <div className='whatisit_text'>
            <div className='whatisit_text_14'>14 Jours</div>
            <div className='whatisit_text_free'>Pour vous jeter à l'eau</div>
            <div className='whatisit_text_description'>
              Essayez notre formule entreprise comprenant 2 utilisateurs, durant 14 jours sans débourser un seul euro et sans engagement.
            </div>
            <div className='whatisit_text_action_button'>
              Je me lance <ArrowRightCircle size={18}/>
            </div>
          </div>
          <div className='whatisit_img'></div>
        </div>
        <div className='tarifs' id='tarifs'>
          <div className='tarif_case'>
            <div className='tarif_icone'>
              <Cake size={24}/>
            </div>
            <div className='tarif_title'>Formule<br/> Mensuelle</div>
            <div className='tarif_prix'>{ (monthly_price / 1.2).toFixed(2) } € <div className='tarif_text_ht'>HT/mois</div></div>
            <div className='tarif_text'>sans engagement</div>
            <div className='tarif_text'>ou {(yearly_price / 1.2).toFixed(2)} € HT/an</div>
            <div className='tarif_description'>
              <div className='tarif_description_title'>Comprend :</div>
              <div className='tarif_description_text'>- 1 entreprise</div>
              <div className='tarif_description_text'>- 2 utilisateurs</div>
              <div className='tarif_description_text'>- ensemble des fonctionnalités</div>
            </div>
          </div>
          <div className='tarif_case'>
            <div className='tarif_icone'>
              <Cake2 size={24}/>
            </div>
            <div className='tarif_title'>Formule<br/> Annuelle</div>
            <div className='tarif_prix'>{(yearly_price / 1.2).toFixed(2) } € <div className='tarif_text_ht'>HT/an</div></div>
            <div className='tarif_text_space'></div>
            <div className='tarif_text_space'></div>
            <div className='tarif_description'>
              <div className='tarif_description_title'>Comprend :</div>
              <div className='tarif_description_text'>- 1 entreprise</div>
              <div className='tarif_description_text'>- 2 utilisateurs</div>
              <div className='tarif_description_text'>- ensemble des fonctionnalités</div>
            </div>
          </div>
          <div className='tarif_case'>
            <div className='tarif_icone'>
              <PersonArmsUp size={24}/>
            </div>
            <div className='tarif_title'>
              Utilisateur supplémentaire
            </div>
            <div className='tarif_prix'>{(users_price / 1.2).toFixed(2)} €<div className='tarif_text_ht'>HT/mois</div></div>
            <div className='tarif_text'>sans engagement</div>
            <div className='tarif_text_space'></div>
            <div className='tarif_description'>
              <div className='tarif_description_title'>Comprend :</div>
              <div className='tarif_description_text'>- 1 utilisateur supplémentaire</div>
              <div className='tarif_description_text'>- ensemble des fonctionnalités</div>
            </div>
          </div>
        </div>
        <div className='detail' id='fonctionnalite'>
          <p className='detail_text'>
            L'ensemble des fonctionnalités du logiciel, détaillées ci-dessous :
            <div className='documentationLink' onClick={() => window.open('https://likeable-flower-50f.notion.site/Documentation-1939f17597004c82b8b109ae07f06706', '_blank')}>Documentation <JournalBookmarkFill size={24}/></div>
          </p>
        </div>
        <div className='contact' id='contact'>
              <div className='contactText'>
                {!instantContact.status ? (
                <>
                  Nous contacter
                  {/* <div className='contact_button' onClick={chatActive}>Chat instantané <ChatDotsFill size={18}/></div> */}
                  <div className='contact_button' onClick={() => setInstantContact({...instantContact, status : true})}>Être rappelé <TelephoneFill size={18}/></div>
                </>) : (
                  <>
                  {instantContact.chrono_status === 0 ? (
                    <>
                      <div className='instantContact'>
                        <div className='instantContact_title'>Être rappelé</div>
                        <div className='instantContact_text'>
                          <label htmlFor='tel'>Téléphone :</label>
                          <input type='text' id='tel' placeholder='Numéro de téléphone' value={instantContact.tel} onChange={(e) => setInstantContact({...instantContact, tel : e.target.value})}/>
                          <label htmlFor='surname'>Prénom :</label>
                          <input type='text' id='surname' placeholder='Prénom' value={instantContact.surname} onChange={(e) => setInstantContact({...instantContact, surname : e.target.value})}/>
                          <label htmlFor='name'>Nom :</label>
                          <input type='text' id='name' placeholder='Nom' value={instantContact.name} onChange={(e) => setInstantContact({...instantContact, name : e.target.value})}/>
                          <div className='contact_button' onClick={callBackActive}>Envoyer</div>
                          <div className='contact_button_cancel' onClick={() => setInstantContact({...instantContact, status : false})}>Annuler</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='instantContact'>
                        <div className='instantContact_title'>Être rappelé</div>
                        <div className='instantContact_text'>
                          <div className='chrono_alert'>{instantContact.chrono_alert}</div>
                        </div>
                      </div>
                    </>
                  )}
                </>
                )}
              </div>
              <div className='contactImage'></div>
        </div>
        <div className={suscribe.shuffle === 0 ? 'suscribe_box_mask_unactive' : 'suscribe_box_mask'}>
          <div className= {suscribe.shuffle === 0 ? 'suscribe_box_unactive' : 'suscribe_box'}>
            <div className='suscribe_box_title'>INSCRIPTION</div>
            {suscribe.step === 0 ?
              <div className='suscribe_text'>
              <div className='suscribe_form'>
                <fieldset>
                  <legend>Informations utilisateur</legend>
                  <label htmlFor='account_type'>Je suis :</label>
                  <select id='account_type' placeholder='Type de compte' value={suscribe.user.account_type} onChange={handleUserChange}>
                    <option value='revendeur'>Revendeur de piscine</option>
                    <option value='associe'>Associé d'un revendeur</option>
                    <option value='employe'>Employé d'un revendeur</option>
                    <option value='prestataire'>Prestataire de service</option>
                  </select>
    
                  <label htmlFor='sexe'>Sexe :</label>
                  <select id='sexe' className={suscribe.alert_user_zone === 'sexe' ? 'inputAlert' : ''} placeholder='sexe' value={suscribe.user.sexe} onChange={handleUserChange}>
                    <option value='femme'>Femme</option>
                    <option value='homme'>Homme</option>
                    <option value='autre'>Autre</option>
                  </select>
    
                  <label htmlFor='name'>Nom :</label>
                  <input type='text' id='name' className={suscribe.alert_user_zone === 'name' ? 'inputAlert' : ''} placeholder='Nom' value={suscribe.user.name} onChange={handleUserChange}/>
    
                  <label htmlFor='surname'>Prénom :</label>
                  <input type='text' id='surname' className={suscribe.alert_user_zone === 'surname' ? 'inputAlert' : ''} placeholder='Prénom' value={suscribe.user.surname} onChange={handleUserChange}/>
    
                  <label htmlFor='birth'>Date de naissance :</label>
                  <input type='date' id='birth' className={suscribe.alert_user_zone === 'birth' ? 'inputAlert' : ''} placeholder='date de naissance' value={suscribe.user.birth} onChange={handleUserChange}/>
    
                  {suscribe.user.account_type !== '' && suscribe.user.account_type !== 'revendeur' && suscribe.user.account_type !== 'prestataire' && (
                    <>
                      <label htmlFor='role'>Mon emploi :</label>
                      <select id='role' placeholder='role' className={suscribe.alert_user_zone === 'role' ? 'inputAlert' : ''} value={suscribe.user.role} onChange={handleUserChange}>
                        <option value='directeur'>Directeur</option>
                        <option value='commerciale'>Commerciale</option>
                        <option value='technicien'>Technicien</option>
                        <option value='vendeur'>Vendeur en magasin</option>
                        <option value='comptable'>Comptable</option>
                        <option value='magasinier'>Magasinier</option>
                        <option value='stagiaire'>Stagiaire</option>
                      </select>
                      <label htmlFor='code'>Code entreprise :</label>
                      <input type='text' id='code' className={suscribe.alert_user_zone === 'code' ? 'inputAlert' : ''} placeholder='code fournis par votre employeur' value={suscribe.user.code} onChange={handleUserChange}/>
                    </>
                  )}
                </fieldset>
                <fieldset>
                  <legend>Informations de connexion</legend>
                  <label htmlFor='mail'>Adresse mail :</label>
                  <input type='email' autoComplete="email" id='mail' className={suscribe.alert_user_zone === 'mail' ? 'inputAlert' : ''} placeholder='Adresse mail' value={suscribe.user.mail} onChange={handleUserChange}/>
    
                  <label htmlFor='pass'>Mot de passe :</label>
                  <input type='password' className={suscribe.alert_user_zone === 'pass' ? 'inputAlert' : ''} id='pass' placeholder='mot de passe' value={suscribe.user.pass} onChange={handleUserChange}/>
    
                  <label htmlFor='pass_bis'>Resaisir mot de passe :</label>
                  <input type='password' id='pass_bis' className={suscribe.alert_user_zone === 'pass_bis' ? 'inputAlert' : ''} placeholder='mot de passe' value={suscribe.user.pass_bis} onChange={handleUserChange}/>
                </fieldset>
                {suscribe.user.account_type === 'revendeur' || suscribe.user.account_type === 'prestataire' ? (
                  <fieldset>
                    <legend>Informations entreprise (siège)</legend>
    
                    <label htmlFor='name'>Nom de l'entreprise :</label>
                    <input type='text' id='name' className={suscribe.alert_enterprise_zone === 'name' ? 'inputAlert' : ''} placeholder="Nom de l'entreprise" value={suscribe.enterprise.name} onChange={handleEnterpriseChange}/>
    
                    <label htmlFor='sirect'>SIRET :</label>
                    <input type='text' id='siret' className={suscribe.alert_enterprise_zone === 'siret' ? 'inputAlert' : ''} placeholder='14 caractères' value={suscribe.enterprise.siret} onChange={handleEnterpriseChange}/>
    
                    <label htmlFor='adresse'>Adresse :</label>
                    <input type='text' id='adresse' className={suscribe.alert_enterprise_zone === 'adresse' ? 'inputAlert' : ''} placeholder='Numéro de voie, rue, avenue...' value={suscribe.enterprise.adresse} onChange={handleEnterpriseChange}/>
    
                    <label htmlFor='adresse_suite'>Adresse suite :</label>
                    <input type='text' id='adresse_suite' className={suscribe.alert_enterprise_zone === 'adresse_suite' ? 'inputAlert' : ''} placeholder='Quartier, bâtiment, détails supplémentaire' value={suscribe.enterprise.adresse_suite} onChange={handleEnterpriseChange}/>
    
                    <label htmlFor='zip'>Code postal :</label>
                    <input type='text' id='zip' className={suscribe.alert_enterprise_zone === 'zip' ? 'inputAlert' : ''} placeholder='Code postal' value={suscribe.enterprise.zip} onChange={handleEnterpriseChange}/>
    
                    <label htmlFor='city'>Ville :</label>
                    <input type='text' id='city' className={suscribe.alert_enterprise_zone === 'city' ? 'inputAlert' : ''} placeholder='Ville' value={suscribe.enterprise.city} onChange={handleEnterpriseChange}/>
    
                    <label htmlFor='phone'>Téléphone de l'entreprise :</label>
                    <input type='text' id='phone' className={suscribe.alert_enterprise_zone === 'phone' ? 'inputAlert' : ''} placeholder='Téléphone' value={suscribe.enterprise.phone} onChange={handleEnterpriseChange}/>
    
                    <label htmlFor='mail'>Mail de l'entreprise :</label>
                    <input type='text' id='mail' className={suscribe.alert_enterprise_zone === 'mail' ? 'inputAlert' : ''} placeholder='Mail' value={suscribe.enterprise.mail} onChange={handleEnterpriseChange}/>
                  </fieldset>
                ) : null}
                {/* Faire revenir cette partie quand le forfait multi-entreprise seront disponible */}
                {suscribe.user.account_type === 'mag' ? (
                  <fieldset>
                    <legend>Informations entreprises</legend>
    
                    {suscribe.more_enterprise.map((enterprise, index) => (
                      <div className='enterprise_more_bloc'>
                        <div className='enterprise_more_name'>
                          <span>{enterprise.name === '' ? 'Entreprise ' + index : enterprise.name}</span>
                          <div className='enterprise_more_reduce' onClick={() => reduceEnterpriseCase(index)}>{enterprise.shuffle === 1 ? 'Réduire' : 'Voir'}</div> {/* Si il y a plus d'une entreprise */}
                          <div className='enterprise_more_delete' onClick={() => deleteEnterpriseCase(index)}>Supprimer</div>
                        </div>
                        <div className={enterprise.shuffle === 1 ? 'enterprise_more_form' : 'enterprise_more_form_close'}>
                          <label htmlFor='link'>Lien avec le siège :</label>
                          <select id='link' placeholder='lien' className={suscribe.alert_other_enterprise_zone === 'link' ? 'inputAlert' : ''} value={enterprise.link} onChange={(e) => handleOtherEnterpriseChange(e, index)}>
                            <option value='aucun'>Aucun</option>
                            <option value='filiale'>Filiale</option>
                            <option value='franchose'>franchise</option>
                          </select>
    
                          <label htmlFor='name'>Nom de l'entreprise :</label>
                          <input type='text' id='name' className={suscribe.alert_other_enterprise_zone === 'name' ? 'inputAlert' : ''} value={enterprise.name} placeholder="Nom de l'entreprise" onChange={(e) => handleOtherEnterpriseChange(e, index)}/>
    
                          <label htmlFor='siret'>SIRET :</label>
                          <input type='text' id='siret' className={suscribe.alert_other_enterprise_zone === 'siret' ? 'inputAlert' : ''} value={enterprise.siret}  onChange={(e) => handleOtherEnterpriseChange(e, index)} placeholder='14 caractères'/>
    
                          <label htmlFor='adresse'>Adresse :</label>
                          <input type='text' id='adresse' className={suscribe.alert_other_enterprise_zone === 'adresse' ? 'inputAlert' : ''} value={enterprise.adresse}  onChange={(e) => handleOtherEnterpriseChange(e, index)} placeholder='Numéro de voie, rue, avenue...'/>
    
                          <label htmlFor='adresse_suite'>Adresse suite :</label>
                          <input type='text' className={suscribe.alert_other_enterprise_zone === 'adresse_suite' ? 'inputAlert' : ''} id='adresse_suite' value={enterprise.adresse_suite}  onChange={(e) => handleOtherEnterpriseChange(e, index)} placeholder='Quartier, bâtiment, détails supplémentaire'/>
    
                          <label htmlFor='zip'>Code postal :</label>
                          <input type='text' id='zip' className={suscribe.alert_other_enterprise_zone === 'zip' ? 'inputAlert' : ''} value={enterprise.zip}  onChange={(e) => handleOtherEnterpriseChange(e, index)} placeholder='Code postal'/>
    
                          <label htmlFor='city'>Ville :</label>
                          <input type='text' id='city' className={suscribe.alert_other_enterprise_zone === 'city' ? 'inputAlert' : ''} value={enterprise.city}  onChange={(e) => handleOtherEnterpriseChange(e, index)} placeholder='Ville'/>
    
                          <label htmlFor='phone'>Téléphone de l'entreprise :</label>
                          <input type='text' id='phone' className={suscribe.alert_other_enterprise_zone === 'phone' ? 'inputAlert' : ''} value={enterprise.phone}  onChange={(e) => handleOtherEnterpriseChange(e, index)} placeholder='Téléphone'/>
    
                          <label htmlFor='mail'>Mail de l'entreprise :</label>
                          <input type='text' id='mail' className={suscribe.alert_other_enterprise_zone === 'mail' ? 'inputAlert' : ''} value={enterprise.mail}  onChange={(e) => handleOtherEnterpriseChange(e, index)} placeholder='Mail'/>
                        </div>
                      </div>
                    ))}

                    <div className='more_enterprise_button' onClick={addEnterprise}>Ajouter une autre entreprise +</div>
                  </fieldset>
                ) : null}
              </div>
              <div className={suscribe.user.account_type === 'revendeur' ? 'suscribe_formule_choice' : 'suscribe_formule_choice_unactive'}>
                <div className='suscribe_formule_choice_title'>Votre formule</div>

                <div className='suscribe_formule_choice_try_period'>
                  <div className='suscribe_formule_choice_try_period_title'>14 jours offert</div>
                  <div className='suscribe_formule_choice_try_period_text'>
                    Testez gratuitement notre logiciel pendant 14 jours sans engagement, <br/>
                    vous pourrez annuler à tout moment.
                  </div>
                </div>
                <div className={suscribe.payment.stripeOptions.priceId === 'price_1PQA7NHFmLHTPkm5q0zDDqRc' ? 'suscribe_formule_choice_case_active' : 'suscribe_formule_choice_case'}>
                  <div className='suscribe_formule_choice_case_image'></div>
                  <div className='suscribe_formule_choice_case_title'>
                    Formule mensuelle
                  </div>
                  <div className='suscribe_formule_choice_case_text'>
                    <div className='suscribe_formule_choice_case_text_price_box'>
                      <div className='suscribe_formule_choice_case_text_price'>{(monthly_price / 1.2).toFixed(2)}€</div>
                      <div className='suscribe_formule_choice_case_text_price_ht'>HT/mois</div>
                    </div>
                    <div className='suscribe_formule_choice_case_text_engagement'>sans engagement</div>
                    <div className='suscribe_formule_choice_case_text_description'>
                      - 1 entreprise <br/>
                      - 2 utilisateurs <br/>
                      - ensemble des fonctionnalités
                    </div>
                    <div className={suscribe.payment.stripeOptions.priceId === 'price_1PQA7NHFmLHTPkm5q0zDDqRc' ? 'suscribe_formule_choice_case_text_button_actif' : 'suscribe_formule_choice_case_text_button'} onClick={() => formuleChange('price_1PQA7NHFmLHTPkm5q0zDDqRc')}>{suscribe.payment.stripeOptions.priceId === 'price_1PQA7NHFmLHTPkm5q0zDDqRc' ? <Check size={24}/> : 'Choisir'}</div>
                  </div>
                </div>
                <div className={suscribe.payment.stripeOptions.priceId === 'price_1PROQUHFmLHTPkm5qRuOcepW' ? 'suscribe_formule_choice_case_active' :  'suscribe_formule_choice_case'}>
                  <div className='suscribe_formule_choice_case_image'></div>
                  <div className='suscribe_formule_choice_case_title'>
                    Formule annuelle
                  </div>
                  <div className='suscribe_formule_choice_case_text'>
                    <div className='suscribe_formule_choice_case_text_price_box'>
                      <div className='suscribe_formule_choice_case_text_price'>{(yearly_price / 1.2).toFixed(2)} €</div>
                      <div className='suscribe_formule_choice_case_text_price_ht'>HT/an</div>
                    </div>
                    <div className='suscribe_formule_choice_case_text_description'>
                      - 1 entreprise <br/>
                      - 2 utilisateurs <br/>
                      - ensemble des fonctionnalités
                    </div>
                    <div className={suscribe.payment.stripeOptions.priceId === 'price_1PROQUHFmLHTPkm5qRuOcepW' ? 'suscribe_formule_choice_case_text_button_actif' : 'suscribe_formule_choice_case_text_button'} onClick={() => formuleChange('price_1PROQUHFmLHTPkm5qRuOcepW')}>{suscribe.payment.stripeOptions.priceId === 'price_1PROQUHFmLHTPkm5qRuOcepW' ? <Check size={24}/> : 'Choisir'}</div>
                  </div>
                </div>
                <div className='suscribe_formule_choice_case'>
                  <div className='suscribe_formule_choice_case_image'></div>
                  <div className='suscribe_formule_choice_case_title'>
                    Utilisateur supplémentaire
                  </div>
                  <div className='suscribe_formule_choice_case_text'>
                    <div className='suscribe_formule_choice_case_text_price_box'>
                      <div className='suscribe_formule_choice_case_text_price'>{users_price.toFixed(2)} €</div>
                      <div className='suscribe_formule_choice_case_text_price_ht'>HT/mois</div>
                    </div>
                    <div className='suscribe_formule_choice_case_text_engagement'>sans engagement</div>
                    <div className='suscribe_formule_choice_case_text_description'>
                      - 1 utilisateur supplémentaire <br/>
                      - ensemble des fonctionnalités
                    </div>
                    <input className='suscribe_form_choice_case_number' type='number' id='more_user' placeholder="nombre d'utilisateur" value={suscribe.payment.users} onChange={formuleUsersNumber}/>
                  </div>
                </div>

                <div className='suscribe_formule_choice_total_case'>
                  <div className='suscribe_formule_choice_total_title'>Total</div>
                  <div className='suscribe_formule_choice_total_case_line'>
                    <div className='suscribe_formule_choice_total_case_title'>À régler aujourd'hui :</div>
                    <div className='suscribe_formule_choice_total_case_price'>{suscribe.payment.users * 15} €
                      <span className='suscribe_formule_choice_total_case_price_ttc'>TTC</span>
                    </div>
                  </div>
                  <div className='suscribe_formule_choice_total_case_line'>
                    <div className='suscribe_formule_choice_total_case_title'>Prévelé dans 14 jours :</div>
                    <div className='suscribe_formule_choice_total_case_price'>{suscribe.payment.stripeOptions.priceId === 'price_1PQA7NHFmLHTPkm5q0zDDqRc' ? '82' :  suscribe.payment.stripeOptions.priceId === 'price_1PROQUHFmLHTPkm5qRuOcepW' ? '735' : '0'} €
                      <span className='suscribe_formule_choice_total_case_price_ttc'>TTC/{suscribe.payment.stripeOptions.priceId === 'price_1PQA7NHFmLHTPkm5q0zDDqRc' ? 'mois' : suscribe.payment.stripeOptions.priceId === 'price_1PROQUHFmLHTPkm5qRuOcepW' ? 'an' : ''}</span>
                    </div>
                  </div>
                </div>
                  
              </div>
              <div className={suscribe.alert !== '' ? 'suscribe_alert_box' : 'suscribe_alert_box_unactive'}>
                {suscribe.alert}
              </div>
              <div className='suscribe_form_action'>
                <div className='suscribe_form_back' onClick={suscribeBlocShuffle}>ANNULER</div>
                <div className={suscribe.status === 1 ? 'suscribe_form_valid suscribe_form_valid_active' : 'suscribe_form_valid'} onClick={suscribeValid}>VALIDER</div>
              </div>
              </div> :
              <div className='suscribe_text'>
                {/* Bouton de paiement Stripe avec react-stripe-js */}
                {suscribe.payment.stripeOptions !== '' &&
                  <>
                  <Elements stripe={stripePromise} options={suscribe.payment.stripeOptions}>
                    <CheckoutForm />
                  </Elements>
                  <div className='suscribe_back' onClick={() => setSuscribe({...suscribe, step : 0})}>Retour</div>
                  </>
                }
              </div>
            }
          </div>
        </div>
        <div className={connec.shuffle === 0 ? 'connection_box_mask_unactive' : 'connection_box_mask'}>
          <div className={connec.shuffle === 0 ? 'connection_box_unactive' : 'connection_box'}>
            <div className='connection_box_title'>CONNEXION</div>
            {!forgotPassSwitch ? (
              <>
                <label htmlFor='mail'>Adresse mail :</label>
                <input id='mail' type='email' autocomplete="email" value={connec.mail} onChange={connecHandleChange}></input>
                <label htmlFor='pass'>Mot de passe :</label>
                <input id='pass' type='password' value={connec.pass} onChange={connecHandleChange}></input>
                <div className='rememberBox'>
                  <input type='checkbox' checked={connec.remember} onChange={connecHandleChange} id='remember'></input>
                  <label htmlFor='remember'>Rester connecté</label>
                </div>
              </>
            ) : (
              <>
                <label htmlFor='mail'>Adresse mail :</label>
                <input id='mail' type='mail' value={connec.mail} onChange={connecHandleChange}></input>
              </>
            )}
            <span className='forgotPass' onClick={forgotPass}>Mot de passe oublié ?</span>
            <div className={connec.alert !== '' ? 'suscribe_alert_box' : 'suscribe_alert_box_unactive'}>
              {connec.alert}
            </div>
    
            <div className='suscribe_form_action'>
              <div className='suscribe_form_back' onClick={connectBlocShuffle}>ANNULER</div>
              <div className='suscribe_form_valid' onClick={validConnec}>VALIDER</div>
            </div>
          </div>
        </div>
        <div className={forgetPass.shuffle === 1 ? 'forgotPass_box_mask' : 'forgotPass_box_mask_unactive'}>
          <div className={forgetPass.shuffle === 1 ? 'forgotNewPass_box' : 'forgotNewPass_box_unactive'}>
            <div className='forgotNewPass_box_title'>Nouveau mot de passe</div>
            <label htmlFor='pass'>Mot de passe :</label>
            <input id='pass' type='password' value={forgetPass.pass} onChange={fogetPassHandleChange}></input>
    
            <label htmlFor='pass_bis'>Resaisir mot de passe :</label>
            <input id='pass_bis' type='password' value={forgetPass.pass_bis} onChange={fogetPassHandleChange}></input>
    
            <div className='suscribe_form_action'>
              <div className='suscribe_form_back' onClick={forgotPassShuffle}>ANNULER</div>
              <div className='suscribe_form_valid' onClick={forgotPassChange}>VALIDER</div>
            </div>
          </div>
        </div>
        <div className={chat.status ? 'chat_box' : 'chat_box_unactive'}>
          <div className='chat_title'>Chat instantané
            <div className='chat_close' onClick={chatClose}>
                <X size={24}/>
            </div>        
          </div>
          <div className='chat_text'>
            <div className='chat_text_zone' id='chat_text_zone'>
              {chat.messages.map((message, index) => (
                <div key={index} className={message.type === 'bot' ? 'chat_text_zone_text_bot' : 'chat_text_zone_text_user'}>
                  <div className={message.type === 'bot' ? 'chat_text_zone_text_message_bot' : 'chat_text_zone_text_message_user'}>{message.text}</div>
                </div>
              ))}
            </div>
            {chat.answers.length === 0 ?
              <div className='chat_input'>
                <input type='text' id='chat_input' placeholder='réponse...' value={chat.talk} onChange={handleChatTalk} onKeyDown={chatEnter}></input>
              </div> : 
              <div className='chat_multiple_choice'>
                {chat.answers.map((answer, index) => (
                  <div key={index} className='chat_multiple_choice_button' onClick={() => chatAnswer(answer)}>{answer}</div>
                ))}
              </div>
            }
          </div>
        </div>
        <div className='fournisseur_zone'>
          <div className='fournisseur_title'>
            Vous êtes fournisseur ?
          </div>
          <div className='fournisseur_text'>
            On recherche aussi des fournisseurs de matériel, accessoires, produits d'entretien et autres prestations pour nos revendeurs de piscines. Si vous êtes intéressé pour être référencé dans notre CRM, n'hésitez pas à nous contacter.
          </div>
          <a href='mailto:fournisseur@bluestock.fr' className='fournisseur_contact'>
            fournisseur@bluestock.fr
          </a>
        </div>
      </div>
    );      
}

export default OnePage;