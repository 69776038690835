import { Fragment, useEffect, useState } from 'react';
import './Technic.css';
import axios from 'axios';
import { ArrowDownShort, ArrowUpShort, Chat, CheckSquare, People, Person, PersonArmsUp, PersonFillCheck, PersonFillExclamation, PersonFillGear, PersonFillSlash, PersonFillX, PersonWalking, PhoneFill, Telephone, TelephoneX } from 'react-bootstrap-icons';
import e from 'cors';

function Technic(req){
    const [period, setPeriod] = useState({
        select_day : new Date().toDateString(),
        taskCreaToggle : 0
    })
    const [users, setUsers] = useState([
        {
            id : 1,
            name : 'Giovanni',
            surname : 'Romain',
            status : 'disponible',
            contact : '0612345678',
            role : 'gerant',
            tasks : [
                {
                    id : 0,
                    crea_date : '2024-01-15',
                    status : 0,
                    toggle : 0,
                    name : 'Ma tache à faire',
                    detail : 'Détails lié à la tache',
                    client : {
                        id : 1,
                        name : 'Pierre',
                        surname : 'Dupont',
                        adress : 'adresse du client',
                        adressBis : 'suite adresse du client',
                        zip : '20240',
                        city: 'Ventiseri',
                        phone : '0612345678',
                        mail : 'clientmail@mail.com'
                    }
                }
            ]
        }
    ])
    const [newTask, setNewTask] = useState({
        name : '',
        content : '',
        tech : 1,
        client : '',
        client_id : 0,
        clientSearch : [],
        techList : [],
        isSearchActive : 0,
        task_date : '',
        alert : ''
    })
    const [taskModif, setTaskModif] = useState({
        id : 0,
        name : '',
        content : '',
        tech : 1,
        client : '',
        client_id : 0,
        clientSearch : [],
        techList : [],
        isSearchActive : 0,
        isSaved : 1,
        task_date : '',
        alert : ''
    })
    const [tasks, setTasks] = useState([]);
    const colors = [
    '#FF5733', '#33FF57', '#5733FF', '#FF3366', '#66FF33',
    '#3366FF', '#FF9933', '#33FF99', '#9933FF', '#FF33CC',
    '#CCFF33', '#33CCFF', '#FF6633', '#33FF66', '#6633FF',
    '#FF33FF', '#FFCC33', '#33FFCC', '#CC33FF', '#FF3366',
    '#66FF33', '#3366FF', '#FF9933', '#33FF99', '#9933FF',
    '#FF33CC', '#CCFF33', '#33CCFF', '#FF6633', '#33FF66',
    '#6633FF', '#FF33FF', '#FFCC33', '#33FFCC', '#CC33FF',
    '#FF3366', '#66FF33', '#3366FF', '#FF9933', '#33FF99',
    '#9933FF', '#FF33CC', '#CCFF33', '#33CCFF', '#FF6633',
    '#33FF66', '#6633FF', '#FF33FF', '#FFCC33', '#33FFCC'
    ]
    const dateOption = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
    const [casesTable, setCasesTable] = useState([])
    const [today, setToday] = useState(new Date());
    const monthList = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const [month, setMonth] = useState(today.getMonth());
    const [monthName, setMonthName] = useState(monthList[today.getMonth()]);
    const [lastMonth, setLastMonth] = useState(month !== 0 ? month - 1 : 11);
    const [year, setYear] = useState(today.getFullYear());
    const [dayName, setDayName] = useState(today.getDay());
    const [dayNumber, setDayNumber] = useState(today.getDate());
    
    const statusList = [
        {value:'Non connecté', text : <><PersonFillX className='userStatusIcon' size={16}/> Non connecté</>, color : '#151515'},
        {value:'Disponible', text : <><PersonFillCheck className='userStatusIcon' size={16}/> Disponible</>, color : '#63bf8f'},
        {value:'Occupé', text : <><PersonFillExclamation className='userStatusIcon' size={16}/> Occupé</>, color : '#ffc72d'},
        {value:'En pause', text : <><Person className='userStatusIcon' size={16}/> En pause</>, color : '#ffc72d'},
        {value:'En déplacement', text : <><PersonWalking className='userStatusIcon' size={16}/> En déplacement</>, color : '#ffc72d'},
        {value:'En congé', text : <><PersonArmsUp className='userStatusIcon' size={16}/> En congé</>, color : '#ff8746'},
        {value:'En réunion', text : <><People className='userStatusIcon' size={16}/> En réunion</>, color : '#ff8746'},
        {value:'En formation', text : <><PersonFillGear className='userStatusIcon' size={16}/> En formation</>, color : '#ff8746'},
        {value:'Indisponible', text : <><PersonFillSlash className='userStatusIcon' size={16}/> Indisponible</>, color  : '#151515'}
    ]
    const [userStatus, setUserStatus] = useState({
        active : 0,
        status : 'Non connecté',
        statusKey : 0
    });

    const ActiveDay = (e) => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)){
            setTaskModif({
            ...taskModif,
            id: 0,
            name: '',
            content: '',
            tech: 1,
            client: '',
            client_id: 0,
            clientSearch: [],
            techList: [],
            isSearchActive: 0,
            isSaved: 1,
            task_date: '',
            alert: ''
            });
        
            const dateString = e.target.id;
            const regex = /\w+ (\d{1,2}) ([a-zàâäéèêëîïôöùûüç]+) (\d{4})/iu; // Expression régulière mise à jour pour correspondre au nom du mois
            const matches = dateString.match(regex);
        
            if (!matches) {
            console.error("Format de date invalide :", dateString);
            return;
            }
        
            const day = matches[1];
            const month = getMonthIndex(matches[2]); // Fonction pour obtenir l'index du mois
            const year = matches[3];
        
            const selectedDate = new Date(year, month, day);
        
            setPeriod({
            ...period,
            select_day: selectedDate.toDateString() // Format selon la date locale
            });
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    };
    const getMonthIndex = (monthName) => {
        if(req.tryAuthorization(26)){    
            const monthNames = [
                "janvier", "février", "mars", "avril", "mai", "juin",
                "juillet", "août", "septembre", "octobre", "novembre", "décembre"
            ];
            
            return monthNames.indexOf(monthName.toLowerCase());
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    };
    const CreateTable = function(caseDay){
        if (req.tryAuthorization(26) && req.tryAuthorization(27)){
            //let dateDay =  new Date( caseYear + '-' + (caseMonth + 1) + '-' + caseDay);
            let thisDay =  new Date(period.select_day);
            let className = 'TechnicCalendarDay';
            let tasksNumber = 0;
            let tasksDone = 0;
            caseDay = new Date(caseDay);
            
            users.map((user) => {
                if(user.id === req.user.id) {
                    user.tasks.map((task) => {
                        if(new Date(task.crea_date).toLocaleDateString('fr-FR', dateOption) === new Date(caseDay).toLocaleDateString('fr-FR', dateOption)){
                            tasksNumber++;
                            if(task.real_date) {
                                tasksDone += 1;
                            }
                        } 
                    })
                }
            })
            let casesTable = [];
            
            for(let i = 1; i <= 42; i++){
                if(caseDay.getMonth() < month){
                    // mois précédent
                    className = 'TechnicCalendarDay TechnicCalendarOut';
                } else if(caseDay.getMonth() == month) {
                    // mois en cours

                    className = new Date(caseDay).toLocaleDateString('fr-FR', dateOption) === thisDay.toLocaleDateString('fr-FR', dateOption) ? 'TechnicCalendarDay TechnicCalendarToday' : 'TechnicCalendarDay';
                } else if (caseDay.getMonth() > month) {
                    // mois suivant
                    className = 'TechnicCalendarDay TechnicCalendarOut';
                }

                let tasksNumber = 0;
                let tasksDone = 0;

                users.map((user) => {
                    user.tasks.map((task) => {
                        if(new Date(task.crea_date).toLocaleDateString('fr-FR', dateOption) === new Date(caseDay).toLocaleDateString('fr-FR', dateOption)){
                            tasksNumber++;
                            if(task.real_date) {
                                tasksDone += 1;
                            }
                        } 
                    })
                });
                casesTable.push(
                    <div className={className} id={caseDay.toLocaleDateString('fr-FR', dateOption)} key={caseDay.toLocaleDateString('fr-FR', dateOption)} onClick={ActiveDay}>
                        {caseDay.getDate()}
                        <div className={tasksNumber > 0 ? 'TechnicCalendarDayShow TechnicCalendarDayShowActive' : 'TechnicCalendarDayShow'}><CheckSquare className='TechnicCalendarDayShowIcon' size={12}></CheckSquare>  {tasksDone}/{tasksNumber}</div>
                    </div>
                )
                caseDay.setDate(caseDay.getDate() + 1);
            }
            return casesTable;
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const taskToggle = (e, id) => {
        if(req.tryAuthorization(26) && req.tryAuthorization(27)) {
            setUsers((prevUsers) => {
                return prevUsers.map((user) => {
                    // Recherche de la tâche dans les tâches de l'utilisateur
                    const updatedTasks = user.tasks.map((task) => {
                    if (task.id === id) {
                        // Inversion de la valeur de toggle
                        return { ...task, toggle: task.toggle === 0 ? 1 : 0 };
                    }
                    return task;
                    });
            
                    // Mise à jour des tâches de l'utilisateur
                    return { ...user, tasks: updatedTasks || []};
                });
            });
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const taskCreaToggle = () => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)){
            setPeriod({
                ...period,
                taskCreaToggle : period.taskCreaToggle === 0 ? 1 : 0,
            })

            // définir taskDate avec l'heure à 9h

            let taskDate = new Date(period.select_day);
            taskDate.setHours(9);
            setNewTask({
                name : '',
                content : '',
                tech : 1,
                client : '',
                client_id : 0,
                clientSearch : [],
                techList : [],
                isSearchActive : 0,
                task_date : taskDate.toJSON().slice(0, 16),
                alert : ''
            })
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const taskHandleChange = async (e) => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)) {
            if(e.target.id === 'tech'){
                console.log(e.target.value);
            }
            setNewTask({
                ...newTask,
                [e.target.id] : e.target.value
            })
            if(e.target.id === 'client'){
                try {
                    const myClient = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/clientSearch",
                    {
                        enterprise_id: req.user.enterprise.id,
                        client_search : e.target.value
                    }
                    );
                    if (myClient.data) {
                        setNewTask({
                            ...newTask,
                            client : e.target.value,
                            clientSearch : myClient.data.rows
                        })
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const taskHandleModif = async (e) => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)) {
            setTaskModif({
                ...taskModif,
                [e.target.id] : e.target.value,
                isSaved : 0
            })
            if(e.target.id === 'client'){
                try {
                    const myClient = await axios.post(
                        process.env.REACT_APP_SERVER_URL + "/api/clientSearch",
                        {
                            enterprise_id: req.user.enterprise.id,
                            client_search : e.target.value
                        }
                    );
                    if (myClient.data) {
                        setTaskModif({
                            ...taskModif,
                            client : e.target.value,
                            clientSearch : myClient.data.rows
                        })
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const activeClientSearch = () => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)) {
            setNewTask({
                ...newTask,
                isSearchActive : newTask.isSearchActive === 0 ? 1 : 0
            })
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const activeModifClientSearch = (user_key, task_key) => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)) {
            const newUsers = users;
            newUsers[user_key].tasks[task_key].isSearchActive = newUsers[user_key].tasks[task_key].isSearchActive === 0 ? 1 : 0;
            setUsers(newUsers);
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const loadTasks = async () => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)) {
            try {
                const myTask = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/loadTasks",
                    {
                        enterprise_id : req.user.enterprise.id,
                        period_month : month,
                        period_year : year
                    }
                );
                if (myTask.data) {
                    console.log(myTask.data.message);
                    console.log(myTask.data.rows)
                    
                    if(myTask.data.rows[0] && myTask.data.rows[0].id){
                        console.log(myTask.data.rows)

                        let usersTasks = myTask.data.rows;

                        usersTasks.map((user) => {
                            if(user.tasks){
                                user.tasks.map((task) => {
                                    task.toggle = 0;
                                    task.modif = 0;
                                    task.isSearchActive = 0;
                                })
                            } else {
                                user.tasks = [];
                            }
                        })

                        setUsers(usersTasks);
                    } else {
                        setUsers([]);
                    }
                    return 0;  
                } else {
                    return 0;
                }
            } catch (error) {
                console.log(error);
                return 0;
            }
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const newTaskValid = async () => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)) {
            if(newTask.tech && newTask.client_id !== 0 && newTask.name && newTask.task_date){
                try {
                    const myTask = await axios.post(
                        process.env.REACT_APP_SERVER_URL + "/api/newTask",
                        {
                            enterprise_id : req.user.enterprise.id,
                            tech : newTask.tech,
                            client : newTask.client_id,
                            name : newTask.name,
                            content : newTask.content,
                            task_date : newTask.task_date
                        }
                    );
                    if (myTask.data) {
                        taskCreaToggle();
                        loadTasks();
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                setNewTask({
                    ...newTask,
                    alert : "Veuillez compléter l'ensemble des champs avant de valider"
                })
                req.popToast("Veuillez compléter l'ensemble des champs avant de valider", 1);
            }
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const editTask = (user_key, task_key) => {
        if(req.tryAuthorization(26) && req.tryAuthorization(27)) {
            if(!users[user_key].tasks[task_key].modif || users[user_key].tasks[task_key].modif === 0){
                setTaskModif({
                    ...taskModif,
                    id : users[user_key].tasks[task_key].id,
                    name : users[user_key].tasks[task_key].name,
                    content : users[user_key].tasks[task_key].detail,
                    tech : users[user_key].id,
                    client : users[user_key].tasks[task_key].client.surname + ' ' + users[user_key].tasks[task_key].client.name,
                    client_id : users[user_key].tasks[task_key].client.id,
                    clientSearch : [],
                    techList : [],
                    isSearchActive : 0,
                    task_date : users[user_key].tasks[task_key].crea_date,
                    alert : ''
                });
                const newUsers = users;
                newUsers[user_key].tasks[task_key].isSearchActive = 0;
                newUsers[user_key].tasks[task_key].modif = 1;
                setUsers(newUsers)
            } else {
                setTaskModif({
                    id : 0,
                    name : '',
                    content : '',
                    tech : 1,
                    client : '',
                    client_id : 0,
                    clientSearch : [],
                    techList : [],
                    isSearchActive : 0,
                    task_date : '',
                    alert : ''
                })
                const newUsers = users;
                newUsers[user_key].tasks[task_key].isSearchActive = 0;
                newUsers[user_key].tasks[task_key].modif = 0;
                setUsers(newUsers)
            }
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const validModifTask = async (user_key, task_key) => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)) {
            if(taskModif.tech && taskModif.client_id !== 0 && taskModif.name && taskModif.task_date){
                try {
                    const myTask = await axios.post(
                        process.env.REACT_APP_SERVER_URL + "/api/validModifTask",
                        {
                            enterprise_id : req.user.enterprise.id,
                            tech : taskModif.tech,
                            client : taskModif.client_id,
                            name : taskModif.name,
                            content : taskModif.content,
                            task_date : taskModif.task_date,
                            task_id : taskModif.id
                        }
                    );
                    if (myTask.data) {
                        editTask(user_key, task_key);
                        loadTasks();
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                setNewTask({
                    ...newTask,
                    alert : "Veuillez compléter l'ensemble des champs avant de valider"
                })
                req.popToast("Veuillez compléter l'ensemble des champs avant de valider", 1);
            }
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const newTasckClientChoose = (idClient, index) => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)) {
            setNewTask({
                ...newTask,
                client : newTask.clientSearch[index].surname + ' ' + newTask.clientSearch[index].name,
                client_id : idClient,
                clientSearch : []
            })
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const modifTasckClientChoose = (idClient, index, user_key, task_key) => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)) {
            setTaskModif({
                ...taskModif,
                client : taskModif.clientSearch[index].surname + ' ' + taskModif.clientSearch[index].name,
                client_id : idClient,
                clientSearch : []
            })
            activeModifClientSearch(user_key, task_key)
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const taskHandleComment = async (e, user_key, task_key) => {
        // onChange - Fonction permettant d'enregistrer un commentaire avant de valider une tâche
        const realDate = new Date();

        const myTask = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/taskUpdate",
            {
                task_id : taskModif.id,
                task_data : 'comment',
                task_content : e.target.value
            }
        );
        if(myTask.data) {
            let new_users = users;
            new_users[user_key].tasks[task_key].real_date = realDate;
            setUsers(new_users);
        }
    }
    const validTask = async(user_key, task_key) => {
        try {
            const realDate = new Date();
            const myTask = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/taskUpdate",
                {
                    task_id : users[user_key].tasks[task_key].id,
                    task_data : 'real_date',
                    task_content : realDate
                }
            );
            if(myTask.data) {
                let new_users = users;
                new_users[user_key].tasks[task_key].real_date = realDate;
                setUsers(new_users);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const switchMonth = (sens) => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)) {
            let actYear, actMonth, actToday;
            if(sens === 0) {
                actYear = month === 0 ? year - 1 : year;
                actMonth = month === 0 ? 11 : month - 1;
                setYear(actYear);
                setMonth(actMonth);
            } else if (sens === 1) {
                actYear = month === 11 ? year + 1 : year;
                actMonth = month === 11 ? 0 : month + 1;
                setYear(actYear);
                setMonth(actMonth);
            }
            actToday = new Date(actYear + '/' + (actMonth + 1) + '/01'); 
            setToday(actToday);
            setMonthName(monthList[actToday.getMonth()]);
            setLastMonth(month !== 0 ? actMonth - 1 : 11);
            setDayName(new Date(actYear + '/' + (actMonth + 1) + '/01').getDay());
            setDayNumber(new Date(actYear + '/' + (actMonth + 1) + '/01').getDate());

            setTaskModif({
                ...taskModif,
                id : 0,
                name : '',
                content : '',
                tech : 1,
                client : '',
                client_id : 0,
                clientSearch : [],
                techList : [],
                isSearchActive : 0,
                isSaved : 1,
                task_date : '',
                alert : ''
            });
            setPeriod({
                ...period,
                select_day : actToday
            }, loadTasks());
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const activeStatusValues = (e) => {
        if(req.tryAuthorization(26) && req.tryAuthorization(27)) {
            if(e.target.id === 'techUserStatus'){
                setUserStatus({
                    ...userStatus,
                    active : userStatus.active === 0 ? 1 : 0
                })
            } else if (e.target.id === 'techUserStatusValue' ) {
                return 0;
            } 
            else {
                setUserStatus({
                    ...userStatus,
                    active : 0
                })
            }
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    }
    const statusChange = async (status, statusKey) => {
        if (req.tryAuthorization(26) && req.tryAuthorization(27)) {
            setUserStatus(prevUserStatus => ({
                ...prevUserStatus,
                status: status,
                active: 0,
                statusKey: statusKey
            }));
            try {
                const myStatus = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/userUpdate",
                    {
                        user_id : req.user.id,
                        user_status : statusKey,
                        enterprise_id : req.user.enterprise.id
                    }
                );
                if(myStatus.data) {
                    return myStatus.data; // retourner la réponse de l'API
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            req.popToast("Vous n'avez pas les droits pour accéder à cette page", 1);
        }
    };

    // REVENDEUR & TECHNICIENS
    
    //IDEA : Affichage des photos techniciens à côté des noms
    //IDEA : Listing des magasins (revendeurs) pouvant proposer des contrat
    
    //IDEA : Liste de tache pré-configuré en fonction des factures

    // PRESTATAIRES

    //IDEA : Suivis des offres de prestation
    //IDEA : Affichage des revendeurs avec qui travailler
    //IDEA : Facturation pour les revendeurs facilité

    useEffect(() => {
        loadTasks();
        setUserStatus({
            ...userStatus,
            status: statusList[req.user.status].text,
            statusKey: req.user.status
        });
    }, [req, period])
    useEffect(() => {
        const firstDay = new Date(year, month, 1);
        
        let caseDay = firstDay.setDate(firstDay.getDate() - (firstDay.getDay() + 6) % 7);

        //let caseDay = firstDay === 1 ? 1 : lastMonthDays - firstDay + 2;
        let caseMonth = caseDay !== 1 ? month - 1 : month;
        let caseYear = caseDay !== 1 && month === 1 ? year - 1 : year;

        setCasesTable(CreateTable(caseDay));
    }, [users])
    
    return(
        <div className='Technic' onClick={activeStatusValues}>
            <div className='TechnicCalendar'>
                <div className='TechnicCalendarDates'>
                    <div className='TechnicCalendarMonth'>
                        <div className='TechnicCalendarMonthArrow' onClick={() => switchMonth(0)}> &#8249; </div>
                        <div className='TechnicCalendarMonthName'>{ monthName } {year}</div>
                        <div className='TechnicCalendarMonthArrow' onClick={() => switchMonth(1)}> &#8250; </div>
                    </div>
                    <div className='TechnicCalendarDayLegend'>LUNDI</div>
                    <div className='TechnicCalendarDayLegend'>MARDI</div>
                    <div className='TechnicCalendarDayLegend'>MERCREDI</div>
                    <div className='TechnicCalendarDayLegend'>JEUDI</div>
                    <div className='TechnicCalendarDayLegend'>VENDREDI</div>
                    <div className='TechnicCalendarDayLegend'>SAMEDI</div>
                    <div className='TechnicCalendarDayLegend'>DIMANCHE</div>
                    {casesTable}
                </div>
                <div className='TechnicCalendarTaskList'></div>
            </div>
            <div className='TechnicUsers'>
                <div className='TechnicUsersDate'>{new Date(period.select_day).toLocaleDateString('fr-FR', dateOption)}</div>
                <div className='TechnicUserStatusParam'>
                    <div id='techUserStatus' className='techUserStatus' style={{backgroundColor : statusList[userStatus.statusKey].color}}>{statusList[userStatus.statusKey].text}</div>
                    <div className={userStatus.active === 0 ? 'statusValuesInactive' : 'statusValues'}>
                        {statusList.map((status, index) => (
                            <div key={index} id='techUserStatusValue' className='techUserStatusValue' onClick={() => statusChange(status.value, index)}>{status.text}</div>
                        ))}
                    </div>
                </div>
                <div className='TechnicUsersZone'>
                    {users.map((user, key) => (
                        <div className='TechPresCard' key={'USR_' + key}>
                            <div className='TechPresCardTop'>
                                <div className='TechPresCardName'>{user.prenom} {user.nom}</div>
                                <div className='TechPresCardStatus'>
                                    {user.status && statusList[user.status] ? statusList[user.status].text : <><PersonFillCheck className='userStatusIcon' size={16}/> Disponible</>}
                                </div>
                            </div>
                            <div className='TechPressCardContent'>
                                <div className='TechPresCardContentPhone'>{user.phone ? <>{user.phone} <Telephone></Telephone></> :  <>aucun numéro <TelephoneX></TelephoneX></>}</div>
                                {/* <div className='TechPresCardContentMessage'>Message <Chat></Chat></div> */}
                                {/* IDEA : Système de chat entre utilisateur */}
                            </div>
                            <div className='TechPresCardTaskZone'>
                                {user.tasks.map((task, t_key) => (new Date(task.crea_date).toLocaleDateString('fr-FR', dateOption) === new Date(period.select_day).toLocaleDateString('fr-FR', dateOption) ? 
                                    (
                                        <div className={task.real_date ? 'TechPresCardTask TechPresCardTaskReal' : 'TechPresCardTask'}  key={'TSK_' + key}>
                                            <div className='TechPresCardTaskTextZone'>
                                                <span className='TechPresCardTaskTextTitle'>{task.name} : </span>
                                                {task.content}

                                                {task.client && (<div className='TechPresCardTaskClientButton'>
                                                    Client : {task.client.name} {task.client.surname}<br/>
                                                </div>)}
                                            </div>
                                            {task.client && (<div className={task.toggle === 0 ? 'TechPresCardTaskClientZone TechPresCardTaskClientZoneUnactive' : 'TechPresCardTaskClientZone'}>
                                                {task.client.address}<br/>
                                                {task.client.address_bis}<br/>
                                                {task.client.zip} {task.client.city}<br/><br/>
                                                <span className="TechPresCardTaskClientZonePhone">{task.client.phone}</span>
                                            </div>)}
                                            <div className='TechPresCardTaskDevellopeButton' onClick={(e) => taskToggle(e, task.id)}>{task.toggle === 0 ? <>Dévelloper <ArrowDownShort size={16}></ArrowDownShort></> : <>Réduire <ArrowUpShort size={16}></ArrowUpShort></>}</div>

                                            <div className='TechPresCardTaskValidZone'>
                                                {task.real_date ? ('') : (
                                                <>
                                                    <div className={task.modif && task.modif === 1 ? 'TechPresCardTaskModifCase' : 'TechPresCardTaskModifCaseUnactive'}>
                                                        <span className='TechPresCardTaskModifButton'>MODIFICATION</span>
                                                        <div className='TechPresCardTaskModifForm'>
                                                            <label htmlFor='name'>Nom de la tache</label>
                                                            <input type='text' id='name' value={taskModif.name} onChange={(e) => taskHandleModif(e)}/><br/>
                                                            <textarea id='content' placeholder='contenue de la tâche' value={taskModif.content} onChange={(e) => taskHandleModif(e)}>
                                                                
                                                            </textarea><br/>
                                                            <input type="datetime-local" id="task_date" value={taskModif.task_date} onChange={(e) => taskHandleModif(e)} min={new Date().toJSON().slice(0, 10)}/>
                                                            <label htmlFor='tech'>Technicien</label>
                                                            <select value={taskModif.tech} id='tech' onChange={(e) => taskHandleModif(e)}>
                                                                {users.map((user) => (
                                                                    <option value={user.id}>{user.surname} {user.name}</option>
                                                                ))}
                                                            </select><br/>
                                                            <label htmlFor='client'>Client</label>
                                                            <input type='text' id='client' value={taskModif.client} onSelect={() => activeModifClientSearch(key, t_key)} onAbort={() => activeModifClientSearch(key, t_key)} onChange={(e) => taskHandleModif(e)}/>

                                                            {task.isSearchActive && taskModif.clientSearch.length > 0 && (
                                                                <ul>
                                                                    {taskModif.clientSearch.map((result, index) => (
                                                                        <li key={index} onClick={() => modifTasckClientChoose(result.id, index, key, t_key)}>{result.surname} {result.name}</li>
                                                                    ))}
                                                                </ul>
                                                            )}

                                                            <div className='TechnicCreateTaskValid' onClick={() => validModifTask(key, t_key)}>Valider</div>
                                                        </div>
                                                    </div>
                                                    <div className='TechPresCardTaskActionZone'>
                                                        <div className='CommentZone'>
                                                            <input type='text' id='comment' placeholder='mettre un commentaire' onChange={(e) => taskHandleComment(e, key, t_key)}/>
                                                        </div>
                                                        <div className={task.modif && task.modif === 1 ? 'TechPresCardTaskValidUnactive' : 'TechPresCardTaskValid'} onClick={() => validTask(key, t_key)}>
                                                            Valider <CheckSquare className='TechPresCardTaskValidIcon' size={14}></CheckSquare>
                                                        </div>
                                                        <div className={task.modif && task.modif === 1 ? 'TechPresCardTaskModifClose' : 'TechPresCardTaskModif'} onClick={() => editTask(key, t_key)}>
                                                            {task.modif && task.modif === 1 ? 'Annuler' : 'Modifier'}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>) : ('')
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='TechnicCreateTask'>
                    <div className='TechnicCreateTaskButton' onClick={taskCreaToggle}>{period.taskCreaToggle === 1 ? 'Fermer' : 'Nouvelle tâche'}</div>
                    <div className={period.taskCreaToggle === 1 ? 'TechnicCreateTaskForm' : 'TechnicCreateTaskFormInactive'}>
                        <label htmlFor='name'>Nom de la tache</label>
                        <input type='text' id='name' value={newTask.name} onChange={taskHandleChange}/><br/>
                        <textarea id='content' placeholder='contenue de la tâche' onChange={taskHandleChange}>

                        </textarea><br/>
                        <input type="datetime-local" id="task_date" value={newTask.task_date} onChange={taskHandleChange} min={new Date().toJSON().slice(0, 10)}/>
                        <label htmlFor='tech'>Technicien</label>
                        <select value={newTask.tech} id='tech' onChange={taskHandleChange}>
                            {users.map((user) => (
                                <option value={user.id}>{user.surname} {user.name}</option>
                            ))}
                        </select><br/>
                        <label htmlFor='client'>Client</label>
                        <input type='text' id='client' value={newTask.client} onSelect={activeClientSearch} onAbort={activeClientSearch} onChange={taskHandleChange}/>
                        {newTask.isSearchActive && newTask.clientSearch.length > 0 && (
                            <ul>
                                {newTask.clientSearch.map((result, index) => (
                                    <li key={index} onClick={() => newTasckClientChoose(result.id, index)}>{result.surname} {result.name}</li>
                                ))}
                            </ul>
                        )}

                        <div className='TechnicCreateTaskValid' onClick={newTaskValid}>Modifier</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Technic;