import { useEffect, useState } from 'react';
import './Caisse.css'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Envelope, Printer, XSquareFill } from 'react-bootstrap-icons';

function Caisse(req){
    //IDEA : Fonction d'opération de caisse (ajoute ou retire une somme à l'entry)
    
    //IDEA : Infos clients (impayés, historique, etc.)
    //IDEA : Bouton envois de la facture par mail
    //IDEA : Bouton impression de la facture
    //IDEA : Bouton Validation de facture qui change couleur et affiche quand la facture est validée

    const [caisse, setCaisse] = useState({
        entry : 0.00,
        totalHT : 0,
        totalTTC : 0,
        totaleRest : 0,
        articles : [],
        reglements : [],
        client : {
            id : 0,
            name : '',
            surname : '',
            adresse : '',
            adresseBis : '',
            zip : '',
            city : '',
            phone : '',
            mail : '',
            bassinSize : 0
        },
        clientSearch : '',
        articlesSearch : '',
        clientsList : [],
        articlesList : [],
        customer_creation : false,
        customer : {
            id : 0,
            name : '',
            surname : '',
            adresse : '',
            adresseBis : '',
            zip : '',
            city : '',
            tel : '',
            mail : ''
        },
        quantityCalculation : false
    })
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [sousCategories, setSousCategories] = useState([]);

    const [inventaire, setInventaire] = useState({
        status : 0,
        categorie : '',
        sousCategorie : '',
        offset : 0,
        limit : 20
    });
    const [search, setSearch] = useState({
        value : ''
    });

    const [quantityBox, setQuantityBox] = useState(false);
    const addNumericEntry = (entry) => {
        if(req.tryAuthorization(7)){
            setCaisse(caisse => {
                let currentEntryAsString = caisse.entry.toFixed(2).replace('.', '');
        
                // Gère les cas spéciaux pour '0' et '00'.
                if (entry === '0') {
                    // Pour un seul '0', ajoutez simplement le '0' à la chaîne.
                    currentEntryAsString += '0';
                } else if (entry === '00') {
                    // Pour '00', ajoutez deux '0' à la chaîne.
                    currentEntryAsString += '00';
                } else {
                    // Pour les autres chiffres, retirez le premier chiffre si c'est un '0' avant d'ajouter l'entrée.
                    if (currentEntryAsString.startsWith('0')) {
                        currentEntryAsString = currentEntryAsString.substring(1);
                    }
                    currentEntryAsString += entry;
                }
        
                // Assurez-vous que la chaîne n'excède pas 5 caractères pour correspondre à xxx.xx
                if (currentEntryAsString.length > 5) {
                    currentEntryAsString = currentEntryAsString.substring(currentEntryAsString.length - 5);
                }
        
                // Convertit la chaîne mise à jour en nombre et divise par 100 pour obtenir le format décimal.
                const newEntry = parseFloat(currentEntryAsString) / 100;
        
                return {
                    ...caisse,
                    entry: newEntry
                };
            });
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const eraseEntry = () => {
        if(req.tryAuthorization(7)){
            setCaisse(caisse => {
                return {
                    ...caisse,
                    entry: 0.00
                };
            });
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const eraseLastNumber = () => {
        if(req.tryAuthorization(7)){
            setCaisse(caisse => {
                let currentEntryAsString = caisse.entry.toFixed(2).replace('.', '');
                currentEntryAsString = currentEntryAsString.substring(0, currentEntryAsString.length - 1);
                if (currentEntryAsString.length === 0) {
                    currentEntryAsString = '0';
                }
                const newEntry = parseFloat(currentEntryAsString) / 100;
                return {
                    ...caisse,
                    entry: newEntry
                };
            });
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const addArticle = () => {
        if (req.tryAuthorization(7)) {
            if(caisse.reglements.length > 0){
                alert('Vous ne pouvez pas ajouter un article si un règlement a déjà été effectué');
                setCaisse({
                    ...caisse,
                    entry: 0.00
                });
                return;
            }
            setCaisse(caisse => {
                return {
                    ...caisse,
                    entry: 0.00,
                    articles: [...caisse.articles, 
                        {   
                            name: 'Divers', 
                            ex: 1,
                            prixHt : caisse.entry / 1.2,
                            prixTtc : caisse.entry,
                            price: caisse.entry,
                            tva : '20',
                            option : 0,
                            modifyName : false,
                            modifyExemplaires : false, 
                            modifyPrice : false
                        }],
                    totalHT: caisse.totalHT + caisse.entry / 1.2,
                    totalTTC: caisse.totalTTC + caisse.entry
                };
            });
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const modifyArticleValue = (index, type, value) => {
        if(req.tryAuthorization(7)){
            if(caisse.reglements.length > 0){
                alert('Vous ne pouvez pas modifier un article si un règlement a déjà été effectué');
                return;
            }
            setCaisse(caisse => {
                let articles = caisse.articles
                if(type === 'ex'){
                    articles[index].ex = value
                } else if (type === 'name'){
                    articles[index].name = value
                } else if (type === 'price') {
                    articles[index].price = value
                } else {
                    console.log('Erreur de type')
                }
                return {
                    ...caisse,
                    articles: articles
                };
            });
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const deleteArticle = (index) => {
        if(req.tryAuthorization(7)){
            if(caisse.reglements.length > 0){
                alert('Vous ne pouvez pas supprimer un article si un règlement a déjà été effectué');
            } else {
                setCaisse(caisse => {
                    let articles = caisse.articles
                    if(articles[index] && articles[index].calcul){
                        let calcul = false;
                        /* On vérifie si un article est en mode calcul, si c'est le cas, on passe la variable calcul à true */

                        articles.map(article => {
                            if(article.calcul){
                                calcul = true
                            }
                        })

                        articles.splice(index, 1)
                        return {
                            ...caisse,
                            articles: articles,
                            quantityCalculation : calcul
                        };
                    } else {
                        articles.splice(index, 1)

                        let calcul = false;
                        /* On vérifie si un article est en mode calcul, si c'est le cas, on passe la variable calcul à true */

                        articles.map(article => {
                            if(article.calcul){
                                calcul = true
                            }
                        })

                        return {
                            ...caisse,
                            articles: articles,
                            quantityCalculation : calcul
                        };
                    }
                });
            }
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const editModeToggle = (index, type) => {
        if (req.tryAuthorization(7)) {
            setCaisse(caisse => {
                let articles = [...caisse.articles];
        
                if(type === 'name'){
                    articles[index] = { ...articles[index], modifyName: !articles[index].modifyName };
                } else if(type === 'ex'){
                    if(articles[index].ex <= 0){
                        deleteArticle(index)
                    }
                    articles[index] = { ...articles[index], modifyExemplaires: !articles[index].modifyExemplaires };
                } else {
                    articles[index] = { ...articles[index], modifyPrice: !articles[index].modifyPrice };
                }

                return {
                    ...caisse,
                    articles: articles
                };
            });
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const calculTotal = () => {
        if(req.tryAuthorization(7)) {
            let totalHT = 0;
            let totalTTC = 0;
            caisse.articles.map(article => {
                totalHT += article.price / 1.2 * article.ex
                totalTTC += article.price * article.ex
            });

            let totaleRest = totalTTC;
            if (caisse.reglements.length > 0) {
                totaleRest = totalTTC - caisse.articles.reduce((acc, article) => acc + article.price * article.exemplaires, 0);
            }
            setCaisse(caisse => {
                return {
                    ...caisse,
                    totalHT: totalHT,
                    totalTTC: totalTTC,
                    totaleRest : totaleRest
                };
            });
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const addReglement = (type) => {
        if(req.tryAuthorization(7)){
            let typeReglement = 0;
            if(type === 'especes'){
                typeReglement = 1;
            } else if(type === 'carte'){
                typeReglement = 2;
            } else if(type === 'cheque'){
                typeReglement = 3;
            } else {
                console.log('Erreur de type de règlement')
            }
            let amount = caisse.entry === 0 ? caisse.totaleRest : caisse.entry;
            let totaleRest = caisse.totalTTC - caisse.reglements.reduce((acc, reglement) => acc + reglement.amount, 0) - amount;
            setCaisse(caisse => {
                return {
                    ...caisse,
                    entry: 0.00,
                    reglements: [...caisse.reglements, { 
                        id: uuidv4(), // Utiliser uuid pour générer un identifiant unique
                        amount: amount,
                        comment: '',
                        type: typeReglement
                    }],
                    totaleRest: totaleRest
                };
            });
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }  
    // const searchArticle = async (e) => {
    //     if(req.tryAuthorization(7)){
    //         console.log(e.target.value);
    //         setCaisse(caisse => {
    //             return {
    //                 ...caisse,
    //                 articlesSearch: e.target.value,
    //             };
    //         })
    //         try {
    //             const response = await axios.post(
    //                 process.env.REACT_APP_SERVER_URL + "/api/articleSearch",
    //                 {
    //                     enterprise_id: req.user.enterprise.id,
    //                     article_search : e.target.value
    //                 }
    //             );
    //             if(response.data){
    //                 console.log(response.data.rows);
    //                 setCaisse(caisse => {
    //                     return {
    //                         ...caisse,
    //                         articlesList: response.data.rows,
    //                         articlesSearch: e.target.value
    //                     };
    //                 })
    //             } else {
    //                 console.log('Aucun article trouvé')
    //             }

    //         } catch (error) {
    //             console.error(error);
    //         }
    //     } else {
    //         req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
    //     }
    // }
    const selectArticle = (index) => {
        if (req.tryAuthorization(7)) {
            console.log('article sélectionné : ' + caisse.articlesList[index].name);
            console.log(caisse.articlesList[index].calcul);
            setCaisse({
                ...caisse,
                articlesSearch: '',
                articles: [...caisse.articles,
                    {name: caisse.articlesList[index].name, 
                    ex: 1, 
                    price: caisse.articlesList[index].prix_vente_ttc, 
                    modifyName : false,
                    modifyExemplaires : false, 
                    modifyPrice : false,
                    calcul : caisse.articlesList[index].calcul || false,
                    calcul_sentence : caisse.articlesList[index].calcul_sentence || '',
                }],
                totalHT: caisse.totalHT + caisse.articlesList[index].prix_vente_ttc / 1.2,
                totalTTC: caisse.totalTTC + caisse.articlesList[index].prix_vente_ttc,
                quantityCalculation : caisse.articlesList[index].calcul ? true : false
            })
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const selectCategoryArticle = (index) => {
        if(req.tryAuthorization(7)) {
            setCaisse({
                ...caisse,
                articlesSearch: '',
                articles: [...caisse.articles,
                    {name: articles[index].name, 
                    ex: 1, 
                    price: articles[index].prix_vente_ttc,
                    calcul : articles[index].calcul,
                    calcul_sentence : articles[index].calcul_sentence,
                    modifyName : false,
                    modifyExemplaires : false, 
                    modifyPrice : false}
                ],
                totalHT: caisse.totalHT + articles[index].prix_vente_ttc / 1.2,
                totalTTC: caisse.totalTTC + articles[index].prix_vente_ttc,
                quantityCalculation : articles[index].calcul ? true : false
            })
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const searchCustomer = async (e) => {
        if(req.tryAuthorization(7)){
            console.log(e.target.value);
            setCaisse(caisse => {
                return {
                    ...caisse,
                    clientSearch: e.target.value
                };
            })
            try {
                console.log(req.user.enterprise.id);
                const response = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/clientSearch",
                    {
                        enterprise_id: req.user.enterprise.id,
                        client_search : e.target.value
                    }
                );
                if(response.data){
                    console.log(response.data.rows);
                    setCaisse(caisse => {
                        return {
                            ...caisse,
                            clientsList: response.data.rows,
                            clientSearch: e.target.value
                        };
                    })
                } else {
                    console.log('Aucun client trouvé')
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const selectCustomer = async (index) => {
        if(req.tryAuthorization(7)){
            let newClient = caisse.clientsList[index];
            newClient.bassinSize = 0;

            /* On recherche si un bassin existe pour ce client */

            try {
                const response = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/clientBassin",
                    {
                        client_id: newClient.id
                    }
                );
                if(response.data.success){
                    console.log(response.data.bassin);

                    let technic = JSON.parse(response.data.bassin[0].technic);
                    console.log(technic);

                    let largeur = technic.largeur;
                    let longueur = technic.longueur;
                    let profondeur = technic.profondeur;
                    let mCube = technic.mcube;

                    newClient.bassinSize = mCube;
                    newClient.bassinLargeur = largeur;
                    newClient.bassinLongueur = longueur;
                    newClient.bassinProfondeur = profondeur;

                    console.log(mCube);

                    console.log(newClient);

                    setCaisse({
                        ...caisse,
                        clientSearch: '',
                        client: newClient,
                        clientsList: []
                    })
                } else {
                    console.log('Aucun bassin trouvé')

                    setCaisse({
                        ...caisse,
                        clientSearch: '',
                        client: newClient,
                        clientsList: []
                    })
                }
            } catch (error) {
                console.error(error);
            
                setCaisse({
                    ...caisse,
                    clientSearch: '',
                    client: newClient,
                    clientsList: []
                })
            }
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const clientDismiss = () => {
        if (req.tryAuthorization(7)) {
            setCaisse({
                ...caisse,
                client: {
                    id : 0,
                    name : '',
                    surname : '',
                    adresse : '',
                    adresseBis : '',
                    zip : '',
                    city : '',
                    phone : '',
                    mail : '',
                    bassinSize : 0
                }
            })
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const clientCreation = () => {
        if (req.tryAuthorization(7) && req.tryAuthorization(8)) {
            setCaisse({
                ...caisse,
                customer_creation: !caisse.customer_creation
            })
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const handleNewClient = (e) => {
        if (req.tryAuthorization(7) && req.tryAuthorization(8)) {
            setCaisse({
                ...caisse,
                customer: {
                    ...caisse.customer,
                    [e.target.id]: e.target.value
                }
            })
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        } 
    }
    const validNewCustomer = () => {
        if(req.tryAuthorization(7) && req.tryAuthorization(8)){
            setCaisse({
                ...caisse,
                client : caisse.customer,
                customer_creation : false,
                customer : {
                    name : '',
                    surname : '',
                    adresse : '',
                    adresseBis : '',
                    zip : '',
                    city : '',
                    phone : '',
                    mail : ''
                }
            })
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const validFacture = async () => {
        if (req.tryAuthorization(7)) {
            if(req.tryAuthorization(7)) {
                console.log('Enregistrement de la facture en cours...');
                console.log(caisse.client);
                // On vérifie que toute les données soit valides

                if(caisse.articles.length === 0){
                    alert('Vous ne pouvez pas enregistrer une facture sans articles');
                    return;
                } else if(caisse.reglements.length === 0){
                    alert('Vous ne pouvez pas enregistrer une facture sans règlement');
                    return;
                } else {
                    if(caisse.client.name === ''){
                        // Création sous "Client caisse"
                        setCaisse({
                            ...caisse,
                            client: {
                                name : 'Client caisse',
                                surname : '',
                                adresse : '',
                                adresseBis : '',
                                zip : '',
                                city : '',
                                phone : '',
                                mail : ''
                            }
                        })
                    }

                    try {
                        const response = await axios.post(
                            process.env.REACT_APP_SERVER_URL + "/api/factureSave",
                            {
                                facture : {
                                    enterprise_id: req.user.enterprise.id,
                                    id : 0,
                                    num : 0,
                                    type : 0,
                                    name : 'Facture Magasin',
                                    editable : 0,
                                    reglement : caisse.reglements,
                                    total_ttc : caisse.totalTTC,
                                    total_taxe : caisse.totalTTC - caisse.totalHT,
                                    total_ht : caisse.totalHT,
                                    articles : caisse.articles,
                                    rDue : caisse.totaleRest,
                                    client : {
                                        id : caisse.client.id,
                                        name : caisse.client.name,
                                        surname : caisse.client.surname,
                                        adresse : caisse.client.adresse,
                                        adresseBis : caisse.client.adresseBis,
                                        zip : caisse.client.zip,
                                        city : caisse.client.city,
                                        tel : caisse.client.phone,
                                        mail : caisse.client.mail
                                    }
                                }
                            }
                        );
                        if(response.data){
                            console.log(response.data);
                            setCaisse({
                                entry : 0.00,
                                totalHT : 0,
                                totalTTC : 0,
                                totaleRest : 0,
                                articles : [],
                                reglements : [],
                                client : {},
                                clientSearch : '',
                                articlesSearch : '',
                                clientsList : [],
                                articlesList : [],
                                customer_creation : false,
                                customer : {
                                    name : '',
                                    surname : '',
                                    address : '',
                                    zip : '',
                                    city : '',
                                    phone : '',
                                    mail : ''
                                }
                            })
                        } else {
                            console.log('Erreur lors de l\'enregistrement de la facture')
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
            } else {
                req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
            }
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const newFacture = () => {
        if (req.tryAuthorization(7)) {
            setCaisse({
                entry : 0.00,
                totalHT : 0,
                totalTTC : 0,
                totaleRest : 0,
                articles : [],
                reglements : [],
                client : {},
                clientSearch : '',
                articlesSearch : '',
                clientsList : [],
                articlesList : [],
                customer_creation : false,
                customer : {
                    name : '',
                    surname : '',
                    address : '',
                    zip : '',
                    city : '',
                    phone : '',
                    mail : ''
                }
            })
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }



    const categoriesList = async (e = {}) => {
        if (req.tryAuthorization(7)) {
            console.log("Demande des catégories");
            try {
                let searchText = '';
                
                if(e && e.target && e.target.value !== undefined) {
                    setSearch({
                        ...search,
                        value : e.target.value.toLowerCase()
                    });
                    searchText = e.target.value.toLowerCase();
                } else {
                    searchText = search.value.toLowerCase();
                }

                const myCategories = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/categories",
                    {
                        search : searchText,
                        enterprise_id: req.user.enterprise.id
                    }
                );
                if (myCategories.data) {
                    console.log(myCategories.data);
                    setCategories(myCategories.data.rows);
                    if (inventaire.status === 1) {
                        sousCategoriesList(inventaire.categorie);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('Vous n\'avez pas les droits pour voir les catégories');
        }
    }
    const sousCategoriesList = async (categorie_name) => {
        if (req.tryAuthorization(7)) {
            console.log("Demande des sous-catégories");
            try {
                const myCategories = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/sousCategories",
                    {
                        enterprise_id: req.user.enterprise.id,
                        categorie_name: categorie_name,
                        search : search.value.toLowerCase()
                    }
                );
                if (myCategories.data) {
                    console.log(myCategories.data);
                    setSousCategories(myCategories.data.rows);
                    setInventaire({
                        ...inventaire,
                        status : 1,
                        categorie : categorie_name
                    });
                    if(inventaire.sousCategorie !== '') {
                        articlesListMore(inventaire.sousCategorie);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('Vous n\'avez pas les droits pour voir les sous-catégories');
        }
    }
    const articlesListMore = async (sousCategorie) => {
        if (req.tryAuthorization(7)) {
            try {
                console.log("sousCat : " + sousCategorie);
                console.log("cat : " + inventaire.categorie);
                console.log("offset : " + inventaire.offset);
                console.log("limit : " + inventaire.limit);

                const myArticle = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/articlesMore",
                    {
                        sousCategorie: sousCategorie,
                        categorie: inventaire.categorie,
                        offset: inventaire.sousCategorie === sousCategorie ? inventaire.offset : 0,
                        limit: inventaire.limit,
                        search : search.value.toLowerCase(),
                        enterprise_id: req.user.enterprise.id
                    }
                );
                if (myArticle.data) {
                    console.log(myArticle.data.rows);
                    if(inventaire.offset === 0 || inventaire.sousCategorie !== sousCategorie) {
                        setArticles(myArticle.data.rows);
                    } else {
                        setArticles([...articles, ...myArticle.data.rows]);
                    }
                    

                    setInventaire({
                        ...inventaire,
                        offset : myArticle.data.offset,
                        sousCategorie : sousCategorie
                    });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('Vous n\'avez pas les droits pour voir les articles');
        }
    }
    const categorieBack = () => {
        if (req.tryAuthorization(7)) {
            setInventaire({
                ...inventaire,
                status : 0,
                categorie : '',
                sousCategorie : ''
            });
        } else {
            console.log('Vous n\'avez pas les droits pour revenir en arrière');
        }
    }





    const calculQuantity = () => {
        console.log('Calcul des quantités');
        setQuantityBox(!quantityBox);
    }
    const quantityBassinSizeChange = (e) => {
        setCaisse({
            ...caisse,
            client : {
                ...caisse.client,
                bassinSize : e.target.value
            }
        })
    }
    const calculQuantityExact = (article) => {
        console.log('Calcul exact des quantités');
        console.log(article);

        let calculArray = [];
        let calcul;
        let i_array = [
            0.1,
            0.2,
            0.5,
            1,
            2,
            3,
            4
        ];
        let i = 0;
        while (i < 6) {
            calcul = article.calcul.replace('volume_du_bassin', caisse.client.bassinSize);
            calcul = calcul.replace('unité_pH_à_diminuer', i_array[i]);
            
            if(article.calcul_sentence){
                console.log(article.calcul_sentence);
                let calcul_sentence = article.calcul_sentence.replace('unité_pH_à_diminuer', i_array[i]);
                calcul_sentence = calcul_sentence.replace('resultat', eval('(' + calcul + ') / 1000').toFixed(2));

                calculArray.push(
                    calcul_sentence
                );
            } else {
                calculArray.push(
                    eval('(' + calcul + ') / 1000').toFixed(2)
                );
            }
            i++;
        }
        return calculArray;
    }

    useEffect(() => {
        calculTotal()
    }, [caisse.articles, caisse.articles.length]);
    useEffect(() => {
        console.log('CATEGORIES LIST');
        categoriesList();
    }, []);
    useEffect(() => {
        console.log('CALCUL :' + caisse.quantityCalculation);
    }, [caisse.quantityCalculation]);
    
    return(
        <div className='Caisse'>
            <div className='CaisseEnregistreuse'>
                <div className='CaisseEnregistreusePad'>
                    <div className='CaisseEnregistreuseNouvelleFacture' onClick={newFacture}>Nouvelle facture</div>
                    <div className='CaisseEnregistreusePadScreen'>{caisse.entry.toFixed(2)} €</div>
                    <div className='CaisseEnregistreusePadTouch Pad7' onClick={() => addNumericEntry(7)}>7</div>
                    <div className='CaisseEnregistreusePadTouch Pad8' onClick={() => addNumericEntry(8)}>8</div>
                    <div className='CaisseEnregistreusePadTouch Pad9' onClick={() => addNumericEntry(9)}>9</div>
                    <div className='CaisseEnregistreusePadTouch Pad4' onClick={() => addNumericEntry(4)}>4</div>
                    <div className='CaisseEnregistreusePadTouch Pad5' onClick={() => addNumericEntry(5)}>5</div>
                    <div className='CaisseEnregistreusePadTouch Pad6' onClick={() => addNumericEntry(6)}>6</div>
                    <div className='CaisseEnregistreusePadTouch Pad1' onClick={() => addNumericEntry(1)}>1</div>
                    <div className='CaisseEnregistreusePadTouch Pad2' onClick={() => addNumericEntry(2)}>2</div>
                    <div className='CaisseEnregistreusePadTouch Pad1' onClick={() => addNumericEntry(3)}>3</div>
                    <div className='CaisseEnregistreusePadTouch Pad0' onClick={() => addNumericEntry(0)}>0</div>
                    <div className='CaisseEnregistreusePadTouch Pad00' onClick={() => addNumericEntry('00')}>00</div>
                    <div className='CaisseEnregistreusePadTouch PadValider' onClick={eraseEntry}>Annuler</div>
                    <div className='CaisseEnregistreusePadTouch PadValider' onClick={eraseLastNumber}>Retour</div>
                    <div className='CaisseEnregistreusePadTouch PadValider' onClick={addArticle}>Valider</div>
                    <div className='CaisseEnregistreusePadTouch PadValider' onClick={() => addReglement('especes')}>ESPECES</div>
                    <div className='CaisseEnregistreusePadTouch PadValider' onClick={() => addReglement('carte')}>CARTE</div>
                    <div className='CaisseEnregistreusePadTouch PadValider' onClick={() => addReglement('cheque')}>CHEQUE</div>
                </div>
                <div className='CaisseEnregistreuseCategories'>
                    <div className='CaisseEnregistreuseArticleSearch'>
                        <input type='text' className='CaisseEnregistreuseArticleSearchInput' value={search.value} onChange={categoriesList} placeholder='Rechercher un article'/><span className='SearchIcon'></span>

                    </div>
                    <div className='CaisseEnregistreuseCategoriesZone'>
                        {console.log(categories)}
                        {
                            inventaire.status === 0 ?
                            categories.map((categorie, index) => (
                                <div className='CaisseEnregistreuseCategorie' onClick={() => sousCategoriesList(categorie.categorie)}>{categorie.categorie}</div>
                            )) : 
                            <>
                                <div className='CaisseEnregistreuseCategorieBack' onClick={categorieBack}>Retour</div>
                                {sousCategories.map((sousCategorie, index) => (
                                    <>
                                        <div className='CaisseEnregistreuseCategorie' onClick={inventaire.sousCategorie === sousCategorie.sous_categorie ? () => setInventaire({...inventaire, sousCategorie: ''}) : () => articlesListMore(sousCategorie.sous_categorie)}>{sousCategorie.sous_categorie}</div>
                                        {inventaire.sousCategorie === sousCategorie.sous_categorie &&
                                            articles.map((article, index) => (
                                                <div className='CaisseEnregistreuseArticle' onClick={() => selectCategoryArticle(index)}>
                                                    <div className='CaisseEnregistreuseArticleName'>{article.name}</div>
                                                    <div className='CaisseEnregistreuseArticlePrice'>{article.prix_vente_ttc} €</div>
                                                </div>
                                            ))
                                        }
                                    </>
                                ))}
                            </>
                        }
                    </div>
                    
                </div>
            </div>
            <div className='CaisseList'>
                <div className={!caisse.client.name ? 'CaisseClientInactive' : 'CaisseClient'}>
                    <div className='CaisseClientTitle'>Client <span className='CaisseClientClose' onClick={clientDismiss}>Annuler</span></div>
                    <div className='CaisseClientName'>{caisse.client.name} {caisse.client.surname}</div>
                    <div className='CaisseClientInfo'>{caisse.client.adresse}</div>
                    <div className='CaisseClientInfo'>{caisse.client.adresseBis}</div>
                    <div className='CaisseClientInfo'>{caisse.client.zip} {caisse.client.city}</div>
                    <div className='CaisseClientInfo'>{caisse.client.phone}</div>
                    <div className='CaisseClientInfo'>{caisse.client.mail}</div>
                </div>
                <div className={!caisse.client.name ? 'CaisseClientOperation' : 'CaisseClientOperationInactive'}>
                    <div className={!caisse.customer_creation ? 'CaisseCreationClient' : 'CaisseCreationClientInactive'} onClick={clientCreation}>Créer un client</div>
                    <div className={!caisse.customer_creation ? 'CaisseRechercheClient' : 'CaisseRechercheClientInactive'}>
                        <input type='text' value={caisse.clientSearch} onChange={(e) => searchCustomer(e)} placeholder='Rechercher un client'/><span className='SearchIcon'></span>
                        <div className={caisse.clientsList.length > 0 && caisse.clientSearch ? 'CaisseRechercheClientSearchResult' : 'CaisseEnregistreuseArticleSearchResultInactive'}>
                            {
                                caisse.clientsList.map((client, index) => (
                                    <div className='CaisseRechercheClientSearchResultClient' onClick={() => selectCustomer(index)}>
                                        <div className='CaisseRechercheClientSearchResultClientName'>{client.name} {client.surname}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className={caisse.customer_creation ? 'CaisseCreationClientZone' : 'CaisseCreationClientZoneInactive'}>
                        <input type='text' value={caisse.customer.surname} id='surname' onChange={handleNewClient} placeholder='Nom'/>
                        <input type='text' value={caisse.customer.name} id='name' onChange={handleNewClient} placeholder='Prénom'/>
                        <input type='text' value={caisse.customer.adresse} id='adresse' onChange={handleNewClient} placeholder='Adresse'/>
                        <input type='text' value={caisse.customer.adresseBis} id='adresseBis' onChange={handleNewClient} placeholder='Adresse Suite'/>
                        <input type='text' value={caisse.customer.zip} id='zip' onChange={handleNewClient} placeholder='Code postal'/>
                        <input type='text' value={caisse.customer.city} id='city' onChange={handleNewClient} placeholder='Ville'/>
                        <input type='text' value={caisse.customer.tel} id='tel' onChange={handleNewClient} placeholder='Téléphone'/>
                        <input type='text' value={caisse.customer.mail} id='mail' onChange={handleNewClient} placeholder='Mail'/>
                        <div className='CaisseCreationClientValidation' onClick={validNewCustomer}>Valider</div>
                        <div className='CaisseCreationClientAnnulation' onClick={clientCreation}>Annuler</div>
                    </div>
                </div>
                <div className='CaisseArticleListZone'>
                    <div className='CaisseArticleList'>
                        <div className='CaisseArticleListHeader'>
                            <div className='CaisseArticleListHeaderArticle'>Article</div>
                            <div className='CaisseArticleListHeaderQuantite'>Quantité</div>
                            <div className='CaisseArticleListHeaderPrix'>Prix</div>
                            <div className='CaisseArticleListHeaderDelete'></div>
                        </div>
                        {caisse.articles.map((article, index) => (
                            // Empecher la dupplication d'articles
                            <div className='CaisseArticleListArticle'>
                                <div className='CaisseArticleListArticleName'>
                                    {!article.modifyName ? <span onClick={() => editModeToggle(index, 'name')}>{article.name}</span> : <input type='text' onBlur={() => editModeToggle(index, 'name')} value={article.name} onChange={(e) => modifyArticleValue(index, 'name', e.target.value)}/> }
                                </div>
                                <div className='CaisseArticleListArticleQuantity'>
                                    {!article.modifyExemplaires ? <span onClick={() => editModeToggle(index, 'ex')}>{article.ex}</span> : <input type='number' onBlur={() => editModeToggle(index, 'ex')} value={article.ex} onChange={(e) => modifyArticleValue(index, 'ex', e.target.value)}/> }
                                </div>
                                <div className='CaisseArticleListArticlePrice'>
                                    {!article.modifyPrice ? <span onClick={() => editModeToggle(index, 'price')}>{article.price.toFixed(2)}</span> : <input type='number' onBlur={() => editModeToggle(index, 'price')} value={article.price.toFixed(2)} onChange={(e) => modifyArticleValue(index, 'price', e.target.value)}/> }
                                </div>
                                <div className='CaisseArticleListArticleDelete' onClick={() => deleteArticle(caisse.articles.indexOf(article))}><XSquareFill size={16}/></div>
                            </div>
                        ))}
                        
                    </div>
                    <div className='CaisseArticleListTotal'>
                        <div className='reglementsZone'>
                            {caisse.reglements.map(reglement => (
                                <div className='reglement'>{reglement.amount} € - {reglement.type}</div>
                            ))}
                        </div>
                        Total HT : {caisse.totalHT.toFixed(2)} €<br/>
                        Total TTC : {caisse.totalTTC.toFixed(2)} €<br/>
                        {caisse.totaleRest >= 0 ? 'Restant à payer ' : 'Rendu monnaie '} : {caisse.totaleRest >= 0 ? caisse.totaleRest.toFixed(2) : (caisse.totaleRest * -1).toFixed(2)} €
                    </div>
                </div>
                <div className={caisse.quantityCalculation ? 'CaisseFactureCalcul' : 'CaisseFactureCalculUnactive'} onClick={calculQuantity}>Calcul des quantités</div>
                <div className='CaisseFactureValidation' onClick={validFacture}>Enregister la facture</div>
            </div>
            <div className={quantityBox ? 'quantityMask' : 'quantityMaskUnactive'}>
                <div className='quantityBox'>
                    <div className='quantityBoxTitle'>Calcul des quantités</div>
                    <div className='quantityBoxContent'>
                        <div className='quantityBoxBassinSize'>
                            Taille du bassin (mètres cubes) :
                            <input type='number' placeholder='Taille du bassin (mètre cube)' className='quantityBoxBassinSizeInput' value={caisse.client.bassinSize} onChange={quantityBassinSizeChange}/>
                        </div>

                        {caisse.articles.map((article, index) => article.calcul && (
                            <div className='quantityBoxContentArticle'>
                                <div className='quantityBoxContentArticleName'>{article.name}</div>
                                <div className='quantityBoxContentArticleQuantite'>{
                                    calculQuantityExact(article).map((calcul, index) => (
                                        <div className='quantityBoxContentArticleCalcul'>{calcul}</div>
                                    ))
                                }</div>
                            </div>
                        ))}
                    </div>
                    <div className='quantityActionBar'>
                        {/* <div className=''>Ces données seront ajouté à votre facture dans une page supplémentaire</div> */}
                        <div className='quantityBoxClose' onClick={calculQuantity}>Fermer</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Caisse