import React, { useEffect, useState } from 'react';
import './App.css';
import Appli from './Appli/Appli';
import OnePage from './OnePage/OnePage';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider/AuthProvider';
import {ThemeProvider, BaseStyles} from '@primer/react'
import InvoiceViewer from './Appli/InvoiceViewer/InvoiceViewer';
import Chat from './Chat/Chat';
import process from 'process';
import { jwtDecode } from 'jwt-decode';
import ReactGA from 'react-ga';
import Fournisseur from './Fournisseur/Fournisseur';
import { X } from 'react-bootstrap-icons';
import Blog from './Blog/Blog';
import BlogPage from './Blog/BlogPage/BlogPage';

const TRACKING_ID = "G-BSPDHD3FQH";
ReactGA.initialize(TRACKING_ID);

const RedirectToFacture = () => {
  const navigate = useNavigate();
  const { setCurrentUser } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem('session_token') || document.cookie.split('; ').find(row => row.startsWith('session_token='))?.split('=')[1];
    if (token) {
      try {
        const decoded = jwtDecode(token);
        if (decoded.exp * 1000 > Date.now()) {
          console.log('Token valide trouvé :', decoded);
          setCurrentUser(decoded);
          navigate('/app/facture', { replace: true });
        } else {
          console.log('Token expiré');
          localStorage.removeItem('session_token');
          document.cookie = 'session_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/app;';
          navigate('/', { replace: true });
        }
      } catch (error) {
        console.error("Erreur lors du décodage du token : ", error);
        localStorage.removeItem('session_token');
        document.cookie = 'session_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/app;';
      }
    } else {
      console.log('Aucun token trouvé');
      navigate('/', { replace: true });
    }
  }, [navigate, setCurrentUser]);

  return null;
};

function RequireAuth({ children }) {
  const { currentUser, isLoading } = useAuth();
  const location = useLocation();

  if (!currentUser && !isLoading) {
    return <RedirectToFacture />;
  }

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return children;
}

function App() {
  const { currentUser, setCurrentUser } = useAuth();
  const [userStatus, setUserStatus] = useState(null);

  const [alphaBanner, setAlphaBanner] = useState({
    active: 2
  });

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    console.log('location :', location);
  }, [location]);
  useEffect(() => {
    setUserStatus(currentUser);
  }, [currentUser]);
  useEffect(() => {
    console.log(process.env.REACT_APP_SERVER_URL);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider>
      <BaseStyles>
        <div className="App">
          <Routes>
            <Route path='/chat' element={<Chat />} />
            <Route path='/' element={<OnePage />} />
            <Route path='/login' element={<OnePage />} />
            <Route path='/login/:token' element={<OnePage />} />
            <Route path='/app/*' element={
              <RequireAuth>
                <Appli user={userStatus} />
              </RequireAuth>
            } />
            <Route path='/success' element={<OnePage />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/blogpage' element={<BlogPage />} />
            <Route path='/fournisseur' element={<Fournisseur />} />
            <Route path='/invoices/:filename' element={
              <RequireAuth>
                <InvoiceViewer />
              </RequireAuth>
            } />
            <Route path='/Appli/Facturation/Facturation'  element={<embed src={'/Appli/Facturation/Facturation'} type="application/pdf" width="100%" height="600px" />}/>
          </Routes>
          {console.log('alphaBanner :', alphaBanner.active !== 0)}
          {alphaBanner.active !== 0 ? ( 
              <div className={alphaBanner.active === 1 ? 'Alpha_banner_active' : 'Alpha_banner'}>
                <p>
                  Ceci est une version alpha, Vous êtes les premiers testeurs de cette application.
                </p>
                {alphaBanner.active !== 1 && <div className='Alpha_banner_button' onClick={() => setAlphaBanner({active : 1})}>en savoir plus </div>}
                <div className='Alpha_banner_close' onClick={() => setAlphaBanner({active : 0})}>
                  <X size={16} />
                </div>
                <div className='Alpha_banner_more' style={{display : alphaBanner.active === 1 ? 'block' : 'none'}}>
                  <p>
                    Cette application est en cours de développement, elle est donc susceptible de contenir des bugs et des erreurs. 
                    Si vous en trouvez, n'hésitez pas à nous les signaler à l'adresse suivante : <a href="mailto:contact@bluestock.fr"> contact@bluestock.fr </a>
                    Vous êtes parmis les premiers abonnés à utiliser cette application, nous vous remercions pour votre confiance et votre patience.
                    Vous serez classé "Utilisateur Alpha" et bénéficierez d'avantage lors de la sortie officielle de l'application.
                    Merci à vous !
                  </p>
              </div>
              </div>
            ) : null}
        </div>
      </BaseStyles>
    </ThemeProvider>
  );
}

export default App;
