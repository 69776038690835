import React from 'react';
//import './InvoiceViewer.css';
import { useLocation } from 'react-router-dom';


function InvoiceViewer(req) {
    const location = useLocation();
    console.log(req);
    const filename = req.url ? req.url : '/invoices/' + location.pathname.split('/').pop();
    console.log(filename);
    const filePath = `/invoices/${filename}`;
  
    //IDEA : ajouter un module d'explication

    return (
      <embed src={filename} type="application/pdf" width="100%" height="600px" />
    );
}

export default InvoiceViewer;