import { useEffect, useState } from 'react';
import './Profil.css';
import axios from 'axios';
import { PersonCircle } from 'react-bootstrap-icons';

function Profil (req) {
    const [profil, setProfil] = useState({
        active : 0,
        name : '',
        surname : '',
        birth : '',
        sexe : '',
        phone : '',
        mail : '',
        image : '',
        picZone : 0,
        contact_status : 'dispo',
        pass : {
            pass : '',
            pass_valid : null,
            new_pass : '',
            new_pass_valid : null,
            re_new_pass : '',
            re_new_pass_valid : null,
            status : 0
        },
        pics : [
            'camel',
            'david',
            'eliott',
            'harry',
            'jeremy',
            'jim',
            'mike',
            'walter',
            'will'
        ]
    })
    const switchProfil = (e) => {
        if(e.target.id === 'Profil' || e.target.id === 'ProfilCloseButton'){

            setProfil({
                ...profil,
                active : profil.active === 0 ? 1 : 0
            })
        }
    }
    const togglePassSection = (e) => {
        if(e.target.id === 'ProfilConnecPassChange' || e.target.id === 'ProfilConnecPassChangeClose' || e.target.id === 'ProfilConnecPassChangeValid' || e.target.id === 'ProfilConnecPassChangeUnvalid'){
            setProfil({
                ...profil,
                pass : {
                    ...profil.pass,
                    status : profil.pass.status === 0 ? 1 : 0,
                    re_new_pass_valid : profil.pass.re_new_pass === "" && profil.pass.new_pass === "" ? null : (profil.pass.new_pass === profil.pass.re_new_pass ? 1 : 0)
                }
            })
        }
    }
    const handleChange = (e, variable) => {
        console.log(variable + ':' + e.target.value);
        setProfil({
            ...profil,
            [variable] : e.target.value
        })
    }
    const handleChangePass = (e, variable) => {
        console.log('handle change pass');
        let status = null;
        let new_pass = profil.pass.new_pass;
        let re_new_pass = profil.pass.re_new_pass;
        if(variable === 'new_pass' && e.target.value !== '' && e.target.value !== null && e.target.value !== undefined){
            new_pass = e.target.value;
            if (e.target.value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#\$%^&*()_\-+=<>?/]).{8,}$/)) {
                status = 5;
            } else if (e.target.value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/)) {
                status = 4;
            } else if (e.target.value.match(/^(?=.*[A-Z])(?=.*[a-z]).{6,}$/)) {
                status = 3;
            } else if (e.target.value.match(/^.{6,}$/)) {
                status = 2;
            } else if(e.target.value.match(/^.{1,5}$/)) {
                status = 1;
            } else {
                status = 0;
            }
            console.log(status);
        } else if (variable === 're_new_pass' && e.target.value !== '' && e.target.value !== null && e.target.value !== undefined){
            re_new_pass = e.target.value;
            if(e.target.value === profil.pass.new_pass){
                status = 1;
            } else if(e.target.value !== profil.pass.new_pass) {
                status = 0;
            }
        }
        setProfil({
            ...profil,
            pass : {
                ...profil.pass,
                [variable] : e.target.value,
                [variable + '_valid'] : status,
                re_new_pass_valid : new_pass === re_new_pass ? 1 : (new_pass !== '' && re_new_pass !== '' ? 0 : null)
            }
        })
    }
    const changePass = async () => {
        //IDEA : Sécurisé la modification avec une en-tête Authorization
        try {
            console.log(profil.pass.new_pass_valid, profil.pass.re_new_pass);
            if(profil.pass.new_pass !== profil.pass.re_new_pass){
                console.log('Les mots de passes ne correspondent pas');
                return;
            } else if (profil.pass.new_pass_valid !== 5){
                console.log('Les mots de passes ne sont pas valides');
                return;
            }

            const response = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/userUpdate",
                {
                    user_pass : profil.pass.pass,
                    new_pass : profil.pass.new_pass,
                    enterprise_id : req.user.enterprise.id,
                    user_id : req.user.id
                }
            )
            if(response.status === 200) {
                    console.log(response.status);
                    if(response.data) {
                    console.log(response.data);
                    if(response.data.success){
                        setProfil({
                            ...profil,
                            pass : {
                                ...profil.pass,
                                status : 0,
                                pass : '',
                                new_pass : '',
                                re_new_pass : ''
                            }
                        });
                    } else {
                        console.log('Erreur de changement de mot de passe');
                        setProfil({
                            ...profil,
                            pass : {
                                ...profil.pass,
                                status : 42
                            }
                        });
                    }
                    
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    const toggleChangePicZone = () => {
        setProfil({
            ...profil,
            picZone : profil.picZone === 0 ? 1 : 0
        })
    }
    const changePic = (image) => {
        setProfil({
            ...profil,
            image : image,
            picZone : 0
        })
    }
    const saveProfil = async () => {
        console.log('save profil changement');
        try {
            const response = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/userUpdate",
                {
                    user_id : req.user.id,
                    user_name : profil.name,
                    user_surname : profil.surname,
                    user_birth : profil.birth,
                    user_sexe : profil.sexe,
                    user_phone : profil.phone,
                    user_mail : profil.mail,
                    user_image : profil.image,
                    user_contact_status : profil.contact_status,
                    enterprise_id : req.user.enterprise.id
                }
            )
            if(response.status === 200) {
                console.log(response.status);
                if(response.data) {
                    console.log(response.data);
                    if(response.data.success){
                        console.log('Changement enregistré');
                        // On actualise le profil dans le menu latéral
                        req.setUser({
                            ...req.user,
                            name : profil.name,
                            surname : profil.surname,
                            birth : profil.birth,
                            sexe : profil.sexe,
                            phone : profil.phone,
                            mail : profil.mail,
                            image : profil.image,
                            contact_status : profil.contact_status
                        })

                        req.popToast('Profil enregistré', 1);
                    } else {
                        console.log('Erreur de changement');
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        setProfil({
            ...profil,
            active : req.user.profile_active
        })
    }, [req.user.profile_active])
    useEffect(() => {
        let formattedDate = '00-00-0000';
        const date = new Date(req.user.birth);
        console.log(date);
        if(date === 'Invalid Date')
            formattedDate = date.toISOString().split('T')[0];

        console.log(formattedDate);
        console.log(req.user);
        
        setProfil({
            ...profil,
            name : req.user.name,
            surname : req.user.surname,
            birth : formattedDate,
            sexe : req.user.sexe,
            phone : req.user.phone,
            mail : req.user.mail,
            image : req.user.image,
            contact_status : req.user.contact_status
        })
    }, [req.user.contact_status])

    return (
        <div onClick={switchProfil} id='Profil' className={profil.active ? 'Profil' : 'ProfilUnactive'}>
            <div id='profilZone' className='profilZone'>
                <span className='ProfilName'>
                    <PersonCircle size={24}></PersonCircle> Profil
                </span>
                <div className='ProfilBox'>
                    <div className='ProfilConnecInfo'>
                        <div className='ProfilConnecMail'>
                            <label htmlFor='mail'>Mail</label>
                            <input type='mail' id='mail' value={profil.mail} onChange={(e) => handleChange(e, 'mail')}></input>
                        </div>
                        <div className='ProfilConnecPassChange' id='ProfilConnecPassChange' onClick={togglePassSection}>
                            {profil.pass.status === 0 ? 'Modifier le mot de passe'
                            : (<>
                                <label htmlFor='pass'>Mot de passe actuel</label>
                                <input type='password' id='pass' onChange={(e) => handleChangePass(e, 'pass')}></input><br/>
                                <label htmlFor='new_pass'>Nouveau mot de passe</label>
                                <input type='password' id='new_pass' value={profil.pass.new_pass} onChange={(e) => handleChangePass(e, 'new_pass')}></input><br/>
                                <div className={profil.pass.new_pass_valid === null ? 'new_pass_test_bar_inactive' : 'new_pass_test_bar'}>
                                    <span className='valid_line'>
                                        <span className={'valid_line_dot valid_line_dot_' + profil.pass.new_pass_valid}></span> 
                                        <span className={'valid_line_dot ' + (profil.pass.new_pass_valid >= 2 ? 'valid_line_dot_' + profil.pass.new_pass_valid : '')}></span> 
                                        <span className={'valid_line_dot ' + (profil.pass.new_pass_valid >= 3 ? 'valid_line_dot_' + profil.pass.new_pass_valid : '')}></span> 
                                        <span className={'valid_line_dot ' + (profil.pass.new_pass_valid >= 4 ? 'valid_line_dot_' + profil.pass.new_pass_valid : '')}></span> 
                                        <span className={'valid_line_dot ' + (profil.pass.new_pass_valid >= 5 ? 'valid_line_dot_' + profil.pass.new_pass_valid : '')}></span> 
                                    </span>
                                    {profil.pass.new_pass_valid < 5 ? "Doit contenir au moins 8 caractères, avec au moins une lettre majuscule, une lettre minuscule, un chiffre et un symbole (!@#$%^&*()_-+=<>?/)" : "OK !"}
                                </div>
                                <label htmlFor='re_new_pass'>Répéter nouveau mot de passe</label>
                                <input type='password' id='re_new_pass' value={profil.pass.re_new_pass} onChange={(e) => handleChangePass(e, 're_new_pass')}></input><br/>
                                <div className={profil.pass.re_new_pass_valid === null ? 're_new_pass_matching_bar_inactive' : 're_new_pass_matching_bar'}>
                                    {profil.pass.re_new_pass_valid !== 1 ? "Les mots de passes sont différents" : "OK !"}</div>
                                <div className={'alert_pass_change'}>{profil.pass.status === 42 ? "Mot de passe incorrect" : ''}</div>
                                <button className={profil.pass.new_pass_valid === 5 && profil.pass.re_new_pass_valid === 1 ? 'ProfilConnecPassChangeValid' : 'ProfilConnecPassChangeUnvalid'} onClick={changePass}>Modifier</button>
                                <div className='ProfilConnecPassChangeClose' id='ProfilConnecPassChangeClose' onClick={togglePassSection}>Fermer</div>
                            </>)}
                        </div>
                    </div>
                    <div className='ProfilBasicInfo'>
                        <div className='ProfilBasicInfoText'>
                            <label htmlFor='name'>Nom</label>
                            <input type='text' value={profil.surname} id='surname' onChange={(e) => handleChange(e, 'surname')}></input>

                            <label htmlFor='surname'>Prénom</label>
                            <input type='text' value={profil.name} id='name' onChange={(e) => handleChange(e, 'name')}></input>

                            <label htmlFor='birth'>Date de naissance</label>
                            <input type='date' value={profil.birth} id='birth' onChange={(e) => handleChange(e, 'birth')}></input>

                            <label htmlFor='sexe'>Sexe</label>
                            <select id='sexe' value={profil.sexe} onChange={(e) => handleChange(e, 'sexe')}>
                                <option value='femme'>Femme</option>
                                <option value='homme'>Homme</option>
                                <option value='autre'>Autre</option>
                            </select>

                            <div className='profilInfoRole'>Role : <span>Directeur</span></div>
                        </div>
                        <div className='ProfilBasicInfoImage' style={{backgroundImage: `url(${window.location.origin}/Avatar/${profil.image}.png)`}}>
                            <div className='ProfilBasicInfoImageChange' onClick={toggleChangePicZone}>Changer la photo</div>
                        </div>
                    </div>
                    <div className={profil.picZone === 0  ? 'changePicZoneInactive' : 'changePicZone'} id='changePicZone'>
                        <div className='changePicZoneClose' onClick={toggleChangePicZone}>Fermer</div>
                        <div className='changePicZoneTitle'>Choisissez une photo de profil</div>
                        <div className='changePicZonePics'>
                              {profil.pics.map((pic, index) => {
                                return (
                                    <div key={index} id={pic} className='changePicZonePic' onClick={() => changePic(pic)}>
                                        <img className='avatar' src={window.location.origin + '/Avatar/' + pic + '.png'} alt={pic}></img>
                                    </div>
                                )
                              })}
                        </div>
                    </div>
                    <div className='ProfilContactInfo'>
                        <label htmlFor='tel'>Numéro de téléphone</label>
                        <input type='phone' id='tel'  value={profil.phone} onChange={(e) => handleChange(e, 'phone')}></input>

                        <label htmlFor='status'>Status</label>
                        <select id='status'  value={profil.contact_status} onChange={(e) => handleChange(e, 'contact_status')}>
                            <option value='dispo'>disponible</option>
                            <option value='occupe'>Occupé</option>
                            <option value='5minbreak'>5 min indisponible</option>
                            <option value='15minbreak'>15 min indisponible</option>
                            <option value='30minbreak'>30 min indisponible</option>
                            <option value='1hbreak'>1 heure indisponible</option>
                            <option value='4hbreak'>4 heures indisponible</option>
                            <option value='conge'>En congé</option>
                            <option value='malade'>Maladie</option>
                        </select>
                        {/* IDEA : Adapter au status déjà existant */}
                    </div>
                </div>
                <div className='ProfilSaveSection'>
                    <div className='ProfilSaveButton' onClick={saveProfil}>Enregistrer</div>
                    <div id='ProfilCloseButton' className='ProfilCloseButton' onClick={switchProfil}>Fermer</div>
                </div>
            </div>
        </div>
    )
}

export default Profil;