import React, { useState } from "react";
import './Fournisseur.css';
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

const Fournisseur = () => {
    const navigate = useNavigate();
    const [contact, setContact] = useState({
        nom: '',
        prenom: '',
        entreprise: '',
        email: '',
        tel: '',
        message: '',
        alert: '',
        status: 0
    });
    const handleModification = (e) => {
        e.preventDefault();
        setContact({
            ...contact,
            [e.target.name]: e.target.value
        });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        // On vérifie que tous les champs sont remplis correctement
        if (contact.nom === '') {
            setContact({
                ...contact,
                alert: 'Veuillez renseigner votre nom'
            });
            return;
        } else if (!contact.nom.match(/^[a-zA-Z]+$/)) {
            setContact({
                ...contact,
                alert: 'Votre nom ne doit contenir que des lettres'
            });
            return;
        } else if (contact.prenom === '') {
            setContact({
                ...contact,
                alert: 'Veuillez renseigner votre prénom'
            });
            return;
        } else if (!contact.prenom.match(/^[a-zA-Z]+$/)) {
            setContact({
                ...contact,
                alert: 'Votre prénom ne doit contenir que des lettres'
            });
            return;
        } else if (contact.entreprise === '') {
            setContact({
                ...contact,
                alert: 'Veuillez renseigner le nom de votre entreprise'
            });
            return;
        } else if (!contact.entreprise.match(/^[a-zA-Z0-9]+$/)) {
            setContact({
                ...contact,
                alert: 'Le nom de votre entreprise ne doit contenir que des lettres et des chiffres'
            });
            return;
        } else if (contact.email === '') {
            setContact({
                ...contact,
                alert: 'Veuillez renseigner votre email'
            });
            return;
        } else if (!contact.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
            setContact({
                ...contact,
                alert: 'Votre email doit être au bon format'
            });
            return;
        } else if (contact.tel === '') {
            setContact({
                ...contact,
                alert: 'Veuillez renseigner votre numéro de téléphone'
            });
            return;
        } else if (!contact.tel.match(/^[0-9]{10}$/)) {
            setContact({
                ...contact,
                alert: 'Votre numéro de téléphone doit être composé de 10 chiffres'
            });
            return;
        } else {
            setContact({
                ...contact,
                alert: ''
            });
        
            // On envoie les données
            try {
                const response = await axios.post('https://bluestock.fr/api/fournisseur_contact', {
                    fournisseur : contact});
                if (response.data.success) {
                    setContact({
                        nom: '',
                        prenom: '',
                        entreprise: '',
                        email: '',
                        tel: '',
                        message: '',
                        alert: 'Votre message a bien été envoyé',
                        status: 1
                    });
                } else {
                    setContact({
                        ...contact,
                        alert: 'Une erreur est survenue, veuillez réessayer'
                    });
                }
            } catch (error) {
                console.error('Erreur lors de l\'envoi du message : ', error);
                setContact({
                    ...contact,
                    alert: 'Une erreur est survenue, veuillez réessayer'
                });
            }
        }

    }

    return (
        <div className='Fournisseur'>
            <div className='title_bloc'>
                <div className="logo_block" onClick={() => navigate('../')}>
                    <div className="logo"></div>
                    Bluestock
                </div>
                <h1>Vous êtes fournisseur ?</h1>
                <p>
                    Nous recherchons également des fournisseurs pour nos clients. Si vous êtes intéressé, veuillez nous contacter grace à ce formulaire. Nous vous recontacterons dans les plus brefs délais.
                </p>
            </div>
            <div className='contact_bloc'>
                <form className={contact.status === 1 && "form_close"}>
                    <label>
                        Nom (contact)
                        <input type='text' name='nom' value={contact.nom} onChange={handleModification} />
                    </label>
                    <label>
                        Prénom (contact)
                        <input type='text' name='prenom' value={contact.prenom} onChange={handleModification} />
                    </label>
                    <label>
                        Nom de l'entreprise
                        <input type='text' name='entreprise' value={contact.entreprise} onChange={handleModification} />
                    </label>
                    <label>
                        Email
                        <input type='email' name='email' value={contact.email} onChange={handleModification} />
                    </label>
                    <label>
                        Téléphone
                        <input type='tel' name='tel' value={contact.tel} onChange={handleModification} />
                    </label>
                    <label>
                        Message
                        <textarea name='message' value={contact.message} onChange={handleModification}></textarea>
                    </label>
                    <div className={contact.alert === '' ? 'alert_bloc_unactive' : 'alert_bloc'}>
                        <p>
                            {contact.alert}
                        </p>
                    </div>
                    <button onClick={handleSubmit}>Envoyer</button>
                </form>
                <div className={contact.status === 1 ? "success_message" : "success_message_inactive"}>
                    <p>
                        {contact.alert}
                    </p>
                </div>
            </div>
            <div className='footer_bloc'>
                <p>
                    Bluestock - 2024 - Tous droits réservés - Mentions légales
                </p>
            </div>
        </div>
    );
};

export default Fournisseur;