import './Appli.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Caisse from './Caisse/Caisse';
import Client from './Client/Client';
import Facturation from './Facturation/Facturation';
import Stock from './Stock/Stock';
import Technic from './Technic/Technic';
import Parametre from './Parametre/Parametre';
import { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Profil from './Profil/Profil';
import NotificationPanel from './NotificationPanel/NotificationPanel';
import { useAuth, AuthContext } from '../AuthProvider/AuthProvider';
import { jwtDecode } from 'jwt-decode';
import { People, CalendarDate, Gear, Shop, CashStack, FileEarmarkPlus, ArrowLeftCircleFill, Bell, ArrowRightCircleFill, DoorOpenFill, Cake, Cake2, PersonArmsUp, Check, InfoCircle, InfoCircleFill, X, ArrowLeft, ArrowRight, ListTask, Grid} from 'react-bootstrap-icons';
import CartSlide from './CartSlide/CartSlide';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../OnePage/SubscriptionForm';
import Stripe from 'stripe';
import { loadStripe } from '@stripe/stripe-js';

function Appli({userStatus}) {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const { currentUser } = useContext(AuthContext);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    //IDEA : Système d'actualisation des données (Vérifier si les données sont à jour, sinon les actualiser)
    //IDEA : Raccourcis clavier pour les actions les plus courantes



    const [helper, setHelper] = useState({
        active: false,
        step: 0,
        text: 'Bonjour ! Je suis la pour vous aider',
        steps : [],
        top: 20,
        left: 60
    });
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState({
        id : 0,
        name: '',
        surname : '',
        profile_active : 0,
        birth : '',
        image : 'jeremy',
        sexe : '',
        phone : '',
        mail : '',
        status : 0,
        contact_status : 0,
        enterprise : {
            id : 0,
            name : '',
            address : '',
            address_bis : '',
            zip : '',
            city: '',
            mail : '',
            phone: ''
        },
        abonnements : [],
        abonnement_alert_status : true,
        abonnement_alert_step: 0,
        abonnement_choice : {
            price_id : null,
            users : 0,
            isLoading: false
        },
        authorization : [],
        stripeOptions : {
            clientSecret: '',
            customer: '' 
        }
    })
    const [menu, setMenu] = useState({
        location : location.pathname,
        toggle : 1
    })
    const [notif, setNotif] = useState({
        status : 0,
        notif_nbr : 0,
        notifs : []
    })
    const [toasts, setToasts] = useState([]);
    const [shop, setShop] = useState({
        cart_toggle : 0,
        active_commande : 0,
        date : '00-00-0000',
        cart : [], // Commande visible
        message : '',
        cart_alert : '',
        commandes : [],
        all_commandes : [],
        totalArticles : 0,
        total : 0
    })
    const [firstConnec, setFirstConnec] = useState(true);

    // IDEA : Ajouter les réglages du shop dans un cookie pour les conserver

    const keyPress = (e) => {
        //Raccourcis clavier pour chaque action
        //Associer Ctrl + touche

        if (e.ctrlKey && e.key === 'c') {
            cartToggle();
        } else if (e.ctrlKey && e.key === 's') {
            saveShop();
        } else if (e.ctrlKey && e.key === 'd') {
            deleteCommande();
        } else if (e.ctrlKey && e.key === 'v') {
            validateCommande();
        } else if (e.ctrlKey && e.key === 'p') {
            profilSwitch();
        } else if (e.ctrlKey && e.key === 'm') {
            menuToggle();
        }
    };
    const loadShopData = async () => {
        try {
          const shopData = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/shopData",
            {
              user_id : user.id,
              enterprise_id : user.enterprise.id
            }
          );
          if (shopData.data.success) {
            let total = shop.total; // utiliser la valeur actuelle de shop.total
            console.log('Shop Data Total : ' + total);
            if (shopData.data.cart && shopData.data.cart.length > 0) {
              total = shopData.data.cart.reduce((acc, item) => acc + (item.prix_ttc * item.exCart), 0); // utiliser la propriété prix_ttc à la place de price
            }
            console.log('Shop Data Total Bis : ' + total);
            setShop({
              ...shop,
              date : shopData.data.date || '00-00-0000',
              cart : shopData.data.cart,
              commandes : shopData.data.commandes,
              all_commandes : shopData.data.all_commandes,
              total : total, // utiliser la nouvelle valeur de total
              active_commande : shopData.data.active_commande,
              totalArticles : shopData.data.totalArticles
            });
          } else {
            console.log('Erreur de chargement des données utilisateur');
          }
        } catch (error) {
          console.log(error);
        }
    };  
    const profilSwitch = () => {
        setUser({
            ...user,
            profile_active : user.profile_active === 0 ? 1 : 0
        })
    };
    const userLoad = async () => {
        console.log('Chargement des données utilisateur');
        try {
            const token = localStorage.getItem('session_token');
            const cookie = document.cookie.split('; ').find(row => row.startsWith('session_token='))?.split('=')[1];
    
            if (!token && cookie) {
                localStorage.setItem('session_token', cookie);
            }
    
            console.log('Token :', token);
            console.log('Cookie :', cookie);
    
            // Vérifier si le token existe et s'il n'est pas vide
            if (token) {
                const decoded = jwtDecode(token);
    
                console.log('Token décodé :', decoded);
    
                const userData = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/user",
                    {
                        user_id : decoded.userId
                    }
                );
                if (userData.data.success) {
                    console.log(userData.data.user);
                    setUser({
                        ...user,
                        id : userData.data.user.id,
                        name: userData.data.user.nom,
                        surname : userData.data.user.prenom,
                        birth : userData.data.user.birth_date,
                        image : userData.data.user.photo,
                        sexe : userData.data.user.sexe,
                        phone : userData.data.user.phone,
                        mail : userData.data.user.mail,
                        role : userData.data.user.role,
                        status : userData.data.user.status,
                        contact_status : userData.data.user.status,
                        first_connexion : userData.data.user.first_connexion,
                        enterprise : {
                            ...user.enterprise,
                            id : userData.data.user.enterprise,
                            name : userData.data.user.entreprise_nom,
                            address : userData.data.user.entreprise_address,
                            address_bis : userData.data.user.entreprise_address_bis,
                            zip : userData.data.user.entreprise_zip,
                            city: userData.data.user.entreprise_city,
                            siren : userData.data.user.entreprise_siren,
                            siret : userData.data.user.entreprise_siret,
                            mail : userData.data.user.entreprise_mail,
                            phone: userData.data.user.entreprise_phone,
                            creation_date : userData.data.user.entreprise_creation_date,
                            status : userData.data.user.entreprise_status
                        },
                        authorization : [],
                        abonnements : userData.data.abonnements,
                        abonnement_alert_status : true
                    });
                    console.log('%cDonnées utilisateur chargées', 'color: green');
                    console.log(userData.data.user);
                } else {
                    console.log('Erreur de chargement des données utilisateur');
                    deconnect();
                }
            } else {
                console.log('Aucun token trouvé');
            }
        } catch (error) {
            console.log(error);
        }
    };    
    const notifToggle = () => {
        setNotif({
            ...notif,
            status : notif.status === 0 ? 1 : 0
        })
    };
    const notifActualise = async () => {
        try {
            const token = localStorage.getItem('session_token');
            const decoded = jwtDecode(token);

            const notifData = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/notifData",
                {
                    user_id : decoded.userId,
                    enterprise_id : user.enterprise.id
                }
            );
            if (notifData.data.success) {
                if(notifData.data.notif_nbr !== notif.notif_nbr){
                    setNotif({
                        ...notif,
                        notif_nbr : notifData.data.notif_nbr,
                        notifs : notifData.data.notifs
                    });
                }
            } else {
                console.log('Erreur de chargement des notifications');
            }
        } catch (error) {
            console.log(error);
        }
    };
    const loadRoleAuthorization = async () => {
        console.log('Chargement des autorisations');
        try {
            const token = localStorage.getItem('session_token');
            const decoded = jwtDecode(token);

            console.log('Token décodé :', decoded);

            const roleData = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/roleAuthorization",
                {
                    user_id : decoded.userId,
                    enterprise_id : user.enterprise.id
                }
            );
            if (roleData.data.success) {
                console.log('Auth CheckList : ', roleData.data.authorization);
                setUser({
                    ...user,
                    authorization : JSON.parse(roleData.data.authorization)
                });
            } else {
                console.log('Erreur de chargement des autorisations');
            }
        } catch (error) {
            console.log(error);
        }
    };
    const tryAuthorization = (id) => {
        if(user.authorization === undefined) {
            return false;
        } else if(user.authorization.find((object) => object.id === id) === undefined) {
            return false;
        } else {
            const authorization = user.authorization.find((object) => object.id === id);

            if(authorization && authorization.status === 1) {
                return true;
            } else {
                return false;
            }
        }
    }
    const popToast = async (message, emergency = 0) => {
        const newToastId = uuidv4();
        const apparitionTime = 3500;
      
        setToasts((toasts) => [
          ...toasts,
          {
            id: newToastId,
            message: message,
            emergency: emergency,
            time: new Date().getTime(),
          }
        ]);
      
        if (emergency === 4) {
          try {
            const token = localStorage.getItem('session_token');
            const decoded = jwtDecode(token);
      
            const notifData = await axios.post(
              process.env.REACT_APP_SERVER_URL + "/api/notifAdd",
              {
                user_id: decoded.userId,
                enterprise_id: user.enterprise.id,
                title: 'Notification',
                content: message,
                emergency: emergency,
                type: 0,
                action: 'empty',
                status: 'active'
              }
            );
            if (notifData.data.success) {
              console.log(notifData.data.message);
              notifActualise();
            } else {
              console.log('Erreur de sauvegarde des notifications');
            }
          } catch (error) {
            console.log(error);
          }
        }
    };
    const closeToast = async (toast_id) => {
        console.log('closeToast');
      
        const toastIndex = toasts.findIndex((toast) => toast.id === toast_id);
        if (toastIndex === -1) {
          console.error(`Erreur : la notification d'ID ${toast_id} n'existe pas`);
          return;
        }
      
        if (toasts[toastIndex].emergency === 4) {
          try {
            const token = localStorage.getItem('session_token');
            const decoded = jwtDecode(token);
      
            const notifData = await axios.post(
              process.env.REACT_APP_SERVER_URL + "/api/notifSatus",
              {
                user_id: decoded.userId,
                enterprise_id: user.enterprise.id,
                notif_id: toast_id,
                status: 'closed'
              }
            );
            if (notifData.data.success) {
              console.log(notifData.data.message);
              notifActualise();
            } else {
              console.log('Erreur de sauvegarde des notifications');
            }
          } catch (error) {
            console.log(error);
          }
        }
      
        console.log(toast_id);
        setToasts((toasts) => toasts.filter((toast) => toast.id !== toast_id));
    };
    async function deconnect() {
        logout();
        navigate('/', { replace: true });
    };
    const menuToggle = () => {
        setMenu({
            ...menu,
            toggle : menu.toggle === 0 ? 1 : 0
        })
    }
    const removeFromCart = (item) => {
        let new_id = shop.active_commande;
        let commandes = shop.commandes;
        let index = commandes.findIndex((commande) => commande.id === shop.active_commande);
      
        if (index !== undefined && index !== -1) {
          // Trouver l'index de l'article dans la commande
          const articleIndex = commandes[index].articles.findIndex((article) => article.id === item.id);
      
          if (articleIndex !== -1) {
            // Si la quantité de l'article est déjà à 1, supprimer complètement l'article de la commande
            if (commandes[index].articles[articleIndex].exCart === 1) {
                //Si il n'y a qu'un article dans la commande, supprimer la commande
                if (commandes[index].articles.length === 1) {
                    commandes = shop.commandes.filter((commande) => commande.id !== commandes[index].id);
                } else {
                    commandes = shop.commandes.map((commande) =>
                    commande.id === shop.active_commande
                    ? {
                        ...commande,
                        articles: commande.articles.filter((article) => article.id !== item.id),
                    }
                    : commande
                    );
                }
            } else {
              // Sinon, décrémenter la quantité de l'article dans la commande
              commandes = shop.commandes.map((commande) =>
                commande.id === shop.active_commande
                  ? {
                      ...commande,
                      articles: commande.articles.map((article, i) =>
                        i === articleIndex ? { ...article, exCart: article.exCart - 1 } : article
                      ),
                    }
                  : commande
              );
            }
          }
        } else if (shop.date !== '00-00-0000' && shop.active_commande === 0) {
          new_id = uuidv4();
      
          commandes = shop.commandes.concat({
            id: new_id,
            valid_date: shop.date,
            total: shop.total - (item.prix_ttc * item.exCart),
            open: 0,
            payment_method: null,
            payment_status: 0,
            payment_date: null,
            status: 0,
            status_name: 'En cours',
            articles: shop.cart.filter((object) => object.id !== item.id),
          });
        }
      
        setShop({
          ...shop,
          cart: shop.cart.filter((object) => object.id !== item.id),
          total: shop.total - (item.prix_ttc * item.exCart),
          commandes: commandes,
          active_commande: new_id,
        });
    };       
    const cartToggle = () => {
        setShop({
            ...shop,
            cart_toggle : shop.cart_toggle === 0 ? 1 : 0
        })
    }
    const lessItem = (item_id) => {
        const item = shop.cart.find((object) => object.id === item_id);
        if (!item) {
          console.error(`Erreur : l'article d'ID ${item_id} n'existe pas dans le panier`);
          return;
        }
      
        if (item.exCart === 1) {
          removeFromCart(item);
        } else {
          const newCart = shop.cart.map((object) =>
            object.id === item_id ? { ...object, exCart: object.exCart - 1 } : object
          );
          const newTotal = newCart.reduce((total, object) => total + object.prix_ttc * object.exCart, 0);
          let new_id = shop.active_commande;
          let newCommandes = shop.commandes;
      
          if (shop.date !== '00-00-0000' && shop.active_commande !== 0) {
            newCommandes = shop.commandes.map((object) =>
              object.id === shop.active_commande
                ? {
                    ...object,
                    articles: object.articles.map((article) =>
                      article.id === item_id ? { ...article, exCart: article.exCart - 1 } : article
                    ),
                  }
                : object
            );
          } else if (shop.date !== '00-00-0000' && shop.active_commande === 0) {
            new_id = uuidv4();
            newCommandes = shop.commandes.concat({
              id: new_id,
              valid_date: shop.date,
              total: newTotal,
              open: 0,
              payment_method: null,
              payment_status: 0,
              payment_date: null,
              status: 0,
              status_name: 'En cours',
              articles: newCart,
            });
          }
      
          setShop({
            ...shop,
            cart: newCart,
            total: newTotal,
            commandes: newCommandes,
            active_commande: new_id,
          });
        }
    };  
    const moreItem = (item_id) => {
        const item = shop.cart.find((object) => object.id === item_id);
        if (!item) {
          console.error(`Erreur : l'article d'ID ${item_id} n'existe pas dans le panier`);
          return;
        }
      
        const newCart = shop.cart.map((object) =>
          object.id === item_id ? { ...object, exCart: object.exCart + 1 } : object
        );
        const newTotal = newCart.reduce((total, object) => total + object.prix_ttc * object.exCart, 0);
        let new_id = shop.active_commande;
        let newCommandes = shop.commandes;
      
        if (shop.date !== '00-00-0000' && shop.active_commande !== 0) {
          newCommandes = shop.commandes.map((object) =>
            object.id === shop.active_commande
              ? {
                  ...object,
                  articles: object.articles.map((article) =>
                    article.id === item_id ? { ...article, exCart: article.exCart + 1 } : article
                  ),
                }
              : object
          );
        } else if (shop.date !== '00-00-0000' && shop.active_commande === 0) {
          new_id = uuidv4();
          newCommandes = shop.commandes.concat({
            id: new_id,
            valid_date: shop.date,
            total: newTotal,
            open: 0,
            payment_method: null,
            payment_status: 0,
            payment_date: null,
            status: 0,
            status_name: 'En cours',
            articles: newCart,
          });
        }
      
        setShop({
          ...shop,
          cart: newCart,
          total: newTotal,
          commandes: newCommandes,
          active_commande: new_id,
        });
    }; 
    const changeItemNumber = (e, item_id) => {
        const newNumber = parseFloat(e.target.value);
        const oldNumber = shop.cart.find((object) => object.id === item_id).exCart;
      
        if (newNumber === 0 || newNumber === '' || isNaN(newNumber)) {
          removeFromCart(shop.cart.find((object) => object.id === item_id));
        } else if (newNumber !== oldNumber) {
          const articles = shop.cart.map((object) =>
            object.id === item_id ? { ...object, exCart: newNumber } : object
          );
          const total = articles.reduce((total, object) => total + object.prix_ttc * object.exCart, 0);
          let commandes = shop.commandes;
          let new_id = shop.active_commande;
      
          if (shop.date !== '00-00-0000') {
            if (shop.active_commande !== 0) {
              commandes = shop.commandes.map((object) =>
                object.id === shop.active_commande
                  ? {
                      ...object,
                      articles: object.articles.map((article) =>
                        article.id === item_id ? { ...article, exCart: newNumber } : article
                      ),
                    }
                  : object
              );
            } else {
              new_id = uuidv4();
              commandes = shop.commandes.concat({
                id: new_id,
                valid_date: shop.date,
                total: total,
                open: 0,
                payment_method: null,
                payment_status: 0,
                payment_date: null,
                status: 0,
                status_name: 'En cours',
                articles: articles,
              });
            }
          }
      
          setShop({
            ...shop,
            cart: articles,
            total: total,
            commandes: commandes,
            active_commande: new_id,
          });
        }
    }; 
    const modifyShopCart = (newCart) => {
        console.log('modifyShopCart');
        const total = newCart.reduce((total, object) => total + (object.prix_ttc * object.exCart), 0);
        let commandes = shop.commandes;
        let new_id = shop.active_commande;
        if(newCart.date !== '00-00-0000' && shop.active_commande !== 0) {
            commandes = shop.commandes.map((object) => object.id === shop.active_commande ? {...object, articles : newCart} : object);
        } else if (shop.date !== '00-00-0000' && 
        shop.active_commande === 0) {
            new_id = uuidv4();
            commandes = shop.commandes.concat({
                id : new_id,
                valid_date : newCart.date,
                total : total,
                open : 0,
                payment_method : null,
                payment_status : 0,
                payment_date : null,
                status : 0,
                status_name : 'En cours',
                articles : newCart
            });
        }
        setShop({
            ...shop,
            cart : newCart,
            total : total,
            commandes : commandes,
            active_commande : new_id
        })
    };
    const openCommandeToggle = (commande_id) => {
        setShop({
            ...shop,
            commandes : shop.commandes.map((object) => object.id === commande_id ? {...object, open : object.open === 0 ? 1 : 0} : object)
        })
    };
    const selectCommande = (commande_id) => {
        const total = shop.commandes.find((object) => object.id === commande_id).articles.reduce((total, object) => total + (object.prix_ttc * object.exCart), 0);

        setShop({
            ...shop,
            cart : shop.commandes.find((object) => object.id === commande_id).articles,
            total: total,
            active_commande : commande_id,
            date : shop.commandes.find((object) => object.id === commande_id).valid_date || '00-00-0000',
            totalArticles : shop.commandes.find((object) => object.id === commande_id).articles.length,
            name : shop.commandes.find((object) => object.id === commande_id).name
        })
    };
    const validateCommande = () => {
        setShop({
            ...shop,
            total : 0,
            cart: [],
            active_commande: 0,
            date: '00-00-0000'
        })
    };
    const saveShop = async () => {
        try {
            const token = localStorage.getItem('session_token');
            const decoded = jwtDecode(token);
            
            const shopData = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/shopSave",
                {
                    user_id : decoded.userId,
                    enterprise_id : user.enterprise.id,
                    shop_cart : shop.cart,
                    shop_commandes : shop.commandes,
                    active_commande : shop.active_commande
                }
            );
            
            if (shopData.data.success) {
                console.log(shopData.data.message);
                
                if(shopData.data.isDifferent) {
                    // Certaines dates de commandes ont été modifiées, on actualise les données sans loadShopData
                    setShop({
                        ...shop,
                        commandes : shopData.data.comandes
                    });
                }
            } else {
                console.log('Erreur de sauvegarde des données utilisateur');
            }
        } catch (error) {
            console.log(error);
        }
    };
    const changeCommandeDate = (e) => {
        const date = new Date(e.target.value);
        console.log(date.toString());
        if (date.toString() === 'Invalid Date' || date < new Date()) {
            // Vérifier que la date n'est pas égale à la date d'une autre commande
            console.log('Date invalide');
            setShop({
                ...shop,
                date : e.target.value || '00-00-0000',
                cart_alert : 'La date de la commande doit être supérieure à la date actuelle'
            })
        } else if (shop.commandes.find((object) => new Date(object.valid_date).toLocaleDateString('fr-FR') === new Date(e.target.value).toLocaleDateString('fr-FR') && object.id !== shop.active_commande)) {
            console.log('Date identique à une autre commande');
            setShop({
                ...shop,
                date : e.target.value || '00-00-0000',
                cart_alert : 'Une commande est déjà programmée à cette date'
            })
        } else {
            console.log('Valide');
            setShop({
                ...shop,
                date: e.target.value || '00-00-0000',
                commandes: shop.commandes.map((object) =>
                    object.id === shop.active_commande
                    ? { ...object, valid_date: e.target.value }
                    : object
                ),
                cart_alert: ''
            });
        }
    };
    const autoCommandeBuilder = (article) => {
        console.log('autoCommandeBuilder');
        // La fonction ajoute des articles à la commande mensuelle si elle existe, sinon elle la crée
        
        const last_day_of_mont = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
        const month_command = shop.commandes.find((object) => object.valid_date === `${last_day_of_mont}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`);
        var new_command;

        if (month_command) {
            console.log('La commande existe');
            // La commande existe, on ajoute l'article
            //On vérifie que l'article n'est pas déjà dans une commande en cours (dont la date de validité est supérieure ou égale à la date actuelle et inférieure ou égale à la date de fin du mois actuel)
            
            if(month_command.articles.find((object) => object.id === article.id)) {
                // L'article est déjà dans une commande en cours
                console.log('L\'article est déjà dans une commande en cours');
                return;
            } else {
                // L'article n'est pas dans une commande en cours, on l'ajoute
                new_command = shop.commandes.map((object) => object.id === month_command.id ? {...object, articles : object.articles.concat(article)} : object);
            }
        } else {
            console.log('La commande n\'existe pas');
            // La commande n'existe pas, on la crée
            //On vérifie que l'article n'est pas déjà dans une commande en cours (dont la date de validité est supérieure à la date actuelle et inférieure à la date de fin du mois actuel)

            if(shop.commandes.find((object) => object.valid_date > `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}` && object.valid_date < `${last_day_of_mont}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`)) {
                // L'article est déjà dans une commande en cours
                console.log('L\'article est déjà dans une commande en cours');
                return;
            } else {
                console.log('L\'article n\'est pas dans une commande en cours');
                // L'article n'est pas dans une commande en cours, on la crée
                let new_command_month = new Date();
                new_command_month.setMonth(new_command_month.getMonth() + 1);

                new_command = shop.commandes.concat({
                    id : uuidv4(),
                    valid_date : `${last_day_of_mont}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`,
                    total : article.prix_ttc,
                    open : 0,
                    name : 'Commande du mois - ' + new_command_month.toLocaleString('fr-FR', { month: 'long' }) + ' ' + new Date().getFullYear(),
                    payment_method : null,
                    payment_status : 0,
                    payment_date : null,
                    status : 0,
                    status_name : 'En cours',
                    articles : [article]
                });
            }
        }

        setShop({
            ...shop,
            commandes : new_command
        })

        //IDEA : perfectionné l'algorythme pour les commandes mensuelles
    };
    const deleteCommande = () => {
        let commandes = shop.commandes.filter((commande) => commande.id !== shop.active_commande);
        setShop({
            ...shop,
            commandes : commandes,
            cart : [],
            total : 0,
            active_commande : 0,
            date : '00-00-0000'
        });
        console.log('DELETE COMMANDE');
    };
    const moveArticle = (article_id, start_commande_id, end_commande_id) => {
        // Controler que start_commande_id et end_commande_id sont différents
        if (start_commande_id === end_commande_id) {
            console.error('Erreur : Les commandes de départ et d\'arrivée sont identiques');
            return;
        } else if (shop.commandes.find((object) => object.id === start_commande_id) === undefined || shop.commandes.find((object) => object.id === end_commande_id) === undefined) {
            console.error('Erreur : Les commandes de départ et d\'arrivée n\'existent pas');
            return;
        }
        // Controler que l'article existe dans la commande de départ
        if (shop.commandes.find((object) => object.id === start_commande_id).articles.find((object) => object.id === article_id) === undefined) {
            console.error('Erreur : L\'article n\'existe pas dans la commande de départ');
            return;
        }
        // Controler que l'article n'existe pas dans la commande d'arrivée
        if (shop.commandes.find((object) => object.id === end_commande_id).articles.find((object) => object.id === article_id) !== undefined) {
            console.error('Erreur : L\'article existe déjà dans la commande d\'arrivée');
            return;
        }
        
        // Déplacer l'article de la commande de départ à la commande d'arrivée

        const start_commande = shop.commandes.find((object) => object.id === start_commande_id);
        const end_commande = shop.commandes.find((object) => object.id === end_commande_id);
        const article = start_commande.articles.find((object) => object.id === article_id);
        const new_start_commande = start_commande.articles.filter((object) => object.id !== article_id);
        const new_end_commande = end_commande.articles.concat(article);
        let new_commandes = shop.commandes.map((object) => object.id === start_commande_id ? {...object, articles : new_start_commande} : object).map((object) => object.id === end_commande_id ? {...object, articles : new_end_commande} : object);

        // Vériier que la commande si la commande de départ est vide, la supprimer
        if (new_start_commande.length === 0) {
            new_commandes = new_commandes.filter((object) => object.id !== start_commande_id);
        }

        // Modifier le cart si la commande active est la commande de fin ou de départ
        if (shop.active_commande === start_commande_id) {
            setShop({
                ...shop,
                cart : new_start_commande,
                commandes : new_commandes
            })
        } else if (shop.active_commande === end_commande_id) {
            setShop({
                ...shop,
                cart : new_end_commande,
                commandes : new_commandes
            })
        } else {
            setShop({
                ...shop,
                commandes : new_commandes
            })
        }
    };
    const switchAbonnementChoice = (abonnement_id) => {
        console.log('switchAbonnementChoice');
        setUser({
            ...user,
            abonnement_choice : {
                ...user.abonnement_choice,
                price_id : abonnement_id
            }
        });
    };
    const changeAbonnementsUsers = (e) => {
        console.log('changeAbonnementsUsers');
        setUser({
            ...user,
            abonnement_choice : {
                ...user.abonnement_choice,
                users : e.target.value
            }
        });
    };
    const userAbonnementChoice = async () => {
        console.log('userAbonnementChoice');
        try {
            const token = localStorage.getItem('session_token');
            const decoded = jwtDecode(token);

            const abonnementData = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/stripe-client-secret",
                {
                    user_id : decoded.userId,
                    enterprise : {
                        id : user.enterprise.id,
                        name : user.enterprise.name,
                        address : {
                            line1 : user.enterprise.address,
                            line2 : user.enterprise.address_bis,
                            country : 'FR',
                            city : user.enterprise.city,
                            state : '',
                            postal_code : user.enterprise.zip
                        }
                    },
                    priceId : user.abonnement_choice.price_id,
                    users : user.abonnement_choice.users
                }
            );
            if (abonnementData.data.success) {
                console.log(abonnementData.data.message);
                setUser({
                    ...user,
                    abonnement_alert_status : true,
                    abonnement_alert_step : 1,
                    stripeOptions : {
                        ...user.stripeOptions,
                        clientSecret: abonnementData.data.clientSecret,
                        customer: abonnementData.data.customer
                    }
                });
            } else {
                console.log('Erreur de sauvegarde de l\'abonnement');
            }
        } catch (error) {
            console.log(error);
        }
    };
    const userAbonnementAlertClose = () => {
        setUser({
            ...user,
            abonnement_alert_status : false
        });
    };
    const helpStart = () => {
        // Afficher le module d'aide
        let steps = [];
        if(location.pathname === '/app/facture') {
            steps = [
                {
                    target: '.menuLineActive',
                    content: 'Bienvenue sur la page de facturation. Cette page vous permet de créer des factures, des devis et des avoirs.',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.menuLineActive',
                    content: 'Vous pouvez voir que la page est active ici',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.NewFactureButton',
                    content : 'Pour créer une pièce (facture / devis), cliquez sur le bouton "Nouvelle Pièce"',
                    placement: 'top',
                    disableBeacon: true
                },
                {
                    target: '.FacturationList',
                    content : 'Vous pouvez voir la liste des pièces créées ici',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.FacturationList',
                    content : 'Vous pouvez voir le détail de la pièce en cliquant dessus',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.FacturationList',
                    content : 'Vous pouvez modifier la pièce en cliquant sur le bouton "Modifier"',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '#FactureDevisChoice',
                    content : 'Vous pouvez changer le type de pièce ici',
                    placement: 'bottom',
                    disableBeacon: true
                },
                {
                    target: '.FactureDateChoice',
                    content : 'Vous pouvez rechercher une pièce par date ici',
                    placement: 'bottom',
                    disableBeacon: true
                },
                {
                    target: '.FactureSearch',
                    content : 'Vous pouvez rechercher une pièce par nom ici',
                    placement: 'bottom',
                    disableBeacon: true
                },
                {
                    target: '.facturationVisionZone',
                    content : 'Vous pouvez avoir un apperçu de la pièce ici',
                    placement: 'left',
                    disableBeacon: true
                },
                {
                    target: '.facturationVisionZone',
                    content : 'Vous pouvez imprimer la pièce en survolant l\'aperçu et en cliquant sur le bouton "Imprimer"',
                    placement: 'left',
                    disableBeacon: true
                },
                {
                    target: '.facturationVisionZone',
                    content : 'Vous pouvez envoyer la pièce par mail en survolant l\'aperçu et en cliquant sur le bouton "Envoyer par mail"',
                    placement: 'left',
                    disableBeacon: true
                },
                {
                    target: '.ParameterButton',
                    content : 'Vous pouvez paramétrer vos pièces en cliquant sur le bouton "Paramètres"',
                    placement: 'top',
                    disableBeacon: true
                }
            ];
        } else if (location.pathname === '/app/caisse') {
            steps = [
                {
                    target: '.menuLineActive',
                    content: 'Bienvenue sur la page de caisse. Cette page vous permet de gérer votre caisse et vos transactions.',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.CaisseEnregistreusePad',
                    content: 'Le pad vous permet de facturer comme une caisse enregistreuse classique',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.CaisseEnregistreusePad',
                    content: 'Pour enregistrez un paiement, saisissez le montant et appuyé sur le type de paiement, ou appuyé sur le type de paiement directement si celui-ci correspond à la totalité de la facture',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.CaisseEnregistreusePad',
                    content: 'Attention, une fois un paiement ajouter à la caisse, il n\'est pas possible de le supprimer',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.CaisseEnregistreuseCategoriesZone',
                    content: 'Les catégories vous permettent de filtrer les produits',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.CaisseEnregistreuseCategoriesZone',
                    content: 'Pour ajouter un produit à la facture, cliquez simplement sur le produit',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.CaisseEnregistreuseCategoriesZone',
                    content: 'La barre de recherche vous permet de rechercher un produit',
                    placement: 'left',
                    disableBeacon: true
                },
                {
                    target: '.CaisseArticleListZone',
                    content: "Vous pouvez modifier le nom, la quantité et le prix d'un article ici",
                    placement: 'left',
                    disableBeacon: true
                },
                {
                    target: '.CaisseRechercheClient',
                    content: 'Vous pouvez rechercher un client en tapant son nom ou prénom ici',
                    placement: 'left',
                    disableBeacon: true
                },
                {
                    target: '.CaisseRechercheClient',
                    content: 'Vous pouvez également créer un client en cliquant sur le bouton "Créer un client"',
                    placement: 'left',
                    disableBeacon: true
                }
            ];
        } else if (location.pathname === '/app/client') {
            steps = [
                {
                    target: '.menuLineActive',
                    content: 'Bienvenue sur la page clients. Cette page vous permet de gérer vos clients.',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.ClientSearch',
                    content: 'La barre de recherche vous permet de rechercher un client par nom ou prénom',
                    placement: 'bottom',
                    disableBeacon: true
                },
                {
                    target: '.ClientInfosPersoLeft',
                    content: 'Vous pouvez voir les informations de base du client ici',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.clientInfoModifButton',
                    content: 'Pour modifier ces informations, cliquez sur le bouton "Modifier informations client"',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.ClientInfosBassin',
                    content: 'Vous pouvez voir les bassins du client ici',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.ClientInfosBassin',
                    content: 'Pour ajouter un bassin, il faut en facturer un, un bouton "Ajouter un bassin" est à venir',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.ClientShop',
                    content: 'Vous pouvez voir les factures et devis du client ici',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.ClientTechnic',
                    content: 'Vous pouvez voir les interventions techniques du client ici',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.ClientList',
                    content: 'Ici, retrouvez la liste de vos client par ordre de création',
                    placement: 'right',
                    disableBeacon: true
                }
            ];
        } else if (location.pathname === '/app/technic') {
            steps = [
                {
                    target: '.menuLineActive',
                    content: 'Bienvenue sur la page technique. Cette page vous permet de gérer vos interventions techniques.',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.TechnicCalendar',
                    content: 'Le calendrier vous permet de voir les interventions techniques chaque jour',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.TechnicUsers',
                    content: 'Vous pouvez voir les techniciens et leurs taches ici',
                    placement: 'left',
                    disableBeacon: true
                },
                {
                    target: '.TechnicUsers',
                    content: 'Pour ajouter une tache, cliquez sur le bouton "Nouvelle tache"',
                    placement: 'left',
                    disableBeacon: true
                }
            ];
        } else if (location.pathname === '/app/stock') {
            steps = [
                {
                    target: '.menuLineActive',
                    content: <>Bienvenue sur la page stock. Cette page vous permet de gérer votre stock.</>,
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.StockSearch',
                    content: <>La barre de recherche vous permet de rechercher un produit par nom</>,
                    placement: 'bottom',
                    disableBeacon: true
                },
                {
                    target: '.StockList',
                    content: <>Vous pouvez voir la liste des catégories et produits ici</>,
                    placement: 'left',
                    disableBeacon: true
                },
                {
                    target : '.StockList',
                    content : <>Pour modifier un produit, cliquez dessus</>,
                    placement : 'left',
                    disableBeacon : true
                },
                {
                    target : '.StockList',
                    content : <>Vous pouvez modifier l'affichage des produits en cliquant sur les boutons <ListTask size={16}/> et <Grid size={16}/></>,
                    placement : 'left',
                    disableBeacon : true
                }
            ];
        } else if (location.pathname === '/app/prestataire') {
            steps = []
        } else if (location.pathname === '/app/parametre') {
            steps = [
                {
                    target: '.menuLineActive',
                    content: 'Bienvenue sur la page paramètres. Cette page vous permet de gérer vos paramètres.',
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.menuLineActive',
                    content: "Ici vous retrouvez l'ensemble des informations sur votre entreprise",
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.menuLineActive',
                    content: "C'est ici que vous créer un utilisateur et sélectionner son rôle",
                    placement: 'right',
                    disableBeacon: true
                },
                {
                    target: '.menuLineActive',
                    content: "Vous pouvez modifier les rôles en leur attribuant les autorisations souhaitées",
                    placement: 'right',
                    disableBeacon: true
                }
            ]

        } else if (location.pathname === '/app/abonnement') {
            steps = []
        } else {
            steps = []
        }
        setHelper({
            ...helper,
            active: true,
            step: 0,
            steps : steps
        });
    }
    const readStep = (step_id) => {
        // On place notre bull en fonction de l'étape
        const step = helper.steps[step_id];
        if(!step) { 
            setHelper({
                ...helper,
                active: false
            })
            return; 
        }
        let top = 0;
        let left = 0;
        let translate = 'translate(-50%, -50%)';

        console.log("TARGET :: ",step.target)

        if (step.placement === 'top') {
            console.log('top')
            top = document.querySelector(step.target).getBoundingClientRect().top;
            left = document.querySelector(step.target).getBoundingClientRect().left;
            top -= 20;
            translate = 'translate(0%, -100%)';
        } else if (step.placement === 'left') {
            console.log('left')
            top = document.querySelector(step.target).getBoundingClientRect().top;
            left = document.querySelector(step.target).getBoundingClientRect().left;
            left -= 20;
            translate = 'translate(-100%, 0%)';
        } else if (step.placement === 'bottom') {
            console.log('bottom');
            top = document.querySelector(step.target).getBoundingClientRect().bottom;
            left = document.querySelector(step.target).getBoundingClientRect().left;
            top += 20;
            translate = 'translate(0%, 0%)';
        } else if (step.placement === 'right') {
            console.log('right');
            top = document.querySelector(step.target).getBoundingClientRect().top;
            left = document.querySelector(step.target).getBoundingClientRect().right;
            left += 20;
            translate = 'translate(0, 0%)';
        }

        setHelper({
            ...helper,
            step: step_id,
            text : step.content,
            top: top,
            left: left,
            translate : translate
        });      
    } 
        
    //IDEA : Fonction pré-commande de Novembre
    useEffect(() => {
        if(helper.active) {
            readStep(0);
        }
    }, [helper.active]);
    useEffect(() => {
        console.log('toast_closer');
        const lastToastId = toasts[toasts.length - 1]?.id;
        setTimeout(() => {
          closeToast(lastToastId);
        }, 3500);
    }, [toasts]);
    useEffect(() => {
        console.log('Application started');
        userLoad();
        notifActualise();
    }, []);
    useEffect(() => {
        if(user.id !== 0) {
            console.log('shop updated', user);
            loadShopData();
            loadRoleAuthorization();
            setIsLoading(false);
        }
    }, [user.id]);
    useEffect(() => {
        console.log('SAVE SHOP');
        saveShop();
    }, [shop.commandes]);
    useEffect(() => {
        console.log('Commande active changée');

        if (shop.active_commande !== 0 && shop.commandes.length > 0) {
            setShop({
                ...shop,
                cart : shop.commandes.find((object) => object.id === shop.active_commande).articles || [],
                total : shop.commandes.find((object) => object.id === shop.active_commande).articles.reduce((total, object) => total + (object.prix_ttc * object.exCart), 0) || 0,
                date : shop.commandes.find((object) => object.id === shop.active_commande).valid_date || '00-00-0000',
                totalArticles : shop.commandes.find((object) => object.id === shop.active_commande).articles.length
            })
        } else {
            setShop({
                ...shop,
                cart : [],
                total : 0,
                date : '00-00-0000',
                totalArticles : 0
            })
        }
    }, [shop.total]);
    //Détection de la taille de l'écran
    useEffect(() => {
        setMenu({
            ...menu,
            toggle : window.innerWidth < 768 ? 0 : 1
        })
    }, [window.innerWidth]);
    useEffect(() => {
        console.log('Enterprise_id modifié');
    }, [user.enterprise.id]); 
    useEffect(() => {
        setHelper({
            ...helper,
            active: false,
            step: 0,
            steps : []
        });
    }, [location.pathname]);

    //IDEA [FIN] : Module d'accompagnement à la première utilisation
    // Vérifiez que currentUser est défini avant de rendre le composant
    if (!currentUser) {
        return <div>Chargement...</div>;
    }

    return (
        <div className='Appli' onKeyDown={keyPress}>
            {console.log('abonnements_status ', user.abonnement_alert_status)}
            {console.log('abonnements ', user.abonnements)}
            <Profil user={user} setUser={setUser} tryAuthorization={tryAuthorization} popToast={popToast}></Profil>
            <NotificationPanel notif={notif} notifToggle={notifToggle} tryAuthorization={tryAuthorization}></NotificationPanel>
            <div className={menu.toggle === 1 ? 'AppliMenu' : 'AppliMenu AppliMenuLight'}>
                <div className= 'AppliMenuTop'>
                    <div className={menu.toggle === 1 ? 'AppliLogo' : 'AppliLogo AppliLogoLight'}></div>
                    <nav className={menu.toggle === 1 ?'AppliMenuNav' : 'AppliMenuNav AppliMenuNavLight'}>
                        <ul>
                            {tryAuthorization(66) && <li className={location.pathname === '/app/facture' ? 'menuLineActive' : ''}><span className='menuLineName' onClick={() => navigate('/app/facture')}><FileEarmarkPlus size={16} className='menuLineIcon'></FileEarmarkPlus>{menu.toggle === 1 ? 'Facturation' : ''}</span></li>}
                            {tryAuthorization(7) && <li className={location.pathname === '/app/caisse' ? 'menuLineActive' : ''}><span className='menuLineName' onClick={() => navigate('/app/caisse')}><CashStack size={16} className='menuLineIcon'></CashStack>{menu.toggle === 1 ? 'Caisse' : ''}</span></li>}
                            {tryAuthorization(16) && <li className={location.pathname === '/app/client' ? 'menuLineActive' : ''}><span className='menuLineName' onClick={() => navigate('/app/client')}><People size={16} className='menuLineIcon'></People>{menu.toggle === 1 ? 'Client' : ''}</span></li>}
                            {tryAuthorization(26) && <li className={location.pathname === '/app/technic' ? 'menuLineActive' : ''}><span className='menuLineName' onClick={() => navigate('/app/technic')}><CalendarDate size={16} className='menuLineIcon'></CalendarDate>{menu.toggle === 1 ? 'Planning' : ''}</span></li>}
                            {tryAuthorization(35) && <li className={location.pathname === '/app/stock' ? 'menuLineActive' : ''}><span className='menuLineName' onClick={() => navigate('/app/stock')}><Shop size={16} className='menuLineIcon'></Shop>{menu.toggle === 1 ? 'Inventaire' : ''}</span></li>}
                            {/* <li className={location.pathname === '/app/prestataire' ? 'menuLineActive' : ''}><span className='menuLineName' onClick={() => navigate('/prestataire')}><RocketIcon size={16} className='menuLineIcon'></RocketIcon>{menu.toggle === 1 ? 'Prestataire' : ''}</span></li>
                            <li className={location.pathname === '/app/fournisseur' ? 'menuLineActive' : ''}><span className='menuLineName' onClick={() => navigate('/fournisseur')}><RocketIcon size={16} className='menuLineIcon'></RocketIcon>{menu.toggle === 1 ? 'Fournisseur' : ''}</span></li> */}
                            {tryAuthorization(67) && <li className={location.pathname === '/app/parametre' ? 'menuLineActive' : ''}><span className='menuLineName' onClick={() => navigate('/app/parametre')}><Gear size={16} className='menuLineIcon'></Gear>{menu.toggle === 1 ? 'Paramètre' : ''}</span></li>}
                        </ul>
                    </nav>
                </div>
                <div className={menu.toggle === 1 ? 'AppliMenuBottom' : 'AppliMenuBottom AppliMenuBottomLight'}>
                    {/* <div className={menu.toggle === 1 ? 'MenuNotifications' : 'MenuNotifications MenuNotificationsLight'} onClick={notifToggle}>
                    {menu.toggle === 1 ? 'Notifications' : <Bell size={16}></Bell>}
                                <span className={notif.notif_nbr === 0 ? 'NotificationNbrRingInactive' : 'NotificationNbrRing'}>{notif.notif_nbr}</span>    
                            
                    </div> */}
                    <div className={menu.toggle === 1 ? 'MenuProfilCase' : 'MenuProfilCase MenuProfilCaseLight'} onClick={profilSwitch}>
                        <img src={window.location.origin + '/Avatar/' + user.image + '.png'} className={menu.toggle === 1 ? 'MenuProfilPicture' : 'MenuProfilPicture MenuProfilPictureLight'} alt='profil'/>
                        {menu.toggle === 1 ? <div className='MenuProfilName'>
                            {user.surname.length > 16 ? `${user.surname.slice(0, 8)}...` : user.surname} {user.name.length > 16 ? `${user.name.slice(0, 8)}...` : user.name}
                        </div> : ''}
                        <div className='MenuProfilAccess'>{menu.toggle === 1 ? 'Voir le profil' : 'Profil'}</div>
                    </div>
                    <div className={menu.toggle === 1 ? 'deconnexion' : 'deconnexion deconnexionLight'} onClick={deconnect}>
                        {menu.toggle === 1 ? 
                            'Déconnexion' :
                            <DoorOpenFill size={16}></DoorOpenFill>}
                    </div>
                    <div className='AppliMenuToggle' onClick={menuToggle}>
                        {menu.toggle === 1 ? 
                            <ArrowLeftCircleFill size={20}></ArrowLeftCircleFill> :
                            <ArrowRightCircleFill size={20}></ArrowRightCircleFill>
                            }
                    </div>
                </div>                
            </div>
            <div className={menu.toggle === 1 ? 'AppliScreen' : 'AppliScreen AppliScreenLight'}>
                {/* Utilisez Routes directement ici sans avoir d'autres composants */}
                <Routes>
                    <Route path='/facture' element={<Facturation popToast={popToast} user={user} tryAuthorization={tryAuthorization}/>} />
                    <Route path='/caisse' element={<Caisse popToast={popToast} user={user} tryAuthorization={tryAuthorization}/>} />
                    <Route path='/client' element={<Client popToast={popToast} user={user} tryAuthorization={tryAuthorization}/>} />
                    <Route path='/technic' element={<Technic popToast={popToast} user={user} tryAuthorization={tryAuthorization}/>} />
                    <Route path='/stock' element={<Stock popToast={popToast} 
                        user={user} 
                        modifyShopCart={modifyShopCart} 
                        cart={shop.cart} 
                        changeItemNumber={changeItemNumber}
                        autoCommandeBuilder={autoCommandeBuilder}
                        tryAuthorization={tryAuthorization}
                    />} />

                    <Route path='/parametre' element={<Parametre popToast={popToast} user={user} tryAuthorization={tryAuthorization}/>} />
                </Routes>
                {tryAuthorization(35) && <CartSlide 
                    popToast={popToast} 
                    cartToggle={cartToggle} 
                    removeFromCart={removeFromCart} 
                    cart={shop.cart} 
                    total={shop.total} 
                    status={shop.cart_toggle}
                    active_date={shop.date}
                    active_commande={shop.active_commande}
                    user={user} 
                    lessItem={lessItem} 
                    moreItem={moreItem} 
                    changeItemNumber = {changeItemNumber}
                    commandes={shop.commandes} 
                    all_commandes = {shop.all_commandes}
                    openCommandeToggle={openCommandeToggle}
                    selectCommande={selectCommande}
                    changeCommandeDate={changeCommandeDate}
                    validateCommande={validateCommande}
                    cart_alert={shop.cart_alert}
                    deleteCommande={deleteCommande}
                    moveArticle={moveArticle}
                    tryAuthorization={tryAuthorization}
                ></CartSlide>}
            </div>
            <div className='toastColumn'>
                {
                    toasts ? toasts.map((toast, key) => (
                        <div className='toast' style={{opacity: 1 - (key / 10)}}>
                            <div className={'toastBand emergency' + toast.emergency}>TOAST</div>
                            {toast.message}
                            <div className='toastClose' onClick={() => closeToast(toast.id)}>OK</div>
                        </div>
                    )) : ''
                }    
            </div>
            <div className={ !user.abonnements && user.abonnement_alert_status ? 'noSubAlertMask' : 'noSubAlertMaskUnactived'}>
                {   user.abonnement_alert_step === 0 ?
                    <div className='noSubAlertBox'>
                        <div className='noSubAlertTitle'>Vous n'avez pas d'abonnement</div>
                        <div className='noSubAlertContent'>Pour accèder à l'ensemble des fonctions vous devez souscrire à un abonnement</div>
                        <div className='noSubList'>
                            <div className={user.abonnement_choice.price_id === 'price_1PQA7NHFmLHTPkm5q0zDDqRc' ? 'noSubListItemActive' : 'noSubListItem'}>
                                <div className='noSubListItemIcone'>
                                    <Cake size={24}></Cake>
                                </div>
                                <div className='noSubListItemTitle'>Abonnement mensuel</div>
                                <div className='noSubListItemPrice'>82,00€ <span>TTC/mois</span></div>
                                <div className='noSubListItemContent'>
                                    - Accès à l'ensemble des fonctionnalités<br/>
                                    - Une entreprise<br/>
                                    - 2 utilisateurs
                                </div>
                                <div className='noSubListItemEngagement'>sans engagement</div>
                                <div className={user.abonnement_choice.price_id === 'price_1PQA7NHFmLHTPkm5q0zDDqRc' ? 'noSubListItemButtonActive' : 'noSubListItemButton'} onClick={() => switchAbonnementChoice('price_1PQA7NHFmLHTPkm5q0zDDqRc')}>
                                    {user.abonnement_choice.price_id === 'price_1PQA7NHFmLHTPkm5q0zDDqRc' ?
                                        <Check size={16} /> : 'Choisir'
                                    }
                                </div>
                            </div>
                            <div className={user.abonnement_choice.price_id === 'price_1PROQUHFmLHTPkm5qRuOcepW' ? 'noSubListItemActive' : 'noSubListItem'}>
                                <div className='noSubListItemIcone'>
                                    <Cake2 size={24}></Cake2>
                                </div>
                                <div className='noSubListItemTitle'>Abonnement annuel</div>
                                <div className='noSubListItemPrice'>735,00€ <span>TTC/an</span></div>
                                <div className='noSubListItemContent'>
                                    - Accès à l'ensemble des fonctionnalités<br/>
                                    - Une entreprise<br/>
                                    - 2 utilisateurs 
                                </div>
                                <div className='noSubListItemEngagementWhite'></div>
                                <div className={user.abonnement_choice.price_id === 'price_1PROQUHFmLHTPkm5qRuOcepW' ? 'noSubListItemButtonActive' : 'noSubListItemButton'} onClick={() => switchAbonnementChoice('price_1PROQUHFmLHTPkm5qRuOcepW')}>
                                    {user.abonnement_choice.price_id === 'price_1PROQUHFmLHTPkm5qRuOcepW' ?
                                        <Check size={16} /> : 'Choisir'
                                    }
                                </div>
                            </div>
                            <div className='noSubListItem'>
                                <div className='noSubListItemIcone'>
                                    <PersonArmsUp size={24}></PersonArmsUp>
                                </div>
                                <div className='noSubListItemTitle'>Utilisateur supplémentaire</div>
                                <div className='noSubListItemPrice'>15,00€ <span>TTC/mois</span></div>
                                <div className='noSubListItemContent'>Ajouter un utilisateur</div>
                                <div className='noSubListItemEngagement'>sans engagement</div>
                                <input type='number' className='noSubListItemInput' value={user.abonnement_choice.users} placeholder="Nombre d'utilisateurs" onChange={changeAbonnementsUsers} />
                            </div>
                        </div>
                        {user.abonnement_choice.alert && <div className='NoSubAlertBox'>{user.abonnement_choice.alert}</div>}
                        <div className='noSubActionZone'>
                            <div className='noSubValid' onClick={userAbonnementChoice}>Valider</div>
                            <div className='noSubClose' onClick={userAbonnementAlertClose}>Fermer</div>
                        </div>
                    </div> : 
                    <div className='noSubAlertBox'>
                        <div className='noSubAlertTitle'>Paiement</div>
                        <Elements stripe={stripePromise} options={user.stripeOptions}>
                            <CheckoutForm />
                        </Elements>
                    </div>
                }
            </div>
            <div className={ false && user.abonnement_alert_status ? 'noSubAlertMask' : 'noSubAlertMaskUnactived'}>
                <div className='noSubAlertBox'>
                    <div className='noSubAlertTitle'>Abonnement expiré</div>
                    <div className='noSubAlertContent'>Votre abonnement a expiré, pour continuer à utiliser l'ensemble des fonctionnalités de l'application vous devez souscrire à un nouvel abonnement</div>
                    
                    <div className='noSubOldSubZone'>
                        <div className='noSubOldSubTitle'>Ancien abonnement</div>
                        <div className='noSubOldSubContent'>
                            <div className='noSubOldSubLine'>Abonnement mensuel</div>
                            <div className='noSubOldSubLine'>82,00€ TTC/mois</div>
                            <div className='noSubOldSubLine'>2 utilisateurs</div>
                            <div className='noSubOldSubLine'>Sans engagement</div>
                        </div>
                        <div className='noSubOldSubContent'>
                            <div className='noSubUsers'>Utilisateurs supplémentaires</div>
                            <div className='noSubUsers'>15,00€ TTC/mois</div>
                            <div className='noSubUsers'>0 utilisateurs</div>
                        </div>
                    </div>
                    <div className='noSubActionZone'>
                        <div className='noSubValid' onClick={userAbonnementChoice}>Valider</div>
                        <div className='noSubClose' onClick={userAbonnementAlertClose}>Fermer</div>
                    </div>
                </div>
            </div>

            <div className={isLoading ? 'loadingMask' : 'loadingMaskUnactived'}>
                <div className='loadingBox'>
                    <img src='https://bluestock.fr/images/load.gif' alt='chargement' className='loadingGif'></img>
                    <div className='loadingContent'>Veuillez patienter...</div>
                </div>
            </div>
            <div className='help_bubble' onClick={helpStart}>
                <InfoCircle size={24}></InfoCircle>
            </div>

            <div className={helper.active ? 'help_chat' : 'help_chat_unactive'} style={{top : helper.top, left: helper.left, transform: helper.translate}}>
                <div className='help_chat_title'>
                    Aide <span className='help_chat_close' onClick={() => setHelper({...helper, active : false, step: 0})}>
                        <X size={16}></X>
                    </span>
                </div>
                <p>
                    {helper.text}
                </p>
                <div className='help_chat_controls' >
                    {helper.step - 1 >= 0 && helper.active && <div className='help_chat_back' onClick={() => readStep(helper.step - 1)}>
                        <ArrowLeft size={16}></ArrowLeft>
                    </div>}
                    {helper.steps[helper.step + 1] && helper.active && <div className='help_chat_next' onClick={() => readStep(helper.step + 1)}>
                        <ArrowRight size={16}></ArrowRight>
                    </div>}
                </div>
            </div>
            {user.first_connexion === 0 && firstConnec && <div className='first_connec_mask'>
                <div className='first_connec_box'>
                    <div className='first_connec_title'>Bienvenue sur BlueStock</div>
                    <div className='first_connec_content'>
                        Vous êtes actuellement sur la version Alpha de BlueStock, nous vous remercions de votre confiance.
                        <br/>
                        Cette version est en cours de développement, si vous rencontrez des problèmes ou si vous avez des suggestions, n'hésitez pas à nous contacter à : <br/>
                        <a href='mailto:contact@bluestock.fr'> contact@bluestock.fr</a>
                        <br/>
                        En temps qu'utilisateur de la version Alpha, vous bénéficiez de nombreux avantages : <br/>
                        - Test des mises à jour en avant première <br/>
                        - Support technique prioritaire <br/>
                        - Nombre d'utilisateurs illimités
                    </div>
                    <div className='first_connec_action'>
                        <div className='first_connec_button' onClick={() => setFirstConnec(false)}>Fermer</div>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default Appli;
