import { Facebook, Instagram, Linkedin, Share, Twitter } from 'react-bootstrap-icons';
import './BlogPage.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

function BlogPage() {
    const [article, setArticle] = useState({
        title: '',
        date: '',
        content: '',
        comments: []
    })
    const loadArticle = async () => {
        const url = new URL(window.location.href);
        const id = url.searchParams.get('id');

        try {
            const response = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/blogPost",
                {
                    blog_id: id
                }
           )
           if (response.data) {
                setArticle({
                     title: response.data.article.title,
                     date: response.data.article.created_at,
                     content: response.data.article.content
                });
           }
        } catch (error) {
            console.error('error', error);
        }
    }
    useEffect(() => {
        loadArticle();
    }, []);

    return (
        <div className='BlogPage'>
            <div className='BlogPageBlock'>
                <div className='BlogPageHeader'>
                    <div className='BlogPagelogo'>
                        <div className='logo-block' onClick={() => window.location.href = '/blog'}>
                            <div className='logo'></div>
                            <div className='logo-text'>Bluestock - Le Blog</div>
                        </div>
                    </div>
                    {/* <div className='BlogPageShare'>
                        <div className='BlogPageShareLink'><Share size={18}/></div>
                        <div className='BlogPageShareLink'><Facebook size={15}/></div>
                        <div className='BlogPageShareLink'><Instagram size={15}/></div>
                        <div className='BlogPageShareLink'><Twitter size={15} /></div>
                        <div className='BlogPageShareLink'><Linkedin size={15}/></div>
                    </div> */}
                </div>
                <div className='BlogPageImage'></div>
                
                <div className='BlogPageTitle'>
                        <h1>{article.title}</h1>
                        <p>
                            {new Date(article.date).toLocaleDateString()}
                        </p>
                </div>

                <div className='BlogPageContent' dangerouslySetInnerHTML={{ __html: article.content.replace(/\n/g, '<br>') }} />

                <div className='BlogPageCommentCreateBlock'>
                    <textarea placeholder='Votre commentaire'></textarea>
                    <div className='BlogPageCommentCreateButton'>Commenter</div>
                </div>

                <div className='BlogPageComments'>
                    <h2>Commentaires</h2>
                    <div className='BlogPageComment'>
                        <div className='BlogPageCommentAuthor'>Auteur</div>
                        <div className='BlogPageCommentContent'>Contenu du commentaire</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogPage;