import React, { useState } from 'react';
import './Blog.css';
import { ArrowBarLeft, ArrowBarRight } from 'react-bootstrap-icons';
import axios from 'axios';
import { set } from 'react-ga';

function Blog () {
    const [articles, setArticles] = useState([]);
    const ArticlesLength = 150; // Nombre d'articles en tout
    const [min_articles, setMinArticles] = useState(0); // Index du premier article à afficher
    const [max_articles, setMaxArticles] = useState(8); // Index du dernier article à afficher
    const [page, setPage] = useState(1); // Page actuelle
    const [pageCount, setPageCount] = useState(1); // Nombre de pages

    const loadArticles = async () => {
        // Requête pour récupérer les articles
        console.log('loadArticles');
        const myArticles = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/api/blog",
            {
                action: 'getArticles'
            }
        );

        // On récupère les articles
        if(myArticles.data && myArticles.data.success) {
            console.log('articlesData', myArticles.data.blog);
            setArticles(myArticles.data.blog);
            setPageCount(Math.ceil(ArticlesLength / 8));
        } else if (myArticles.data &&  !myArticles.data.success) {
            console.log('Erreur lors de la récupération des articles');
        }
    }

    useState(() => {
        loadArticles();
        // On rédcupère page en GET
        const url = new URL(window.location.href);
        const page = url.searchParams.get('page');
        if(page) {
            setMinArticles((page - 1) * 8);
            setMaxArticles(page * 8);
            setPage(parseFloat(page));
            setPageCount(Math.ceil(ArticlesLength / 8));
        }
    }, []);

    return (
        <div className='Blog'>
            <div className='Blog-block'>
                <div className='Blog-header'>
                    <div className='Blog-header-mask'>
                        <div className='Blog-logo' onClick={() => window.location.href = '/'}>
                            <img src='https://bluestock.fr/logo192.png' alt='logo' />
                            <span>Bluestock</span>
                        </div>
                        <div className='Blog-title'>
                            <h1>Le blog</h1>
                            <p>
                                Toute la technique autour de la piscine et du spa
                            </p>
                        </div>
                    </div>
                </div>

                <div className='Blog-last-articles'>
                    <h2>Articles à la une</h2>
                    <div className='Blog-articles'>
                        {
                            articles.map((article, index) => {
                                if(article.une && index < 3) {
                                    return (
                                        <div className='Blog-article' key={index}>
                                            <div className='Blog-article-image'></div>
                                            <h3>{article.title}</h3>
                                            <p>{article.content.substring(0, 30)}...</p>
                                        </div>
                                    )
                                } 
                            })
                        }
                    </div>
                </div>

                <div className='Blog-all-articles'>
                    <h2>Tous les articles</h2>
                    {
                        articles.map((article, index) => {
                            if(index >= min_articles && index < max_articles) {
                                    return (
                                    <div className='Blog-big-article' key={index}>
                                        <div className='Blog-big-article-image'></div>
                                        <div className='Blog-big-article-content'>
                                            <h3>{article.title}</h3>
                                            <p>{article.content.substring(0, 30)}</p>
                                            <div className='Blog-big-article-footer'>
                                                <span className='Blog-big-article-date'>{new Date(article.created_at).toLocaleDateString()}</span>
                                                <span className='Blog-big-article-button' onClick={() => window.location.href = '/blogpage?id=' + article.id}>Lire la suite</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }     
                </div>

                {ArticlesLength > 8 &&
                    <div className='Blog-Pagination'>
                        {page > 1 &&
                            <div className='Blog-Pagination-previous' onClick={() => window.location.href = '/blog?page=' + (page - 1)}>
                                <ArrowBarLeft size={24} />
                            </div>
                        }
                        <div className='Blog-Pagination-page'>
                            {/* 1 - 2 - 3 - 4 - 5 - ... - MAX */}
                            {/* 1 - ... - 6 - 7 - 8 - ... - MAX */}
                            {/* 1 - 2 - 3 - ... - MAX - 1 - MAX */}


                            <div className={page === 1 ? 'Blog-Pagination-page-number Blog-Pagination-page-number-active' : 'Blog-Pagination-page-number'} onClick={() => window.location.href = '/blog?page=1'}>1</div>
                            

                            {pageCount < 6 &&
                                <>
                                    {
                                        Array.from({length: pageCount - 1}, (_, i) => i + 2).map((number) => {
                                            return (
                                                <div className={page === number ? 'Blog-Pagination-page-number Blog-Pagination-page-number-active' : 'Blog-Pagination-page-number'} onClick={() => window.location.href = '/blog?page=' + number}>{number}</div>
                                            )
                                        })
                                    }
                                </>
                            }
                            {pageCount >= 6 && page < 4 && 
                                <>
                                    {
                                        Array.from({length: 3}, (_, i) => i + 2).map((number) => {
                                            return (
                                                <div className={page === number ? 'Blog-Pagination-page-number Blog-Pagination-page-number-active' : 'Blog-Pagination-page-number'} onClick={() => window.location.href = '/blog?page=' + number}>{number}</div>
                                            )
                                        })
                                    }
                                    <div className='Blog-Pagination-page-more'>...</div>
                                </>
                            }
                            {pageCount >= 6 && page >= 4 && page < pageCount - 2 &&
                                <>
                                    <div className='Blog-Pagination-page-more'>...</div>
                                    {
                                        Array.from({length: 3}, (_, i) => i + page - 1).map((number) => {
                                            return (
                                                <div className={page === number ? 'Blog-Pagination-page-number Blog-Pagination-page-number-active' : 'Blog-Pagination-page-number'} onClick={() => window.location.href = '/blog?page=' + number}>{number}</div>
                                            )
                                        })
                                    }
                                    <div className='Blog-Pagination-page-more'>...</div>
                                </>
                            }
                            {pageCount >= 6 && page >= pageCount - 2 &&
                                <>
                                    <div className='Blog-Pagination-page-more'>...</div>
                                    {
                                        Array.from({length: 3}, (_, i) => i + pageCount - 3).map((number) => {
                                            return (
                                                <div className={page === number ? 'Blog-Pagination-page-number Blog-Pagination-page-number-active' : 'Blog-Pagination-page-number'} onClick={() => window.location.href = '/blog?page=' + number}>{number}</div>
                                            )
                                        })
                                    }
                                </>
                            }

                            {
                                pageCount > 1 &&
                                    <div className={page === pageCount ? 'Blog-Pagination-page-number Blog-Pagination-page-number-active' : 'Blog-Pagination-page-number'} onClick={() => window.location.href = '/blog?page=' + pageCount}>{pageCount}</div>
                            }
                        </div>
                        {
                            page < pageCount &&
                                <div className='Blog-Pagination-next' onClick={() => window.location.href = '/blog?page=' + (page + 1)}>
                                    <ArrowBarRight size={24} />
                                </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Blog;