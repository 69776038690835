import axios from 'axios'
import { useEffect, useState } from 'react'
import './Client.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { XSquare } from 'react-bootstrap-icons'

function Client(req){
    const [client, setClient] = useState({
        id : 0,
        modif : 0,
        name : 'Nom',
        surname : 'Prénom',
        address : 'Adresse',
        addressBis : 'Adresse suite',
        zip : '00000',
        city : 'Ville',
        phoneId : '+33',
        phone : '0612345678',
        mail : 'monsupermail@gmail.com',
        suscribedAt : '00/00/0000',
        lastUpdate : '00/00/0000',
        bassins : [
            // {
            //     type : 'Piscine',
            //     image : '',
            //     revetement : 'coque polyester',
            //     modele : 'modele du bassin',
            //     idBassin : 1,
            //     status : 'en service',
            //     forme : 'rectangulaire',
            //     largeur : '00.00m',
            //     longueur : '00.00m', // longueur du bassin
            //     profondeur : '0.00m', // profondeur du bassin
            //     metreCube : 35, // metrage cube du bassin
            //     traitement : 'Electrolyse', // Chlore / Brome / électrolyse
            //     skimmer: 1, // Nombre de skimmer
            //     bacheVolet : 'Volet immergé',
            //     address : 'Adresse du bassin',
            //     addressBis : 'Suite adresse du bassin',
            //     zip : '00000',
            //     city : 'Ville',
            //     createdAt : '00/00/0000'
            // }
        ],
        visites : [],
        technics : [],
        alerts : []
    })
    const [search, setSearch] = useState({
        value : '',
        status : 0,
        result : [{
            id : 1,
            name : "Nom",
            surname : "Prénom",
            image : ''
        }]
    })
    const [alert, setAlert] = useState({
        status : 0,
        type : 'impaye',
        content : ''
    })
    const [clientList, setClientList] = useState({
        status : 0,
        list : []
    })
    const navigate = useNavigate();
    const location = useLocation();
    const ClientSearch = async (e) => {
        if(req.tryAuthorization(16)){

            const newSearch = { ...search, value: e.target.value};
            setSearch(newSearch);
            try {
            const search = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/clientSearch",
                {
                    enterprise_id: req.user.enterprise.id,
                    client_search : e.target.value
                }
            );
            if (search.data) {
                if(search.data.rows.length === 0){
                    setSearch({
                        ...search,
                        value: e.target.value,
                        status : 0,
                        result : []
                    })
                } else if (e.target.value === '') {
                    setSearch({
                    ...search,
                    value: e.target.value,
                    status : 0,
                    result : []
                    })
                }
                else {
                    setSearch({
                    ...search,
                    value: e.target.value,
                    status : 1,
                    result : search.data.rows
                    })
                }
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const activeClient = async (id) => {
        if (req.tryAuthorization(16)) {
            console.log('activation du client ', id);
            setSearch({
                ...search,
                value : '',
                status : 0,
                result : []
            })
            try {
                const choosenOne = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/client",
                {
                    client_id : id
                }
                );
                if (choosenOne.data) {
                const myClient = choosenOne.data.rows[0];
                setClient({
                    ...client,
                    id : myClient.client_id,
                    modif : 0,
                    name : myClient.client_name,
                    surname : myClient.client_surname,
                    address : myClient.client_address,
                    addressBis : myClient.client_address_bis,
                    zip : myClient.client_bis,
                    city : myClient.client_city,
                    phone : myClient.client_phone,
                    mail : myClient.client_mail,
                    suscribedAt : myClient.client_creation_date,
                    bassins : JSON.parse(myClient.bassins),
                    visites : JSON.parse(myClient.pieces),
                    technics : JSON.parse(myClient.intervention),
                    alerts : JSON.parse(myClient.alerts)
                })
                }
            } catch (error) {
            console.log(error);
            }
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const clientModifActiv = async () => {
        if (req.tryAuthorization(16)) {
            if(client.modif === 0){
                setClient({
                    ...client,
                    modif: 1
                })
            } else {
                setClient({
                    ...client,
                    modif: 0
                })
                try {
                    const choosenOne = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/clientEdit",
                    {
                        id : client.id,
                        name : client.name,
                        surname : client.surname,
                        address : client.address,
                        addressBis : client.addressBis,
                        zip : client.zip,
                        city : client.city,
                        phone : client.phone,
                        mail : client.mail
                    }
                    );
                    if (choosenOne.data) {
                        activeClient(client.id);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const handleChange = (e, variable) => {
        if (req.tryAuthorization(16)) {
            setClient({
                ...client,
                [variable] : e.target.value
            })
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const activeAlertCreator = () => {
        if (req.tryAuthorization(16)) {
            setAlert({
                ...alert,
                status : alert.status === 0 ? 1 : 0
            })
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const handleAlertChange = (e, variable) => {
        if (req.tryAuthorization(16)) {
            setAlert({
                ...alert,
                [variable] : e.target.value
            })
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const saveAlert = async () => {
        if (req.tryAuthorization(16)) {
            try {
                const myAlert = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/alert",
                {
                    user_id : req.user.id,
                    enterprise_id : req.user.enterprise.id,
                    client_id : client.id,
                    type : alert.type,
                    content : alert.content
                }
                );
                if (myAlert.data) {
                    setAlert({
                        ...alert,
                        status : 0,
                        content : '',
                        type : 'impaye'
                    })
                    activeClient(client.id);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const clientsList = async () => {
        if (req.tryAuthorization(16)) {
            try {
                const clients = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/clientsList",
                    {
                        enterprise_id : req.user.enterprise.id
                    }
                );
                if (clients.data) {
                    console.log(clients.data);
                    setClientList({
                        ...clientList,
                        status : 1,
                        list : clients.data.rows
                    })
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    const archiveAlert = async (id) => {
        if (req.tryAuthorization(16)) {
            try {
                const myAlert = await axios.post(
                    process.env.REACT_APP_SERVER_URL + "/api/alertArchive",
                    {
                        alert_id : id
                    }
                );
                if (myAlert.data) {
                    console.log(myAlert.data.message);
                    activeClient(client.id);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            req.popToast("Vous n'avez pas l'autorisation de réaliser cette action", 3);
        }
    }
    useEffect(() => {
        clientsList();
    }, [req.user.enterprise.id])
    useEffect(() => {
        if(clientList.status === 1){
                const params = new URLSearchParams(location.search);
                const id = parseFloat(params.get('id'));
                if(id && id !== 0){
                    activeClient(id);
                } else {
                    activeClient(clientList.list[0].id);
                }
        }
    }, [clientList.status]);
    useEffect(() => {
        if(client.id !== 0){
            navigate('/app/client?id=' + client.id);
        }
    }, [client.id]);

    //IDEA : Ajouter la partie statistique clients
    //IDEA : Recherche de client par date / période

    return(
        <div className='Client'>
            <div className='ClientSearch'><input type='text' onChange={ClientSearch} placeholder='Rechercher un client'/></div>
            <div className={!search.status ? 'clientSearchZone' : 'clientSearchZoneActive'}>
                {
                    search.result.map((article) => (
                        <div className='clientSearchLine' onClick={() => activeClient(article.id)}>
                            <div className='clientSearchLineName'>{article.name}</div>
                            <div className='clientSearchLineName'>{article.surname}</div>
                            <div className='clientSearchLineImage'></div>
                        </div>
                    ))
                }
            </div>
            <div className='ClientProfile'>
                <div className='ClientInfos'>
                    <div className='ClientInfosPerso'>
                        <div className='ClientInfosPersoLeft'>
                            {client.modif === 0 ? 'Nom : ' + client.name : <input type='text' value={client.name} onChange={(e) => handleChange(e, 'name')} placeholder='Nom'/>}<br/>
                            {client.modif === 0 ? 'Prénom : ' + client.surname : <input type='text' value={client.surname} onChange={(e) => handleChange(e, 'surname')} placeholder='Prénom'/>}<br/>
                            <br/>
                            {client.modif === 0 ? client.address : <input type='text' value={client.address} onChange={(e) => handleChange(e, 'address')} placeholder='Adresse'/>},<br/>
                            {client.modif === 0 ? client.addressBis : <input type='text' value={client.addressBis} onChange={(e) => handleChange(e, 'addressBis')} placeholder='Adresse suite'/>}<br/>
                            {client.modif === 0 ? client.zip : <input type='text' value={client.zip} onChange={(e) => handleChange(e, 'zip')} placeholder='Code postal'/>} {client.modif === 0 ? client.city : <input type='text' value={client.city} onChange={(e) => handleChange(e, 'city')}/>}<br/>
                        </div>
                        <div className='ClientInfosPersoRight'>
                            Inscrit le : {new Date(client.suscribedAt).toLocaleDateString('fr-FR')}<br/>
                            {/* Dernière visite : {client.lastUpdate} */}
                        </div>
                    </div>

                    {console.log("client.phone ", client.phone)}
                    
                    <div className='ClientInfoPhone'>{client.modif === 0 ? (client.phone && client.phone !== '' ? client.phone : 'AUCUN NUMERO') : <input type='tel' value={client.phone} placeholder='Téléphone' onChange={(e) => handleChange(e, 'phone')}/>}</div>
                    <div className='ClientInfoMail'>{client.modif === 0 ? (client.mail && client.mail !== '' ? client.mail : 'AUCUN MAIL') : <input type='mail' value={client.mail} placeholder='Mail' onChange={(e) => handleChange(e, 'mail')}/>}</div>
                    <div className='clientInfoModifButton' onClick={clientModifActiv}>{client.modif === 0 ? 'Modifier informations utilisateur' : 'ENREGISTRER'}</div>

                    <div className='ClientInfosAlert'>
                        {client.alerts.filter((alert) => (alert.id !== null)).map((alert) => {
                            if(alert.status === 0){

                                return (<div className='ClientAlert'>
                                    <span className='AlertIcon'>
                                        {alert.type === 'impaye' ? '💰' : alert.type === 'technique' ? '🔧' : alert.type === 'bassin' ? '🏊' : '🧹'}
                                    </span>
                                    <div className='AlertText'>
                                        {alert.content}
                                    </div>
                                    <div className='AlertArchive' onClick={() => archiveAlert(alert.id)}>
                                        <XSquare size={16}/>
                                    </div>
                                </div>)
                            } else {
                                return (<div className='ClientAlert clientAlertValid'>
                                    <span className='AlertIcon'>
                                        {alert.type === 'impaye' ? '💰' : alert.type === 'technique' ? '🔧' : alert.type === 'bassin' ? '🏊' : '🧹'}
                                    </span>
                                    <div className='AlertText'>
                                        {alert.content}
                                    </div>
                                </div>)
                            }
                        })}
                        <div className='ClientInfosAlertCreator'>
                            <div className={alert.status === 0 ? 'ClientInfosAlertCreatorButton' : 'ClientInfosAlertCreatorButtonUnactive'} onClick={activeAlertCreator}>Créer une alerte</div>
                            <div className={alert.status === 1 ? 'ClientInfosAlertCreatorForm' : 'ClientInfosAlertCreatorFormUnactive'}>
                                <label htmlFor="alertType">Type :</label>
                                <select id='alertType' value={alert.type} onChange={(e) => handleAlertChange(e, 'type')} className='ClientInfosAlertCreatorFormType'>
                                    <option value='impaye'>Impayé</option>
                                    <option value='technique'>Technique</option>
                                    <option value='bassin'>Bassin</option>
                                    <option value='entretien'>Entretien</option>
                                </select>
                                <input type='text' placeholder='Contenus' value={alert.content} onChange={(e) => handleAlertChange(e, 'content')}></input>
                                <div className='ClientInfosAlertCreatorFormValid' onClick={saveAlert}>Valider</div>
                                <div className='ClientInfosAlertCreatorFormCancel' onClick={activeAlertCreator}>Annuler</div>
                            </div>

                        </div>
                    </div>

                    <div className='ClientInfosBassins'>
                        {   client.bassins.length > 0 ?
                            client.bassins.filter((bassin) => (bassin.id !== null)).map((bassin) => (
                                <div className='ClientInfosBassin'>
                                    <div className='ClientInfosBassinLeft'>
                                        <div className='ClientBassinName'>Nom du bassin<span className='ClientBassinStatus'>En service</span></div>
                                        <div className='ClientBassinDescription'>
                                            L {bassin.longueur}m x l {bassin.largeur}m x P {bassin.profondeur}m<br/>
                                            {bassin.metreCube}m3 - {bassin.skimmer} Skimmer{bassin.skimmer > 1 ? 's' : ''}<br/>
                                            {bassin.traitement}<br/>
                                            {bassin.bacheVolet}<br/><br/>
                                            {bassin.address},<br/>
                                            {bassin.addressBis}<br/>
                                            {bassin.zip} {bassin.city}
                                        </div>
                                        <div className='ClientBassinCreation'>
                                            Mis en service le: {bassin.createdAt}
                                        </div>
                                    </div>
                                    <div className='ClientInfosBassinRight'>
                                        <div className='ClientInfosBassinType'>{bassin.type}</div>
                                        <div className='ClientInfosBassinImage'></div>
                                    </div>
                                </div>                 
                            )) :
                            'Aucun bassin enregistré'
                        }
                    </div>
                </div>
                <div className='ClientShop'>
                    <div className='ClientShopTitle'>
                        Passages/achats magasin
                    </div>

                    {client.visites.filter((visit) => (visit.id !== null)).map((visite) => (
                        <div className='ClientShopFactureCard'>
                            <div className='ClientShopFactureName'>
                                <span>{visite.type === 0 ? 'Facture ' : 'Devis '} {('' + visite.piece_nbr).padStart(6, '0')}</span>
                                <span>{visite.creation_date}</span>
                            </div>
                            <div className='ClientShopFactureStatusBar'>
                                <span className='ClientShopFactureStatus'>{visite.rDue === 0 ? 'Réglé' : 'En cours'}</span>
                            </div>
                            <div className='ClientShopFactureRecap'>
                                <div>{visite.content.length} Articles</div>
                                <div>Total TTC : {(visite.total_ttc + visite.total_taxe.toFixed(2))} €</div>
                            </div>
                            <div className='ClientShopFactureRestant'>Restant dûe : {visite.rDue} €</div>
                            {req.tryAuthorization(66) && <div className='ClientShopFactureLink' onClick={() => navigate('/app/facture?id=' + visite.id + '&categorie=' + (visite.type === 0 ? 'facture' : 'devis'))}>Voir la facture</div>}
                        </div>
                    ))}
                </div>
                <div className='ClientTechnic'>
                    <div className='ClientTechnicTitle'>
                        Passages techniques/entretiens
                    </div>
                    {client.technics.filter((passage) => (passage.id !== null)).map((passage) => (
                        <div className='ClientShopTechnicCard'>
                            <div className='ClientShopTechnicCardBasicInfo'>
                                Passage technique du {passage.real_date}<br/>
                                Facture associé : {passage.piece_id}<br/>
                                Technicien : {passage.agent}
                            </div>
                            <div className='ClientShopTechnicCardInfo'>

                                Résumé de l'intervention :<br/>
                                <span className='ClientShopTechnicCompletion'>{passage.resume}</span><br/>
                                Commentaire :<br/>
                                <span className='ClientShopTechnicCompletion'>{passage.comment}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='ClientStat'>
                    <div className='ClientList'>
                        <div className='ClientListTitle'>Derniers clients</div>
                        <div className='ClientListContent'>
                            {clientList.list.map((client) => (
                                <div className='ClientListLine' onClick={() => activeClient(client.id)}>
                                    <div className='ClientListLineSurname'>{client.name}</div>
                                    <div className='ClientListLineName'>{client.surname}</div>
                                    <div className='ClientListLineImage'></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Client